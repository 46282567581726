import { IonButton, IonCol, IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPopover, IonRow, IonSelect, IonSelectOption, IonText } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import './UserEditModal.css'
import { formatPhoneNumber } from '../components/functions';
import { firestore } from '../config/firebase';
import { helpCircleOutline } from 'ionicons/icons';

function UserEditModal({ userToEdit, setUserToEdit }) {
  //Hard code group and course for now
  const group = localStorage.getItem('group');
  const course = localStorage.getItem('currentCourse');
  const [courseIndex, setCourseIndex] = useState(null);
  const [showRolesPopover, setShowRolesPopover] = useState(false);
  const [showRulesPopover, setShowRulesPopover] = useState(false);

  // We need the index of the current course in this user's privileges array
  useEffect(() => {
    if (userToEdit?.courses) {
      setCourseIndex(userToEdit?.privileges?.findIndex((obj => obj.course === course && obj.group === group)));
    }
  }, [userToEdit]);

  const getRequirePayment = () => {
    if (userToEdit?.privileges && courseIndex !== null && courseIndex !== 'undefined') {
      return userToEdit?.privileges[courseIndex]?.requirePayment ? userToEdit.privileges[courseIndex].requirePayment : '';
    } else {
      return ''
    }
  }

  const getUserRole = () => {
    if (userToEdit?.privileges && courseIndex !== null && courseIndex !== 'undefined') {
      return userToEdit?.privileges[courseIndex]?.role ? userToEdit.privileges[courseIndex].role : '';
    } else {
      return ''
    }
  }

  const setUserCoursePrivileges = (property, value) => {

    let newPrivilegesArray = [{ course: course, group: group }];

    if (userToEdit.privileges && userToEdit.privileges.length > 0) {
      newPrivilegesArray = userToEdit.privileges;
      if (newPrivilegesArray.findIndex((obj => obj.course === course && obj.group === group)) === -1) {
        newPrivilegesArray.push({ course: course, group: group });
      }
    }

    let courseIndex = newPrivilegesArray.findIndex((obj => obj.course === course && obj.group === group));

    if (property === 'requirePayment') {
      newPrivilegesArray[courseIndex]['requirePayment'] = value;
    } else if (property === 'role') {
      newPrivilegesArray[courseIndex]['role'] = value;
    }

    setUserToEdit(prevState => ({ ...prevState, privileges: newPrivilegesArray }))

  }

  const cancelUserToEdit = () => {
    setUserToEdit(false);
  }

  // Function to Edit a User
  const editUser = async e => {
    e.preventDefault();
    try {
      await firestore.collection('users').doc(userToEdit.id).update(userToEdit);
      setUserToEdit(false);
      // window.location.reload();//refresh page until we find something better like on Snapshot
    } catch (err) {
      console.log('error updating user: ', err);
    }
  }


  return (

    <IonModal isOpen={Boolean(userToEdit)} cssClass="userEditModal" backdropDismiss={true} onDidDismiss={cancelUserToEdit}>
      <div>
        <IonList>
          <IonItem key={userToEdit.id + "-name"}>
            <IonLabel>Name</IonLabel>
            <div slot="end">{userToEdit.firstName} {userToEdit.lastName}</div>
          </IonItem>
          <IonItem key={userToEdit.id + "-phone"}>
            <IonLabel>Phone</IonLabel>
            <div slot="end">{formatPhoneNumber(userToEdit.phone)}</div>
          </IonItem>
        </IonList>
      </div>

      <div>
        <div className="modalListHeader">
          Online Reservation Rules  <IonIcon onClick={() => setShowRulesPopover(true)} className="teerocket-help-icon" icon={helpCircleOutline}></IonIcon><br></br>
          <small><em>(These apply to the current group and course only.)</em></small>
        </div>
        <IonList>
          <IonItem key="activeInput">
            <IonLabel>Require Payment?</IonLabel>
            {/* <IonToggle checked={userToEdit?.requirePayment} onIonChange={e => setUserToEdit(prevState => ({ ...prevState, requirePayment: e.detail.checked }))} /> */}
            <IonSelect placeholder="Select an option" value={getRequirePayment()} onIonChange={e => setUserCoursePrivileges("requirePayment", e.detail.value)} interface="popover">
              <IonSelectOption key="rules" value="rules">Follow Widget Rules</IonSelectOption>
              <IonSelectOption key="always" value="always">Always</IonSelectOption>
              <IonSelectOption key="never" value="never">Never</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList>
      </div>

      <div>
        <div className="modalListHeader">
          Roles and Privileges <IonIcon onClick={() => setShowRolesPopover(true)} className="teerocket-help-icon" icon={helpCircleOutline}></IonIcon><br></br>
          <small><em>(These apply to the current group and course only.)</em></small>
        </div>

        <IonList>
          <IonItem key="activeInput">
            <IonLabel>User Role</IonLabel>
            <IonSelect placeholder="Select a role" onIonChange={e => setUserCoursePrivileges("role", e.detail.value)} value={getUserRole()} interface="popover">
              <IonSelectOption key="golfer" value="golfer">Golfer</IonSelectOption>
              <IonSelectOption key="courseStaff" value="courseStaff">Course Staff</IonSelectOption>
              <IonSelectOption key="courseAdmin" value="courseAdmin">Course Admin</IonSelectOption>
              <IonSelectOption key="groupAdmin" value="groupAdmin">Group Admin</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList>
      </div>

      <IonRow>
        <IonCol><IonButton expand="block" color="danger" onClick={cancelUserToEdit}>Cancel</IonButton></IonCol>
        <IonCol><IonButton type="submit" expand="block" onClick={editUser}>Save Changes</IonButton></IonCol>
      </IonRow>

      <IonPopover isOpen={showRolesPopover} onDidDismiss={() => setShowRolesPopover(false)}>
        <IonContent>
          <IonText className="teerocket-popover-title">Roles</IonText>
          <IonList>
            <IonItem><p><strong>Golfer</strong><br></br><small>Golfers are the default role. They can only use the reservation widget.</small></p></IonItem>
            <IonItem><p><strong>Course Staff</strong><br></br><small>Course Staff are able to manage the reservations on the course's tee sheet.</small></p></IonItem>
            <IonItem><p><strong>Course Admin</strong><br></br><small>Course Admins have staff privileges (above) and can edit a course's settings.</small></p></IonItem>
            <IonItem><p><strong>Group Admin</strong><br></br><small>Group Admins can edit the group settings as well as the settings of each course in the group.</small></p></IonItem>
          </IonList>
        </IonContent>
      </IonPopover>

      <IonPopover isOpen={showRulesPopover} onDidDismiss={() => setShowRulesPopover(false)}>
        <IonContent>
          <IonText className="teerocket-popover-title">Reservation Rules</IonText>
          <IonList>
            <IonItem><p><strong>Require Payment: Follow Widget Rules</strong><br></br><small>By default, your reservation widget rules will control when golfers must pay in advance to make a reservation.</small></p></IonItem>
            <IonItem><p><strong>Require Payment: Never</strong><br></br><small>You may opt to specify certain golfers who NEVER have to pay in advance. Use this for staff with free golf privileges, season-pass holders, etc.</small></p></IonItem>
            <IonItem><p><strong>Require Payment: Always</strong><br></br><small>You may opt to specify certain golfers who ALWAYS have to pay in advance. Use this if a particular golfer has a habit of no-shows and you want to restrict their ability to book online without paying in advance.</small></p></IonItem>
          </IonList>
        </IonContent>
      </IonPopover>

    </IonModal >
  )
}

export default UserEditModal;