import { IonAlert, IonButton, IonCardContent, IonCol, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { add, trashOutline } from 'ionicons/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../config/firebase';

function SettingsTeeSheets() {

  //Hard code group and course for now
  const group = localStorage.getItem('group');
  const course = localStorage.getItem('currentCourse');
  //Create a string to reference for all db calls
  const groupCourse = firestore.collection('groups').doc(group).collection('courses').doc(course);

  const [teeSheets, setTeeSheets] = useState([]);

  const [newTeeSheetTimeSlots, setNewTeeSheetTimeSlots] = useState(Array);

  const [newTeeSheet, setNewTeeSheet] = useState(null);
  const [teeSheetToEdit, setTeeSheetToEdit] = useState(null);

  const daysInWeekRadioOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const [userPermitted, setUserPermitted] = useState(false);
  useEffect(() => {
    const groupUser = firestore.collection('groups').doc(group).collection('users').doc(auth?.currentUser.uid);
    try {
      groupUser.get().then(doc => {
        if (doc.exists) {
          if (doc.data().role === 'admin') {
            setUserPermitted(true);
            console.log(doc.data().role);
          }
        } else {
          console.log("no permissions for this group");
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [group]);

  return (
    <div className="settingsPageContainer">

      <IonGrid>
        <IonRow>
          <IonCol>
            <h2 className="subHeading">Tee-Sheet Settings (Coming Soon!)</h2>
            <p>In the future, you will edit your course tee-sheet settings here. Currently it is accessible by clicking the settings icon on your tee-sheet or by <a href="/page/TeeSheetSettings">clicking here</a>.</p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default SettingsTeeSheets;
