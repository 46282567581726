import {
  IonPage,
  IonContent,
  IonButton,
  IonInput,
  IonLabel,
  IonItem,
  IonList,
  IonText,
  IonLoading,
} from '@ionic/react';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { useAuth } from '../auth';
import { auth } from '../config/firebase';

import './Login.css';

const Login: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { loggedIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState({ loading: false, error: false });
  const [errorDesc, setErrorDesc] = useState('');

  localStorage.removeItem('group');


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  }

  const handleLogin = async () => {
    try {
      setStatus({ loading: true, error: false });
      const credential = await auth.signInWithEmailAndPassword(email, password);
      console.log('credential:', credential);
    } catch (error) {
      setStatus({ loading: false, error: true });
      console.log('Error Object:', error);
      if (error.code === "auth/user-not-found") {
        setErrorDesc('User not found.');
      } else if (error.code === "auth/wrong-password") {
        setErrorDesc('The password is incorrect.');
      } else if (error.code === "auth/invalid-email") {
        setErrorDesc('You need to use a valid email address.');
      } else {
        setErrorDesc('There was an error logging in. Please try again.');
      }
    }
  };

  console.log("Current Group: ", localStorage.getItem('group'));
  console.log("Current Course: ", localStorage.getItem('currentCourse'));

  if (loggedIn) {
    return <Redirect to="/page/Groups" />;
  } else {

    return (
      <IonPage>

        <IonContent id="loginContainer" className="ion-padding">
          <div className="centered-app-container">
            <img alt="teerocket logo" src={require("../img/teerocket-logo-white.png")}></img>
            <IonList className="loginPageForm">
              <IonItem className="ion-no-padding">
                <IonLabel position="stacked">Email</IonLabel>
                <IonInput type="email" value={email} onIonChange={(event) => setEmail(event.detail.value)} onKeyDown={handleKeyDown} />
              </IonItem>
              <IonItem className="ion-no-padding">
                <IonLabel position="stacked">Password</IonLabel>
                <IonInput type="password" value={password} onIonChange={(event) => setPassword(event.detail.value)} onKeyDown={handleKeyDown} />
              </IonItem>
              <IonButton className="ion-margin-top whiteText" expand="block" onClick={handleLogin}>Login</IonButton>
              {/* <IonButton expand="block" fill="clear" routerLink="/page/register">Don't have an account?</IonButton> */}
              {status.error &&
                <div className="ion-text-center ion-margin-top"><IonText color="danger">{errorDesc}</IonText></div>
              }

              <IonLoading isOpen={status.loading} />

            </IonList>
            {/* <IonButton class="ion-margin-top" routerDirection="root" routerLink="/page/Reservations" fill="clear" color="light" slot="end"><IonIcon slot="start" icon={calendarOutline}></IonIcon>Reservations</IonButton> */}

          </div>

        </IonContent>
      </IonPage>
    );
  }
};

export default Login;