// PaymentErrorModal.js
import React from 'react';
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonText
} from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import './PaymentErrorModal.css';

const PaymentErrorModal = ({ isOpen, amount, onClose, message }) => {
  return (
    <IonModal className="payment-modal-wrap" isOpen={isOpen} onDidDismiss={onClose}>
      <IonContent className="payment-error-modal">
        <IonHeader>
          <IonToolbar>
            <IonButton slot="end" fill="clear" onClick={onClose}>✖️</IonButton>
          </IonToolbar>
        </IonHeader>
        <div className="modal-content">
          <IonIcon icon={closeCircleOutline} className="error-icon" />
          <IonText className="amount-text">${Number(amount).toFixed(2)}</IonText>
          <IonText className="error-text">Payment Cancelled</IonText>
          <IonText className="pending-text">
            {message}
          </IonText>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default PaymentErrorModal;
