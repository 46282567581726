import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonButton,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { firestore, auth } from '../config/firebase';
import './Courses.css';

const Courses: React.FC<React.PropsWithChildren<unknown>> = () => {

    const group = localStorage.getItem('group') ? (localStorage.getItem('group')) : ("undefined");
    const [courses, setCourses] = useState([]);
    const [userPermitted, setUserPermitted] = useState(false);

    useEffect(() => {

        const groupUser = firestore.collection('groups').doc(group).collection('users').doc(auth?.currentUser.uid);
        try {
            groupUser.get().then(doc => {
                if (doc.exists) {
                    if (doc.data().role === 'admin') {
                        setUserPermitted(true);
                        console.log(doc.data().role);
                    }
                } else {
                    console.log("no permissions for this group", doc.data());
                }
            });
        } catch (error) {
            console.log(error);
        }

        try {
            firestore.collection('groups').doc(group).collection('courses').onSnapshot(({ docs }) => {
                const mappedData = docs.map(d => ({ id: d.id, ...d.data() }));
                setCourses(mappedData);

            });

        } catch (err) {
            console.log('error=====>>>>', err)
        }
    }, [group]);

    const handleSetCourse = async (e) => {
        localStorage.setItem('currentCourse', e.currentTarget.getAttribute('data-id'));
        window.location.href = "/page/TeeSheet";

    }

    // console.log("Got Courses:", courses);
    // console.log("Current Group: ",localStorage.getItem('group'));
    // console.log("Current Course: ",localStorage.getItem('currentCourse'));



    if (!group) {
        return <Redirect to="/page/Groups" />;
    } else {

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Courses</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">

                    {userPermitted &&
                        <div>
                            <p className="ion-padding-start">Choose a course below.</p>

                            <div className="courseList">

                                {courses.length && courses.map((course, i) =>

                                    <IonCard key={i} className="courseCard">

                                        <IonCardHeader>
                                            <IonCardTitle>{course.name}</IonCardTitle>
                                        </IonCardHeader>

                                        <IonCardContent>
                                            <p>{course.address}</p>
                                            <p>{course.city}, {course.state > '' ? course.state : course.province} {course.zip > '' ? course.zip : course.postalcode}</p>
                                            <p>{course.country}</p>
                                            <IonButton data-id={course.id} onClick={handleSetCourse}>Select This Course</IonButton>
                                        </IonCardContent>

                                    </IonCard>
                                )}

                            </div>
                        </div>
                    }

                    {!userPermitted &&
                        <p className="ion-padding-start">You don't have permissions to use this group.</p>

                    }

                </IonContent>
            </IonPage>
        );

    }
};

export default Courses;