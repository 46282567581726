import { IonContent, IonPage } from '@ionic/react';
import React from 'react'
import { Redirect } from 'react-router';
import { useAuth } from '../auth';
import PageHeader from '../components/PageHeader';

const WidgetSettings: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { loggedIn } = useAuth();
    console.log("Logged In: ", loggedIn);

    if (loggedIn) {
        return (
            <IonPage>
                <PageHeader title="Widget Settings" settings="" backlink=""></PageHeader>

                <IonContent fullscreen>

                    <div className="ion-padding">
                        <p>Use the button below to logout. More coming soon.</p>
                    </div>

                </IonContent>
            </IonPage>
        );
    } else {
        return <Redirect to="/page/Login" />;

    }
}

export default WidgetSettings