import {IonContent, IonPage} from '@ionic/react';
import React from 'react';
import PageHeader from '../components/PageHeader';

const StoreSettings: React.FC<React.PropsWithChildren<unknown>> = () => {

  return (
    <IonPage>
      
      <PageHeader title="Store Settings" settings="" backlink="/page/Store"></PageHeader>

      <IonContent fullscreen className="ion-padding">
        <p>Store Settings coming soon!</p>
        
      </IonContent>
    </IonPage>
  );
};

export default StoreSettings;
