import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

const useAccessToken = (groupId, paymentAccountId, setToastMessage, setShowToast) => {
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        const fetchAccessToken = async () => {
            if (!paymentAccountId) {
                setToastMessage("Oops, Must select current square account from Point of Sale page.");
                setShowToast(true);
                return;
            }

            const paymentAccountRef = firebase.firestore()
                .collection('groups')
                .doc(groupId)
                .collection('paymentAccounts')
                .doc(paymentAccountId);

            try {
                const paymentAccountDoc = await paymentAccountRef.get();
                
                if (paymentAccountDoc.exists) {
                    const data = paymentAccountDoc.data();
                    const token = data.productionMode ? data.prodAccessToken : data.sandboxAccessToken;
                    setAccessToken(token);
                } else {
                    console.log('Payment account document not found.');
                }
            } catch (error) {
                console.error('Error retrieving payment account document:', error);
            }
        };

        fetchAccessToken();
    }, [groupId, paymentAccountId, setToastMessage, setShowToast]);

    return accessToken;
};

export default useAccessToken;
