import { IonBadge, IonButton, IonContent, IonHeader, IonItem, IonLabel, IonList, IonLoading, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import PageHeader from '../components/PageHeader';
import { firestore } from '../config/firebase';
import './People.css';
import UserEditModal from '../components/UserEditModal';

const People: React.FC<React.PropsWithChildren<unknown>> = () => {

  const group = localStorage.getItem('group') ? (localStorage.getItem('group')) : ("undefined");
  const course = localStorage.getItem('currentCourse') ? (localStorage.getItem('currentCourse')) : ("undefined");
  const [users, setUsers] = useState([]);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [userToEdit, setUserToEdit] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {

    if (group > '' && course > '') {

      let myCourse = {
        course: course,
        group: group
      }

      try {
        firestore.collection('users').where("courses", "array-contains", myCourse).onSnapshot(({ docs }) => {
          const mappedData = docs.map(d => ({ id: d.id, ...d.data() }));
          setUsers(mappedData);
        });

      } catch (err) {
        console.log('error getting users: ', err)
      }

    }
  }, [group, course]);

  // Sort users alphabetically
  useEffect(() => {
    let sortedUsers = users.sort((a, b) => (a.lastName.trim() > b.lastName.trim()) ? 1 : -1);
    setSortedUsers(sortedUsers);
    setPageLoader(false);

    // setTimeout(function () {
    //   setPageLoader(false);
    // }, 3000);
  }, [users]);

  useEffect(() => {
    console.log("User to Edit: ", userToEdit);
  }, [userToEdit]);

  return (
    <IonPage>
      <PageHeader title="People" settings="" backlink=""></PageHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">People</IonTitle>
          </IonToolbar>
        </IonHeader>
        {pageLoader === false &&
          <div className="ion-margin-top ion-padding-top">
            <h2 className="ion-text-center">You currently have {users.length} users.</h2>
            <p className="ion-text-center">You can edit them to assign roles and privileges below.</p>
            <div className="peopleList loaderList">
              <IonList>
                {sortedUsers.length > 0 && sortedUsers.map((user, i) =>
                  <IonItem key={user?.id}>
                    <IonLabel onClick={() => setUserToEdit(user)}>{user.lastName}, {user.firstName} </IonLabel>
                    <IonButton onClick={() => setUserToEdit(user)} className="reservationBadge" color="medium">Edit</IonButton>
                  </IonItem>
                )}
              </IonList>
            </div>
          </div>
        }
        <UserEditModal userToEdit={userToEdit} setUserToEdit={setUserToEdit}></UserEditModal>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={pageLoader}
          onDidDismiss={() => setPageLoader(false)}
          message={'Please wait...'}
        />
      </IonContent>
    </IonPage>
  );
};

export default People;
