
import { IonBadge, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonLoading, IonPage, IonTitle, IonToolbar, IonButton, IonSelect, IonSelectOption, IonText, IonTabs, IonTabBar, IonTabButton, IonIcon, IonRouterOutlet} from '@ionic/react';
import React, { useEffect, useState, useRef } from 'react';
import PageHeader from '../components/PageHeader';
import './Payments.css';
import firebase from 'firebase';
import RefundPopup from '../components/RefundPopup';

const PAGE_SIZE = 15;

const Payments: React.FC<React.PropsWithChildren<unknown>> = () => {
    const group = localStorage.getItem('group') || "undefined";
    const course = localStorage.getItem('currentCourse') || "undefined";
    const [payments, setPayments] = useState([]);
    const [pageLoader, setPageLoader] = useState(true);
    const [lastDocument, setLastDocument] = useState<firebase.firestore.DocumentSnapshot | null>(null); 
    const [fetchingData, setFetchingData] = useState(false); 
    const [currentPage, setCurrentPage] = useState(1); 
    const [docsLength, setDocLength] = useState(0)
    const [active, setActive] = useState("card")
    const [isRefundPopupOpen, setRefundPopupOpen] = useState(false);

    const contentRef = useRef<HTMLIonContentElement>(null); 

    useEffect(() => {
        firebase.firestore().collection('groups').doc(group).collection("payments").where("type", "==", active).get().then(querySnapshot => {
            setDocLength(querySnapshot.size);
        })

        if (group && course && !fetchingData && lastDocument === null) {
            try {
                setFetchingData(true);

                var db = firebase.firestore();
                var paymentsRef = db.collection('groups').doc(group).collection("payments")
                                    .where("type", "==", active)
                                    .orderBy('payment.updated_at', 'desc')
                                    .limit(PAGE_SIZE);

                paymentsRef.onSnapshot((querySnapshot) => {
                    const paymentsData = [];
                    querySnapshot.forEach((doc) => {
                        paymentsData.push({ id: doc.id, ...doc.data() });
                    });

                    if (querySnapshot.size > 0) {
                        setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
                    } else {
                        setLastDocument(null);
                    }
                    setPayments(paymentsData);
                    setPageLoader(false);
                    setFetchingData(false);
                });
            } catch (err) {
                console.log('error getting payments: ', err)
                setPageLoader(false);
                setFetchingData(false);
            }
        }
    }, [group, course, active]);

    useEffect(() => {
        if (currentPage > 1 && !fetchingData && lastDocument !== null) { 
            try {
                setFetchingData(true); 

                var db = firebase.firestore();
                var paymentsRef = db.collection('groups').doc(group).collection("payments")
                                    .where("type", "==", active)
                                    .orderBy('payment.updated_at', 'desc')
                                    .startAfter(lastDocument)
                                    .limit(PAGE_SIZE);

                paymentsRef.onSnapshot((querySnapshot) => {
                    const paymentsData = [];
                    querySnapshot.forEach((doc) => {
                        paymentsData.push({ id: doc.id, ...doc.data() });
                    });

                    if (querySnapshot.size > 0) {
                        setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
                    } else {
                        setLastDocument(null);
                    }

                    setPayments(prevPayments => [...prevPayments, ...paymentsData]);
                    setPageLoader(false);
                    setFetchingData(false);
                });
            } catch (err) {
                console.log('error getting payments: ', err)
                setPageLoader(false);
                setFetchingData(false);
            }
        }
    }, [currentPage, active]);

    const loadMore = () => {
        setCurrentPage(page => page + 1); 
    };

    const renderPagination = () => {
        if (payments.length > 0 && !fetchingData && lastDocument !== null) {
            return (
                <IonButton onClick={loadMore}>Load More</IonButton>
            );
        }
        return null;
    };

    const handleRefundSubmit = (amount: number, reason: string) => {
        console.log('Refund submitted:', { amount, reason });
    };

    return (
        <IonPage>
            <PageHeader title="Payments" settings="" backlink="" />

            <IonContent ref={contentRef} fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Payments</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {pageLoader === false &&
                    <div className="ion-margin-top ion-padding payment-wrap">
                        <div className='payment-title'>
                        <h2 >Payment List</h2>
                        <div className='payment-btn' style={{ border: '1px solid #eee', borderRadius: '10px', overflow: 'hidden' }}>
                            {["card", "cash"].map((type) => (
                                <IonButton key={type} className={active === type ? 'active' : ""} fill='clear' onClick={() => {setPayments([]); setDocLength(0); setLastDocument(null); setActive(type)}}>
                                    {type === "card" ? "Card" : "Cash"}
                                </IonButton>
                            ))}
                        </div>
                        </div>

                        {
                            active == "card" ?
                            <>
                                <div className='payments-table'>
                                    <table className="messageList " >
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Created At</th>
                                                <th>Updated At</th>
                                                <th>Completed Payment Ids</th>
                                                <th>Device Id</th>
                                                <th>Amount</th>
                                                <th>Currency</th>
                                                <th>Payment Type</th>
                                                <th>Payment Status</th>
                                                <th>Refund Amount</th>
                                                <th>Refund Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payments.length > 0 && payments.map((payment, i) =>
                                                <tr key={payment.id}>
                                                    <td>{payment?.payment?.id}</td>
                                                    <td>{payment?.payment?.created_at}</td>
                                                    <td>{payment?.payment?.updated_at}</td>
                                                    <td>
                                                        {payment?.payment.hasOwnProperty("payment_ids") ? payment?.payment?.payment_ids?.map((id, index) => (
                                                            <div key={index}>{id}</div>
                                                        )): "--"}
                                                    </td>
                                                    <td>{payment?.payment?.device_options?.device_id}</td>
                                                    <td style={{ fontWeight: 900 }}>${payment?.payment?.amount_money?.amount / 100}</td>
                                                    <td>{payment?.payment?.amount_money?.currency}</td>
                                                    <td>{payment?.payment?.payment_type == "CARD_PRESENT" ? "CARD" : payment?.payment?.payment_type}</td>
                                                    <td><span className={`payment-status ${
                                                        payment?.payment?.status === "PENDING" ? 'pending' :
                                                        payment?.payment?.status === "CANCELED" ? 'canceled' :
                                                        payment?.payment?.status === "COMPLETED" ? 'completed' :
                                                        'progress'
                                                        }`}>{payment?.payment?.status.replace(/_/g, ' ')}</span></td>
                                                    <td>{payment?.refund?.amount_money?.amount ? "$" + (payment?.refund?.amount_money?.amount / 100) : "--"}</td>
                                                    <td>{payment?.refund ? <span className={`payment-status ${
                                                        payment?.refund?.status === "PENDING" ? 'pending' :
                                                        (payment?.refund?.status === "FAILED" || payment?.refund?.status === "REJECTED") ? 'canceled' :
                                                        payment?.refund?.status === "COMPLETED" ? 'completed' :
                                                        null
                                                        }`}>{payment?.refund?.status.replace(/_/g, ' ')}</span>: "--"}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {!(payments.length >= docsLength) && renderPagination()}
                            </>
                            :
                            <>
                                <div className='payments-table'>
                                    <table className="messageList " >
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Created At</th>
                                                <th>Updated At</th>
                                                <th>Receipt Url</th>
                                                <th>Total Amount</th>
                                                <th>Buyer Given</th>
                                                <th>Change Given</th>
                                                <th>Currency</th>
                                                <th>Payment Type</th>
                                                <th>Payment Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payments.length > 0 && payments.map((payment, i) =>
                                                <tr key={payment.id}>
                                                    <td>{payment?.payment?.id}</td>
                                                    <td>{payment?.payment?.created_at}</td>
                                                    <td>{payment?.payment?.updated_at}</td>
                                                    <td><a target='_blank' href={payment?.payment?.receipt_url}>{payment?.payment?.receipt_url}</a></td>
                                                    <td style={{ fontWeight: 900 }}>${payment?.payment?.amount_money?.amount / 100}</td>
                                                    <td style={{ fontWeight: 900 }}>${payment?.payment?.cash_details?.buyer_supplied_money?.amount / 100}</td>
                                                    <td style={{ fontWeight: 900 }}>${payment?.payment?.cash_details?.change_back_money?.amount / 100}</td>
                                                    <td>{payment?.payment?.amount_money?.currency}</td>
                                                    <td>{payment?.payment?.source_type}</td>
                                                    <td><span className={`payment-status ${
                                                        payment?.payment?.status === "PENDING" ? 'pending' :
                                                        payment?.payment?.status === "CANCELED" ? 'canceled' :
                                                        payment?.payment?.status === "COMPLETED" ? 'completed' :
                                                        'progress'
                                                        }`}>{payment?.payment?.status.replace(/_/g, ' ')}</span></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {!(payments.length >= docsLength) && renderPagination()}
                            </>
                        }
               
                    </div>
                }
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={pageLoader}
                    onDidDismiss={() => setPageLoader(false)}
                    message={'Please wait...'}
                />
            </IonContent>
        </IonPage>
    );
};

export default Payments;
