import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonButton,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import './Courses.css';
import { useAuth } from '../auth';
import { auth, firestore } from '../config/firebase';

const Groups: React.FC<React.PropsWithChildren<unknown>> = () => {

    const { loggedIn } = useAuth();
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        try {
            firestore.collection('groups').onSnapshot(({ docs }) => {
                const mappedData = docs.map(d => ({ id: d.id, ...d.data() }));
                setGroups(mappedData);
                
            });

        } catch (err) {
            console.log('error=====>>>>', err)
        }
    }, []);

    const handleSetGroup = async (e) => {
        localStorage.setItem('group', e.currentTarget.getAttribute('data-id'));
        window.location.href = "/page/Courses";
    }
    const logout = () => {
        auth.signOut();
    }

    if (!loggedIn) {
        return <Redirect to="/page/Login" />;
    } else {

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Groups</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                <IonTitle className="ion-text-center"><IonButton fill="clear"
                        onClick={logout}>
                        Back to Login
                    </IonButton></IonTitle>

                    <p className="ion-padding-start">Choose a group below.</p>

                    <div className="courseList">

                        {groups.length && groups.map((group, i) => 
                            <IonCard key={i} className="courseCard">


                            <IonCardHeader>
                                <IonCardTitle>{group.name}</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                <IonButton data-id={group.id} onClick={handleSetGroup}>Select This Group</IonButton>
                            </IonCardContent>

                        </IonCard>

                        )}

                    </div>

                </IonContent>
            </IonPage>
        );

    }
};

export default Groups;