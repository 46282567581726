import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonPopover, IonReorder, IonReorderGroup, IonRow, IonSelect, IonSelectOption, IonSpinner, IonTextarea, IonTitle, IonToast, IonToggle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import PageHeader from '../components/PageHeader';
import { firestore } from '../config/firebase';
import { addOutline, arrowDownOutline, arrowForwardOutline, closeOutline, createOutline, eyeOffOutline, eyeOutline, pencilOutline, saveOutline, trashOutline } from 'ionicons/icons';
import CartPOSItem from '../components/CartPOSItem';
import { focusOnFirstInput } from '../components/functions';
import './POS.css';
import { CirclePicker, CompactPicker, TwitterPicker, SwatchesPicker } from 'react-color';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase';
import * as CONSTANT from "../config/constants"

const POSSettings: React.FC<React.PropsWithChildren<unknown>> = () => {

    const group = localStorage.getItem('group') ? (localStorage.getItem('group')) : ("undefined")
    const [myCurrentPOS, setMyCurrentPOS] = useState(localStorage.getItem('currentPOS') ? (localStorage.getItem('currentPOS')) : ("undefined"));
    const [myCurrentSquareApi, setMyCurrentSquareApi] = useState(localStorage.getItem('currentSquareApi') ? (localStorage.getItem('currentSquareApi')) : ("undefined"));
    const [myCurrentTerminalDevice, setMyCurrentTerminalDevice] = useState(localStorage.getItem('currentTerminalDevice') ? (localStorage.getItem('currentTerminalDevice')) : ("undefined"));

    const [posscreens, setPosScreens] = useState([]);
    const [paymentAccounts, setPaymentAccounts] = useState([]);
    const [devices, setDevices] = useState([]);
    const [posToEdit, setPosToEdit] = useState(null);
    const [posToEditData, setPosToEditData] = useState(null);
    const [newCategory, setNewCategory] = useState(null);
    const [newDevice, setNewDevice] = useState(null);
    const [paymentAccount, setPaymentAccount] = useState(null);
    const [deviceToEdit, setDeviceToEdit] = useState(null);
    const [newItem, setNewItem] = useState(null);
    const [newPOS, setNewPOS] = useState(null);
    const [posToDelete, setPosToDelete] = useState(null);
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
    const [currentItemIndex, setCurrentItemIndex] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [categoryToEdit, setCategoryToEdit] = useState(null);
    const [posChangeDetected, setPosChangeDetected] = useState(false);
    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const [apiToDelete, setApiToDelete] = useState(null);
    const [deviceToDelete, setDeviceToDelete] = useState(null);
    const [editPosSettings, setEditPosSettings] = useState(null);
    const [accessToken, setAccessToken] = useState(null)
    const [squareAccountTitle, setSquareAccountTitle] = useState(null)
    const [showCode, setShowCode] = useState(false);
    const [editedDeviceId, setEditedDeviceId] = useState("")
    const [loading, setLoading] = useState(false)
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [mode, setMode] = useState("sandbox")

    // Get POS Screens
    useEffect(() => {
        try {
            firestore.collection('groups').doc(group).collection('posscreens').onSnapshot(({ docs }) => {
                const mappedData = docs.map(d => ({ id: d.id, name: d.data().name, description: d.data().description }));
                setPosScreens(mappedData);

            });

        } catch (err) {
            console.log('error=====>>>>', err)
        }

        try {
            firestore.collection('groups').doc(group).collection('paymentAccounts').onSnapshot(({ docs }) => {
                const mappedData = docs.map(d => ({ id: d.id, ...d.data() }));
                setPaymentAccounts(mappedData);

            });

        } catch (err) {
            console.log('error=====>>>>', err)
        }

        try {
            firestore.collection('groups').doc(group).collection('devices').onSnapshot(({ docs }) => {
                const mappedData = docs.map(d => ({ id: d.id, ...d.data() }));
                setDevices(mappedData);

            });

        } catch (err) {
            console.log('error=====>>>>', err)
        }
        //eslint-disable-next-line
    }, [group]);

    // Get POS Data
    useEffect(() => {

        if (posToEdit > '') {
            let posToEditDoc = firestore.collection('groups').doc(group).collection('posscreens').doc(posToEdit);

            // Get Widget Settings
            try {
                posToEditDoc.get().then(snapshot => {
                    console.log("Selected POS Data: ", snapshot.data())
                    setPosToEditData(snapshot.data());
                    setCurrentCategoryIndex(0);
                });
            } catch (err) {
                console.error("Error: ", err);
            }
        }

        //eslint-disable-next-line
    }, [posToEdit]);

    // UI To CHange category on screen
    // const handleChooseCategory = (index) => {
    //     setCurrentCategoryIndex(index);
    // }

    const [selectedIndex, setSelectedIndex] = useState(null)

    const toggleCodeVisibility = (i) => {
        setSelectedIndex(i)
        setShowCode(!showCode);
    };

    const saveCurrentPOSData = async () => {
        console.log('>>>Trying to save group ', group, 'and pos ', posToEdit, 'With: ', posToEditData?.Categories, 'current category: ', currentCategoryIndex);
        if ("Categories" in posToEditData === false) {
            posToEditData.Categories = [];
        }
        try {
            await firestore.collection('groups').doc(group).collection('posscreens').doc(posToEdit).update({
                Categories: posToEditData?.Categories,
                name: posToEditData.name,
                description: posToEditData.description,
            });
        } catch (err) {
            console.log('pos categories', err);
        } finally {
            setPosToEditData(null);
            setPosToEdit(null);
            setPosChangeDetected(false);
            console.log('>>>Trying Again to save group ', group, 'and pos ', posToEdit, 'With: ', posToEditData.Categories, 'current category: ', currentCategoryIndex);

        }
    }

    const updatePosToEditCategories = () => {
        try {
            setPosToEditData(prev => ({
                ...prev, Categories: Array.isArray(prev.Categories) ? prev.Categories.concat(newCategory) : [newCategory]
            }));
        } catch (err) {
            console.log('error setting posToEditData Categories', err);
        } finally {
            setNewCategory(null);
            setPosChangeDetected(true);
            console.log('successfully tried to save new categories', posToEditData.Categories)
        }
    }

    const updatePosToEditItems = () => {
        console.log("+++++++Temp Cats", posToEditData.Categories);
        try {
            if (!Array.isArray(posToEditData.Categories[currentCategoryIndex]?.items)) {
                posToEditData.Categories[currentCategoryIndex].items = [];
            }
            posToEditData.Categories[currentCategoryIndex]?.items.push(newItem);
            console.log("+++++++Temp Cats", posToEditData.Categories);
        } catch (err) {
            console.log('error setting posToEditData Items', err);
        } finally {
            setNewItem(null);
            setPosChangeDetected(true);
        }
    }

    const saveChangesToItem = () => {
        posToEditData.Categories[currentCategoryIndex].items[currentItemIndex] = itemToEdit;
        setItemToEdit(null);
        setCurrentItemIndex(null);
        setPosChangeDetected(true);
    }

    const saveChangesToCategory = () => {
        posToEditData.Categories[currentCategoryIndex] = categoryToEdit;
        setCategoryToEdit(null);
        setPosChangeDetected(true);
    }

    const deleteCurrentItem = () => {
        posToEditData.Categories[currentCategoryIndex].items.splice(currentItemIndex, 1);
        setItemToEdit(null);
        setCurrentItemIndex(null);
        setPosChangeDetected(true);
    }

    const deleteCurrentCategory = () => {
        posToEditData.Categories.splice(currentCategoryIndex, 1);
        setCategoryToEdit(null);
        setPosChangeDetected(true);
    }

    // const handleAddNewPOS = () => {
    //     console.log("POS Action:", "handled adding new POS", "Name:", newPOS.name, "Desc:", newPOS.description);
    //     db.collection('records').add({
    //         name: 'POS'
    //       })
    //       .then(() => {
    //         console.log('Record added successfully');
    //       })
    //       .catch((error) => {
    //         console.error('Error adding record: ', error);
    //       });
    // }

    const handleAddNewPOS = () => {

        firestore.collection('groups').doc(group).collection('posscreens').add(newPOS)
            .then(() => {
                console.log('New POS added successfully');
                setNewPOS(null);
            })
            .catch((error) => {
                console.error('Error adding record: ', error);
                setNewPOS(null);
            });
    }

    const handleDeleteCurrentPOS = () => {
        firestore.collection('groups').doc(group).collection('posscreens').doc(posToDelete).delete()
            .then(() => {
                console.log('POS deleted successfully');
            })
            .catch((error) => {
                console.error('Error deleting POS: ', error);
            }).finally(() => {
                setPosToEditData(null);
                setPosToEdit(null);
                setPosToDelete(null);
                setPosChangeDetected(false);
            });
    }

    const handleAddNewPaymentAccount = () => {
        console.log("paymentAccount", paymentAccount)
        firestore.collection('groups').doc(group).collection('paymentAccounts').add(paymentAccount)
          .then(() => {
            setPaymentAccount(null);
          })
          .catch((error) => {
            console.error('Error adding record: ', error);
            setPaymentAccount(null);
          });
      };
      
      const handleEditPaymentAccount = async () => {
        try {
          await firestore.collection('groups').doc(group).collection('paymentAccounts').doc(paymentAccount.id).set(paymentAccount);
        } catch (err) {
          console.log('++++error updating payment account', err);
        } finally {
          setPaymentAccount(null);
        }
      };

    const groupId = localStorage.getItem('group');
    const paymentAccountId = localStorage.getItem('currentSquareApi');

    useEffect(() => {
   
        if (paymentAccountId) {
            const paymentAccountRef = firebase.firestore().collection('groups').doc(groupId).collection('paymentAccounts').doc(paymentAccountId);

            paymentAccountRef.get()
            .then(paymentAccountDoc => {
                if (paymentAccountDoc.exists) {
                // Get the value of the 'sandboxAccessToken' field
                let accessToken = paymentAccountDoc?.data()?.productionMode ?  paymentAccountDoc.data().prodAccessToken : paymentAccountDoc.data().sandboxAccessToken;
                setAccessToken(accessToken)
                let squareAccountName = paymentAccountDoc.data().name;
                setSquareAccountTitle(squareAccountName)
                } else {
                console.log('Payment account document not found.');
                }
            })
            .catch(error => {
                console.error('Error retrieving payment account document:', error);
            });
        }
    }, [myCurrentSquareApi, paymentAccounts])
    
    const handleAddNewDevice = () => {
        setLoading(true)
        getCode(accessToken)
    }

    const generateIdempotencyKey = () => {
        return uuidv4();
    }

    const getCode = async(token)=> {
        const data = {
            data: {
                "idempotency_key": generateIdempotencyKey(),
                  "device_code": {
                    "name": newDevice?.name,
                    "product_type": "TERMINAL_API"
                }, 
            },
            type: mode
        };
        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }

        axios.post(CONSTANT.CLOUD_FUNCTION_BASE_URL + `square-squareProxy/square-proxy`, data, {
            headers: headers
          })
        .then(response => {
            const newData = { ...response.data.data, squareAccountTitle: squareAccountTitle };

            firestore.collection('groups').doc(group).collection('devices').add(newData)
            .then(() => {
                setNewDevice(null);
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error adding record: ', error);
                setNewDevice(null);
                setLoading(false)
                setToastMessage("Oops, seems like there is an issue with your square access token");
                setShowToast(true);
            });
        })
        .catch(error => {
            setNewDevice(null);
            console.error('Error:', error);
            setLoading(false)
            setToastMessage("Oops, seems like there is an issue with your square access token");
            setShowToast(true);
        });
    }

    const handleEditDevice = async () => {
        try {
            await firestore.collection('groups').doc(group).collection('devices').doc(editedDeviceId).update({
                device_code: {...deviceToEdit, name: deviceToEdit.name },
            });
        } catch (err) {
            console.log("err", err)
            console.log('error updating device', deviceToEdit);
        } finally {
            setDeviceToEdit(null);
        }
    }

    const handleSetCurrentPOS = async (e) => {
        localStorage.setItem('currentPOS', e.currentTarget.getAttribute('data-id'));
        setMyCurrentPOS(localStorage.getItem('currentPOS'));
    }

    const handleSetCurrentSquareAPI = async (e) => {
        localStorage.setItem('currentSquareApi', e.currentTarget.getAttribute('data-id'));
        setMyCurrentSquareApi(localStorage.getItem('currentSquareApi'));
    }

    const handleSetCurrentTerminalDevice = async (e) => {
        localStorage.setItem('currentTerminalDevice', e.currentTarget.getAttribute('data-id'));
        setMyCurrentTerminalDevice(localStorage.getItem('currentTerminalDevice'));
    }

    const deleteApiAccess = async (id) => {
        try {
            await firestore.collection('groups').doc(group).collection('paymentAccounts').doc(id).delete();
        } catch (err) {
            console.log('error deleting ==>>', err);
        } finally {
            setApiToDelete(null);
            setPaymentAccount(null);
        }
    }

    const deleteDevice = async (id) => {
        try {
            await firestore.collection('groups').doc(group).collection('devices').doc(id).delete();
        } catch (err) {
            console.log('error deleting ==>>', err);
        } finally {
            setDeviceToDelete(null);
            setDeviceToEdit(null);
        }
    }

    const checkPOSChange = (newValue, type) => {
        if (type === "name") {
            if (newValue !== posToEditData?.name) {
                setPosChangeDetected(true);
            }
        } else if (type === "desc") {
            if (newValue !== posToEditData?.description) {
                setPosChangeDetected(true);
            }
        }
    }

    // const updatePosToEditName = (name) => {
    //     setPosToEdit(prevState => ({ ...prevState, [name]: name }));
    // }

    // Save Changes to POS
    // const updatePosToEdit = async (PosId) => {
    //     try {
    //         const pos = posscreens.find(t => t.id === PosId);
    //         await groupCourse.collection('teesheets').doc(teeSheetId).update({
    //             blockedTimes: teeSheet.blockedTimes
    //         });
    //         // disableSaveButton(teeSheetId);
    //         Array.from(document.querySelectorAll('.changed')).forEach((el) => el.classList.remove('changed'));

    //     } catch (err) {
    //         console.log('error updating blockedtimes', err);
    //     }
    // }

    return (
        <IonPage>

            <PageHeader title="Point of Sale" settings="" backlink=""></PageHeader>
            <IonContent fullscreen className="ion-padding">

                <IonRow>
                    <IonCol>

                        <h2 className="ion-padding-horizontal apph2Title">Points of Sale</h2>
                        <p className="ion-margin-horizontal app-explainer-section">Points of Sale are custom screens designed for each group that can be shared among admin users in the same group. We recommend having a separate Point of Sale for each location (course), but theoretically you could even have multiple POS screens in one location (eg. clubhouse, cafe, etc).</p>
                        <div>

                            {(posscreens?.length && posscreens?.length) > 0 ? posscreens?.map((pos, i) =>
                                <IonCard key={i} className="appListCard">

                                    <IonCardHeader>

                                        <IonCardTitle>
                                            {pos.name}
                                            <IonButton className="ion-float-right appModalTitleButton" fill="clear" onClick={() => { setPosToEdit(pos.id) }}>Edit</IonButton>

                                        </IonCardTitle>


                                    </IonCardHeader>

                                    <IonCardContent>
                                        {pos.description &&
                                            <p className="appModalDescription">{pos.description}</p>
                                        }
                                        {
                                            myCurrentPOS === pos.id &&
                                            <IonButton color="light" disabled>This is my current POS</IonButton>

                                        }
                                        {
                                            myCurrentPOS !== pos.id &&
                                            <IonButton data-id={pos.id} onClick={handleSetCurrentPOS}>Set As Current POS</IonButton>
                                        }
                                    </IonCardContent>

                                </IonCard>

                            ) : ""}

                            <IonButton fill="clear" type="submit" expand="block" onClick={() => { setNewPOS({ name: '' }) }}><IonIcon className="mr5" icon={addOutline}></IonIcon>Add New Point of Sale</IonButton>

                        </div>

                    </IonCol>

                    <IonCol>

                        <h2 className="ion-padding-horizontal apph2Title">Square API Accounts</h2>
                        <p className="ion-margin-horizontal app-explainer-section">You can have one or multiple Square API Accounts with separate banking information. These can then be assigned to your different Points of Sale. For example, your clubhouse and cafe could use different Points of Sale with payments going to separate bank accounts.</p>
                        <div>

                            {(paymentAccounts?.length && paymentAccounts?.length) > 0 ? paymentAccounts?.map((pa, i) =>
                                <IonCard key={i} className="appListCard">

                                    <IonCardHeader>

                                        <IonCardTitle>
                                            {pa.name}
                                            <IonButton className="ion-float-right appModalTitleButton" fill="clear" onClick={() => { setPaymentAccount(pa) }}>Edit</IonButton>
                                        </IonCardTitle>
                                    </IonCardHeader>

                                    <IonCardContent>
                                        <p className="appModalDescription">{pa.productionMode === true ? "Production Mode" : "Sandbox Mode"}</p>
                                        {
                                            myCurrentSquareApi === pa.id &&
                                            <IonButton color="light" disabled>This is my current Square API</IonButton>

                                        }
                                        {
                                            myCurrentSquareApi !== pa.id && 
                                            <IonButton data-id={pa.id} onClick={handleSetCurrentSquareAPI}>Set As Current Square API</IonButton>
                                        }
                                    </IonCardContent>
                                </IonCard>

                            ) : ""}

                            <IonButton fill="clear" type="submit" expand="block" onClick={() => { setPaymentAccount({ name: '' }) }}><IonIcon className="mr5" icon={addOutline}></IonIcon>Add New Square Account</IonButton>

                        </div>

                    </IonCol>
                    <IonCol>

                        <h2 className="ion-padding-horizontal apph2Title">Devices</h2>
                        <p className="ion-margin-horizontal app-explainer-section">Connect your Square Terminal(s) below. You can then assign which device is default for this account.</p>
                        <div>

                            {(devices?.length && devices?.length) > 0 ? devices?.map((device, i) =>

                                <IonCard key={i} className="appListCard ion-padding tr-devices">
                                <IonCardHeader className='ion-no-padding'>
                                    <IonCardTitle>
                                        {device.device_code.name}
                                        <IonButton className="ion-float-right appModalTitleButton" fill="clear" onClick={()=> {setDeviceToEdit(device.device_code)
                                             setEditedDeviceId(device.id) }}>Edit</IonButton>
                                    </IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent className='ion-no-padding'>
                                    <IonGrid className='ion-no-padding tr-devices-grid'>
                                        <IonRow className='ion-align-items-center tr-devices-list'>
                                            <IonCol size="4" className='tr-devices-list-info'>
                                                <IonCardTitle className="ion-text-wrap" style={{ fontSize: '14px' }}>Code</IonCardTitle>
                                            </IonCol>
                                            <IonCol size="8" className="ion-text-end tr-devices-list-info">
                                                <IonCardContent className='ion-no-padding tr-password'>  
                                                    {selectedIndex == i && showCode ? device.device_code.code : <span className='tr-password-text'>*****</span>} 
                                                    <IonIcon onClick={()=> toggleCodeVisibility(i)} icon={ selectedIndex == i && showCode ? eyeOutline : eyeOffOutline} style={{cursor: 'pointer'}}></IonIcon>
                                                </IonCardContent>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-align-items-center tr-devices-list'>
                                            <IonCol size="4" className='tr-devices-list-info'>
                                                <IonCardTitle className="ion-text-wrap" style={{ fontSize: '14px' }}>Status:</IonCardTitle>
                                            </IonCol>
                                            <IonCol size="8" className="ion-text-end">
                                                <IonCardContent className='tr-devices-list-info'>{device.device_code.status}</IonCardContent>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-align-items-center tr-devices-list'>
                                            <IonCol size="4" className='tr-devices-list-info'>
                                                <IonCardTitle className="ion-text-wrap" style={{ fontSize: '14px' }}>Square account name:</IonCardTitle>
                                            </IonCol>
                                            <IonCol size="8" className="ion-text-end">
                                                <IonCardContent className='tr-devices-list-info'>{device?.squareAccountTitle ? device?.squareAccountTitle : "--"}</IonCardContent>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-align-items-center tr-devices-list'>
                                            <IonCol size="4" className='tr-devices-list-info'>
                                                <IonCardTitle className="ion-text-wrap" style={{ fontSize: '14px' }}>Connected device:</IonCardTitle>
                                            </IonCol>
                                            <IonCol size="8" className="ion-text-end tr-devices-list-info">
                                                <IonCardContent className='ion-no-padding'>{device?.device_code?.device_id ? device.device_code?.device_id : "--"}</IonCardContent>
                                            </IonCol>
                                        </IonRow>
                                        {
                                            device?.device_code?.status == "PAIRED" &&
                                            <IonCardContent className='ion-no-padding'>
                                                {
                                                    myCurrentTerminalDevice === device.id &&
                                                    <IonButton color="light" disabled>This is my current Terminal Device</IonButton>

                                                }
                                                {
                                                    myCurrentTerminalDevice !== device.id && 
                                                    <IonButton data-id={device.id} onClick={handleSetCurrentTerminalDevice}>Set As Current Terminal Device</IonButton>
                                                }
                                            </IonCardContent>
                                        }
                                    </IonGrid>
                                </IonCardContent>
                                {
                                    device?.description &&
                                    <IonCardContent className='ion-no-padding'>
                                        <p className="appModalDescription ion-no-margin">{device.description}</p>
                                    </IonCardContent>
                                }
                            </IonCard>
                            ) : ""}

                            <IonButton fill="clear" type="submit" expand="block" onClick={() => { 
                                        firebase.firestore().collection('groups').doc(group).collection('paymentAccounts').doc(paymentAccountId).get()
                                        .then((doc) => {
                                            if (doc.exists) {
                                                console.log('Document data:', doc.data());
                                                setMode(doc.data().productionMode ? "production" : "sandbox")
                                            } else {
                                                console.log('No such document!');
                                            }
                                        })
                                        .catch((error) => {
                                            console.log('Error getting document:', error);
                                        });
                                paymentAccountId ? setNewDevice({ name: '' }) : alert("Please select a current square account first") }}><IonIcon className="mr5" icon={addOutline}></IonIcon>Add New Square Device</IonButton>
                        </div>
                    </IonCol>
                </IonRow>


                {/* New POS Modal */}
                <IonModal onDidPresent={() => focusOnFirstInput('newPOSName')} isOpen={Boolean(newPOS)} cssClass='teeSheetModal' backdropDismiss={true} onDidDismiss={() => setNewPOS(null)}>
                    <IonToolbar className="appModalToolbar">
                        <IonTitle>
                            <IonLabel className="titleEditLabel">Add New Point of Sale</IonLabel>
                        </IonTitle>

                    </IonToolbar>
                    <IonList className="appHeightAuto">
                        <IonItem>
                            <IonLabel className="titleEditLabel">POS Name: </IonLabel>
                            <IonInput id="newPOSName" onIonChange={e => setNewPOS(prevState => ({ ...prevState, name: e.detail.value }))} value={newPOS?.name}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="titleEditLabel">POS Description: </IonLabel>
                            <IonTextarea id="newPOSDescription" onIonChange={e => setNewPOS(prevState => ({ ...prevState, description: e.detail.value }))} value={newPOS?.description}></IonTextarea>
                        </IonItem>
                    </IonList>

                    <IonRow>
                        <IonCol><IonButton type="submit" disabled={newPOS?.name && newPOS?.name > '' ? false : true} expand="block" onClick={() => handleAddNewPOS()}>Add New POS</IonButton></IonCol>
                    </IonRow>
                </IonModal>



                {/* POS Edit Dialog Modal */}
                <IonModal isOpen={Boolean(posToEdit)} cssClass='cartEditDialog teeSheetModal' backdropDismiss={true} onDidDismiss={() => { setPosToEdit(null); setPosChangeDetected(false); }}>
                    <IonToolbar className="appModalToolbar">

                        <IonButtons slot="start">
                            <IonTitle>
                                {posToEditData?.name}
                            </IonTitle>
                            <IonButton shape="round" color="success" onClick={() => { setEditPosSettings(posToEdit) }}>Edit Name & Description</IonButton>

                        </IonButtons>

                        <IonButtons slot="end">
                            <IonButton color="danger" type="submit" expand="block" onClick={() => { setPosToDelete(posToEdit) }}>
                                <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
                            </IonButton>

                        </IonButtons>
                    </IonToolbar>



                    <IonGrid>
                        <IonRow>

                            <IonCol size="12" className="cartPOS">


                                <div className="cartPOSCategories">
                                    {
                                        posToEditData?.Categories?.map((cat, i) =>
                                            <div className={currentCategoryIndex === i ? "cartPOSCategory active" : "cartPOSCategory inactive"} key={i} data-index={i} onClick={() => setCurrentCategoryIndex(i)}>
                                                {cat?.name}
                                                <IonButton className="categoryEditButton" size="small" fill="clear" color="medium" onClick={() => { setCategoryToEdit(cat) }}>
                                                    <IonIcon icon={createOutline}></IonIcon>
                                                </IonButton>
                                            </div>
                                        )
                                    }

                                    <IonRow className="ion-margin-top">
                                        <IonCol>
                                            <IonButton fill="clear" type="submit" expand="block" onClick={() => { setNewCategory({ name: '' }) }}>
                                                <IonIcon className="mr5" icon={addOutline}></IonIcon>Add New Category</IonButton>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow style={{ position: "absolute", bottom: "10px", width: "100%" }}>
                                        <IonCol>
                                            {posChangeDetected && <div className="posChangesDetectedAlert ion-margin-horizontal">Changes detected!<br></br>Remember to Save!</div>}
                                            <IonButton className="ion-margin-horizontal" expand="block" onClick={() => { saveCurrentPOSData(); }}>Save Changes</IonButton>
                                        </IonCol>
                                    </IonRow>

                                </div>

                                {
                                    posToEditData?.Categories?.map((cat, i) =>
                                        <div className={currentCategoryIndex === i ? "cartPOSItems active" : "cartPOSItems inactive"} key={i} data-index={i}>
                                            {cat.items?.length && cat.items?.map((item, i) =>
                                                <div className="cartPOSItem" key={i}
                                                    onClick={() => { setItemToEdit(item); setCurrentItemIndex(i) }}
                                                    style={{
                                                        background: item?.backgroundColor ? item.backgroundColor : "#f7f7f7",
                                                        color: item?.textColor ? item.textColor : "black"
                                                    }}
                                                >
                                                    <div>{item.name}</div>
                                                    <div><small>${item.price ? Number(item.price).toFixed(2) : Number(0).toFixed(2)}</small></div>
                                                </div>

                                            )}

                                            <IonButton className="addNewItemButton" fill="clear" type="submit" expand="block" onClick={() => { setNewItem({ name: '' }) }}><IonIcon className="mr5" icon={addOutline}></IonIcon>Add New Item</IonButton>

                                        </div>
                                    )
                                }

                                {/* {
                                    posToEditData?.Categories?.map((cat, i) =>
                                        <div className="cartPOSItems" key={i} data-index={i}>
                                            <IonReorderGroup disabled={false}>
                                                {cat.items.map((item, i) =>
                                                    <IonItem key={i}>
                                                        <IonLabel>{item.name}</IonLabel>
                                                        <IonReorder slot="end"></IonReorder>
                                                    </IonItem>

                                                )}
                                            </IonReorderGroup>
                                        </div>

                                    ) */
                                }

                            </IonCol>


                        </IonRow>
                    </IonGrid>
                </IonModal>


                {/* New Category Modal */}
                <IonModal onDidPresent={() => focusOnFirstInput('newCategoryName')} isOpen={Boolean(newCategory)} cssClass='teeSheetModal modalOverModal' backdropDismiss={true} onDidDismiss={() => setNewCategory(null)}>
                    <IonToolbar className="appModalToolbar">
                        <IonTitle>
                            <IonLabel className="titleEditLabel">Add New Category</IonLabel>
                        </IonTitle>

                    </IonToolbar>
                    <IonList className="appHeightAuto">
                        <IonItem>
                            <IonLabel className="">Name: </IonLabel><IonInput id="newCategoryName" onIonChange={e => setNewCategory(prevState => ({ ...prevState, name: e.detail.value }))} value={newCategory?.name}></IonInput>
                        </IonItem>
                    </IonList>

                    <IonRow>
                        <IonCol><IonButton type="submit" expand="block" onClick={() => updatePosToEditCategories()}>Add New Category</IonButton></IonCol>
                    </IonRow>
                </IonModal>



                {/* New Item Modal */}
                <IonModal onDidPresent={() => focusOnFirstInput('newItemName')} isOpen={Boolean(newItem)} cssClass='teeSheetModal modalOverModal' backdropDismiss={true} onDidDismiss={() => setNewItem(null)}>
                    <IonToolbar className="appModalToolbar">
                        <IonTitle>
                            <IonLabel className="titleEditLabel">Add New Item to {newItem && posToEditData?.Categories[currentCategoryIndex]?.name}</IonLabel>
                        </IonTitle>

                    </IonToolbar>
                    <IonList className="appHeightAuto">
                        <IonItem>
                            <IonLabel className="titleEditLabel">Name: </IonLabel><IonInput id="newItemName" onIonChange={e => setNewItem(prevState => ({ ...prevState, name: e.detail.value }))} value={newItem?.name}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="titleEditLabel">Price: ($)</IonLabel><IonInput type="number" id="newItemPrice" onIonChange={e => setNewItem(prevState => ({ ...prevState, price: e.detail.value }))} value={newItem?.price ? newItem?.price : ""} placeholder="0.00"></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="titleEditLabel">Background Color: </IonLabel><div className="colorSwatch" style={{ background: newItem?.backgroundColor ? newItem.backgroundColor : "#f7f7f7" }} id="itemBackgroundColor" onClick={() => setColorPickerOpen(true)}></div>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="titleEditLabel">Text Color: </IonLabel>
                            <IonSelect onIonChange={e => setNewItem(prevState => ({ ...prevState, textColor: e.detail.value }))} value={newItem?.textColor ? newItem.textColor : "black"} placeholder="Select text color">
                                <IonSelectOption value="black">Black</IonSelectOption>
                                <IonSelectOption value="white">White</IonSelectOption>
                            </IonSelect>
                        </IonItem>

                    </IonList>

                    <IonRow>
                        <IonCol><IonButton type="submit" expand="block" onClick={() => updatePosToEditItems()}>Add New Item</IonButton></IonCol>
                    </IonRow>
                </IonModal>

                {/* EDIT Item Modal */}
                <IonModal onDidPresent={() => focusOnFirstInput('itemName')} isOpen={Boolean(itemToEdit)} cssClass='teeSheetModal modalOverModal' backdropDismiss={true} onDidDismiss={() => { setItemToEdit(null); }}>
                    <IonToolbar className="appModalToolbar">
                        <IonTitle>
                            <IonLabel className="titleEditLabel">Edit This Item</IonLabel>
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton fill="clear" color="danger" onClick={() => { deleteCurrentItem(); }}>Delete This Item</IonButton>
                        </IonButtons>

                    </IonToolbar>
                    <IonList className="appHeightAuto">
                        <IonItem>
                            <IonLabel className="titleEditLabel">Name: </IonLabel><IonInput id="itemName" onIonChange={e => setItemToEdit(prevState => ({ ...prevState, name: e.detail.value }))} value={itemToEdit?.name}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="titleEditLabel">Price: ($)</IonLabel><IonInput type="number" id="itemPrice" onIonChange={e => setItemToEdit(prevState => ({ ...prevState, price: e.detail.value }))} value={itemToEdit?.price ? itemToEdit?.price : "0.00"}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="titleEditLabel">Background Color: </IonLabel><div className="colorSwatch" style={{ background: itemToEdit?.backgroundColor ? itemToEdit.backgroundColor : "#f7f7f7" }} id="itemBackgroundColor" onClick={() => setColorPickerOpen(true)}></div>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="titleEditLabel">Text Color: </IonLabel>
                            <IonSelect onIonChange={e => setItemToEdit(prevState => ({ ...prevState, textColor: e.detail.value }))} value={itemToEdit?.textColor ? itemToEdit.textColor : "black"} placeholder="Select text color">
                                <IonSelectOption value="black">Black</IonSelectOption>
                                <IonSelectOption value="white">White</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonList>

                    <IonRow>
                        <IonCol><IonButton type="submit" expand="block" onClick={() => saveChangesToItem()}>Done Editing This Item</IonButton></IonCol>
                    </IonRow>
                </IonModal>

                {/* EDIT Category Modal */}
                <IonModal onDidPresent={() => focusOnFirstInput('catName')} isOpen={Boolean(categoryToEdit)} cssClass='teeSheetModal modalOverModal' backdropDismiss={true} onDidDismiss={() => { setCategoryToEdit(null); }}>
                    <IonToolbar className="appModalToolbar">
                        <IonTitle>
                            <IonLabel className="titleEditLabel">Edit This Category</IonLabel>
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton fill="clear" color="danger" onClick={() => { deleteCurrentCategory(); }}>Delete This Category</IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonList className="appHeightAuto">
                        <IonItem>
                            <IonLabel className="titleEditLabel">Name: </IonLabel><IonInput id="catName" onIonChange={e => setCategoryToEdit(prevState => ({ ...prevState, name: e.detail.value }))} value={categoryToEdit?.name}></IonInput>
                        </IonItem>
                    </IonList>

                    <IonRow>
                        <IonCol><IonButton type="submit" expand="block" onClick={() => saveChangesToCategory()}>Done Editing This Item</IonButton></IonCol>
                    </IonRow>
                </IonModal>

                <IonModal cssClass="colorPickerModal" isOpen={colorPickerOpen} backdropDismiss={true} onDidDismiss={() => setColorPickerOpen(false)}>

                    <SwatchesPicker
                        width={550}
                        height={300}
                        onChangeComplete={
                            color => {
                                if (newItem) {
                                    setNewItem(prevState => ({ ...prevState, backgroundColor: color.hex === "#ffffff" ? "#f7f7f7" : color.hex }));
                                }
                                else if (itemToEdit) {
                                    setItemToEdit(prevState => ({ ...prevState, backgroundColor: color.hex === "#ffffff" ? "#f7f7f7" : color.hex }));

                                }
                                setColorPickerOpen(false);
                            }
                        }
                    />
                </IonModal>

                {/* Add/Edit Square Account */}
                <IonModal 
                    onDidPresent={() => focusOnFirstInput('paymentAccountName')} 
                    isOpen={Boolean(paymentAccount)} 
                    cssClass='teeSheetModal' 
                    backdropDismiss={true} 
                    onDidDismiss={() => setPaymentAccount(null)}
                    >
                    <IonToolbar className="appModalToolbar">
                        <IonTitle>
                        <IonLabel className="titleEditLabel">{paymentAccount?.id ? "Edit API Access" : "Add New Square Account"}</IonLabel>
                        </IonTitle>
                        {paymentAccount?.id && (
                        <IonButtons slot="end">
                            <IonButton fill="clear" color="danger" className="ion-float-right" onClick={() => { setApiToDelete(paymentAccount.id) }}>
                            <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
                            </IonButton>
                        </IonButtons>
                        )}
                    </IonToolbar>

                    <IonList className="appHeightAuto">
                        <IonItem>
                        <IonLabel className="appMediumColor">API Name <small>(for reference only):</small></IonLabel>
                        <IonInput 
                            id="paymentAccountName" 
                            onIonChange={e => setPaymentAccount(prevState => ({ ...prevState, name: e.detail.value }))} 
                            value={paymentAccount?.name ? paymentAccount.name : ""}
                        />
                        </IonItem>
                        <IonItem>
                        <IonLabel className="appMediumColor">Use Production Mode?</IonLabel>
                        <IonToggle 
                            color="success" 
                            checked={paymentAccount?.productionMode === true ? true : false} 
                            onIonChange={e => setPaymentAccount(prevState => ({ ...prevState, productionMode: e.detail.checked === true ? true : false }))}
                        />
                        </IonItem>
                        <p className="ion-padding-horizontal appMediumColor">
                        <small>Using production mode will use your production credentials instead of your sandbox credentials. Keep this off until you are ready to accept real payments.</small>
                        </p>
                        <IonItem>
                        <h3>Sandbox Credentials</h3>
                        </IonItem>
                        <IonItem>
                        <IonLabel className="appMediumColor">Application ID:</IonLabel>
                        <IonInput 
                            onIonChange={e => setPaymentAccount(prevState => ({ ...prevState, sandboxAppID: e.detail.value }))} 
                            value={paymentAccount?.sandboxAppID ? paymentAccount.sandboxAppID : ""}
                        />
                        </IonItem>
                        <IonItem>
                        <IonLabel className="appMediumColor">Access Token:</IonLabel>
                        <IonInput 
                            onIonChange={e => setPaymentAccount(prevState => ({ ...prevState, sandboxAccessToken: e.detail.value }))} 
                            value={paymentAccount?.sandboxAccessToken ? paymentAccount.sandboxAccessToken : ""}
                        />
                        </IonItem>
                        <IonItem>
                        <h3 className="ion-padding-top">Production Credentials</h3>
                        </IonItem>
                        <IonItem>
                        <IonLabel className="appMediumColor">Application ID:</IonLabel>
                        <IonInput 
                            onIonChange={e => setPaymentAccount(prevState => ({ ...prevState, prodAppID: e.detail.value }))} 
                            value={paymentAccount?.prodAppID ? paymentAccount.prodAppID : ""}
                        />
                        </IonItem>
                        <IonItem>
                        <IonLabel className="appMediumColor">Access Token:</IonLabel>
                        <IonInput 
                            onIonChange={e => setPaymentAccount(prevState => ({ ...prevState, prodAccessToken: e.detail.value }))} 
                            value={paymentAccount?.prodAccessToken ? paymentAccount.prodAccessToken : ""}
                        />
                        </IonItem>
                    </IonList>

                    <IonRow>
                        <IonCol>
                        <IonButton 
                            type="submit" 
                            expand="block" 
                            onClick={() => paymentAccount?.id ? handleEditPaymentAccount() : handleAddNewPaymentAccount()}
                        >
                            {paymentAccount?.id ? "Save Changes to API Access" : "Add New Payment Account"}
                        </IonButton>
                        </IonCol>
                    </IonRow>
                </IonModal>

                {/* New Device Modal */}
                <IonModal isOpen={Boolean(newDevice)} cssClass='teeSheetModal ion-no-padding' backdropDismiss={true} onDidDismiss={() => setNewDevice(null)}>
                    <IonToolbar>
                        <IonTitle>
                            <IonLabel className="titleEditLabel ion-no-padding">Add New Device</IonLabel>
                        </IonTitle>
                    </IonToolbar>
                    <IonList className="appHeightAuto ion-padding-vertical">
                    <IonRow class='tr-devices-list ion-padding-horizontal'>
                        <IonCol size="5" className='tr-devices-list-info'>
                            <IonCardTitle className="ion-text-wrap" style={{ fontSize: '14px' }}>Selected Square Account:</IonCardTitle>
                        </IonCol>
                        <IonCol size="7" className="ion-text-end tr-devices-list-info">
                            <IonCardContent class='ion-no-padding'>
                                {squareAccountTitle}
                            </IonCardContent>
                        </IonCol>
                    </IonRow>
                        <IonItem>
                            <IonLabel className="">Device Name: </IonLabel><IonInput id="newDeviceName" onIonChange={e => setNewDevice(prevState => ({ ...prevState, name: e.detail.value }))} value={newDevice?.name}></IonInput>
                        </IonItem>
                    </IonList>
                    <IonRow>
                        <IonCol><IonButton type="submit" disabled={loading} expand="block" onClick={() => handleAddNewDevice()}>Add New Device {loading && <IonSpinner style={{width:'20px' , outerHeight:'20px'}} color="light" className='ion-margin-start'></IonSpinner>}</IonButton></IonCol>
                    </IonRow>
                </IonModal>


                {/* Edit Device Modal */}
                <IonModal isOpen={Boolean(deviceToEdit)} cssClass='teeSheetModal' backdropDismiss={true} onDidDismiss={() => setDeviceToEdit(null)}>
                    <IonToolbar className="appModalToolbar">
                        <IonTitle>
                            <IonLabel className="titleEditLabel">Edit Device Details</IonLabel>
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton fill="clear" color="danger" className="ion-float-right" onClick={() => { setDeviceToDelete(editedDeviceId) }}>
                                <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <p></p>
                    <IonList className="appHeightAuto">
                        <IonItem>
                            <IonLabel className="">Device Name: </IonLabel><IonInput id="editDeviceName" onIonChange={e => {
                                const newValue = e.detail.value;
                                setDeviceToEdit(prevState => {
                 
                                    if (!prevState) {
                                        return { name: newValue }; 
                                    }
                                
                                    if (typeof prevState !== 'object') {
                                        return prevState;
                                    }
                                    
                                    return { ...prevState, name: newValue };
                                });
                                }} value={deviceToEdit?.name}></IonInput>
                        </IonItem>
                    </IonList>

                    <IonRow>
                        <IonCol><IonButton type="submit" expand="block" onClick={() => handleEditDevice()}>Save Changes to Device</IonButton></IonCol>
                    </IonRow>
                </IonModal>

                {/* Delete API Alert */}
                <IonAlert
                    isOpen={apiToDelete ? true : false}
                    onDidDismiss={() => setApiToDelete(null)}
                    mode="ios"
                    cssClass='confirm-delete-panel'
                    header={'Delete this API Access?'}
                    message={'<strong>Warning:</strong> This cannot be undone.'}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'cancelDeletion',
                        },
                        {
                            text: 'Delete',
                            cssClass: 'confirmDeletion',
                            handler: () => {
                                deleteApiAccess(apiToDelete);
                            }
                        }
                    ]}
                />

                {/* Delete Device Alert */}
                <IonAlert
                    isOpen={deviceToDelete ? true : false}
                    onDidDismiss={() => setDeviceToDelete(null)}
                    mode="ios"
                    cssClass='confirm-delete-panel'
                    header={'Delete this Device?'}
                    message={'<strong>Warning:</strong> This cannot be undone.'}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'cancelDeletion',
                        },
                        {
                            text: 'Delete',
                            cssClass: 'confirmDeletion',
                            handler: () => {
                                deleteDevice(deviceToDelete);
                            }
                        }
                    ]}
                />

                {/* Delete POS Alert */}
                <IonAlert
                    isOpen={posToDelete ? true : false}
                    onDidDismiss={() => setPosToDelete(null)}
                    mode="ios"
                    cssClass='confirm-delete-panel'
                    header={'Delete this Point of Sale?'}
                    message={'<strong>Warning:</strong> If you delete this Point of Sale, it will not be recoverable. However, this will not delete any transactions made with the Point of sale. DO THIS AT YOUR OWN RISK! Are you sure you want to delete it?'}
                    buttons={[
                        {
                            text: 'No, don\'t delete!',
                            role: 'cancel',
                            cssClass: 'cancelDeletion',
                        },
                        {
                            text: 'Yes, Please Delete!',
                            cssClass: 'confirmDeletion',
                            handler: () => {
                                handleDeleteCurrentPOS();
                            }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={editPosSettings ? true : false}
                    onDidDismiss={() => setPosToDelete(null)}
                    cssClass='confirm-delete-panel'
                    header={'Edit POS Name & Description'}
                    mode="ios"
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'cancelDeletion',
                            handler: data => {
                                setEditPosSettings(false);
                            }
                        },
                        {
                            text: 'Save Changes',
                            cssClass: 'confirmDeletion',
                            handler: data => {
                                console.log('+++++changes saved!', data);
                                checkPOSChange(data.name, "name");
                                checkPOSChange(data.description, "description");
                                setPosToEditData(prevState => ({ ...prevState, name: data.name }));
                                setPosToEditData(prevState => ({ ...prevState, description: data.description }));
                                setEditPosSettings(false);
                            }
                        }
                    ]}
                    inputs={[
                        {
                            label: 'Name',
                            value: posToEditData?.name,
                            name: 'name'
                        },
                        {
                            type: 'textarea',
                            label: 'Description',
                            value: posToEditData?.description,
                            name: 'description'
                        }
                    ]}
                />

            </IonContent>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toastMessage}
                duration={3000}
            />
        </IonPage >

    );
};

export default POSSettings;