import { IonBadge, IonButton, IonButtons, IonHeader, IonIcon, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { arrowBackOutline, cartOutline, settingsOutline } from 'ionicons/icons';
import Cart from './Cart';

interface Props {
    title: string,
    settings: string,
    backlink: string
}

const PageHeader: React.FC<React.PropsWithChildren<Props>> = ({ title, settings, backlink }) => {
    const cart = localStorage.getItem('cart') ? (localStorage.getItem('cart')) : (null);
    const [cartItemsCount, setCartItemsCount] = useState(0);
    const [showCartModal, setShowCartModal] = useState(false);

    useEffect(() => {
        if (cart && Array.isArray(JSON.parse(cart))) {
            setCartItemsCount(JSON.parse(cart).length);
        } else {
            setCartItemsCount(0);
        }
        //eslint-disable-next-line
    }, [cart]);

    return (
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton menu="menu" />
                </IonButtons>
                <IonTitle>{title}</IonTitle>
                {settings &&
                    <IonButton routerDirection="root" routerLink={settings} fill="clear" color="medium" slot="end"><IonIcon slot="icon-only" icon={settingsOutline}></IonIcon></IonButton>
                }
                {backlink &&
                    <IonButton routerDirection="root" routerLink={backlink} fill="clear" color="medium" slot="end"><IonIcon slot="icon-only" icon={arrowBackOutline}></IonIcon></IonButton>
                }
                <IonButtons className="cartIconBadge" slot="end">
                    <IonButton onClick={() => setShowCartModal(true)}>
                        <IonIcon slot="icon-only" icon={cartOutline}></IonIcon>
                    </IonButton>
                    <IonBadge className="cartCountBadge" color="dark">{cartItemsCount}</IonBadge>
                </IonButtons>
            </IonToolbar>
            <Cart showCartModal={showCartModal} setShowCartModal={setShowCartModal}></Cart>
        </IonHeader>

    );

};

export default PageHeader;