import { IonAlert, IonBadge, IonButton, IonButtons, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonMenuButton, IonModal, IonPage, IonPopover, IonRow, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { addOutline, settingsOutline, trashOutline, menuOutline, printOutline, checkmarkOutline, alertOutline, closeOutline, cartOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth';
import { SketchPicker } from 'react-color';
import './TeeSheet.css';
import '../components/TouchScreenDragAndDrop.js';
import moment from 'moment';
import { firestore, auth } from '../config/firebase';
import { currentCourse } from '../components/functions';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/free-solid-svg-icons';
import { faCarSide } from '@fortawesome/free-solid-svg-icons';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { faTshirt } from '@fortawesome/free-solid-svg-icons';
import { PDFViewer, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Cart from '../components/Cart';

const TeeSheet: React.FC<React.PropsWithChildren<unknown>> = () => {

  const { loggedIn } = useAuth();

  if (!localStorage.getItem('chosenDate')) {
    localStorage.setItem('chosenDate', moment().format('YYYY-MM-DD'));
  }

  const group = localStorage.getItem('group') ? (localStorage.getItem('group')) : ("undefined")

  const course = localStorage.getItem('currentCourse') ? (localStorage.getItem('currentCourse')) : ("undefined")

  // Will use with course picker when needed
  const [courses, setCourses] = useState([]);
  const [courseID, setCourseID] = useState('');
  const [courseData, setCourseData] = useState(null);
  const [cartTeeGroups, setCartTeeGroups] = useState(null);


  const cart = localStorage.getItem('cart') ? localStorage.getItem('cart') : (null);
  useEffect(() => {
    setCartTeeGroups(localStorage.getItem('cartTeeGroups') ? localStorage.getItem('cartTeeGroups') : ('null'));
  }, []);

  const addCartTeeGroupItem = (data) => {
    let oldCartTeeGroups = localStorage.getItem("cartTeeGroups");
    let newCartTeeGroups = '';
    if (oldCartTeeGroups > '') {
      newCartTeeGroups = oldCartTeeGroups + "," + data;
    } else {
      newCartTeeGroups = data;
    }
    // let cartTeeGroupItems = JSON.parse(cartTeeGroups);
    // let newItems = [];
    // if (cartTeeGroupItems && Array.isArray(cartTeeGroupItems) && cartTeeGroupItems.length > 0) {

    //   cartTeeGroupItems.map((cartTeeGroupItem) => {
    //     newItems.push(cartTeeGroupItem);
    //   });
    // }
    // newItems.push(data);

    // let stringifiedCartTeeGroups = JSON.stringify(newItems);
    localStorage.setItem("cartTeeGroups", newCartTeeGroups);
    setCartTeeGroups(newCartTeeGroups);
  }

  const removeCartTeeGroupItem = (data) => {
    console.log("First Trying to remove: ", data);

    let oldCartTeeGroups = cartTeeGroups;

    recurse(oldCartTeeGroups);

    function recurse(oldCartTeeGroups) {
      if (oldCartTeeGroups.includes(data)) {
        let newCartTeeGroups = oldCartTeeGroups.replace(data, '');
        recurse(newCartTeeGroups);
      } else if (oldCartTeeGroups.slice(-1) === ',') { //if last character is comma
        let newCartTeeGroups = oldCartTeeGroups.replace(/,\s*$/, "");;
        recurse(newCartTeeGroups);
      } else if (oldCartTeeGroups.substring(0, 1) === ',') { //if first character is comma
        let newCartTeeGroups = oldCartTeeGroups.replace(/^,/, '');
        recurse(newCartTeeGroups);
      } else {
        finishRecursion(oldCartTeeGroups);
      }
    }

    function finishRecursion(teeGroups) {
      console.log("Trying to remove: ", teeGroups)

      localStorage.setItem("cartTeeGroups", teeGroups);
      setCartTeeGroups(teeGroups);
    }

  }

  const [cartItemsCount, setCartItemsCount] = useState(0);

  useEffect(() => {
    console.log("CART TEE GROUPS: ", cartTeeGroups)
    //eslint-disable-next-line
  }, [cartTeeGroups]);

  useEffect(() => {
    if (cart && Array.isArray(JSON.parse(cart))) {
      setCartItemsCount(JSON.parse(cart).length);
    } else {
      setCartItemsCount(0);
    }
    //eslint-disable-next-line
  }, [cart]);

  useEffect(() => {
    console.log("Group ++++", group);
    console.log("Course ++++", course);
    if (group > '' && course > '') {
      const groupCourse = firestore.collection('groups').doc(group).collection('courses').doc(course);

      // Get Widget Settings
      try {
        groupCourse.get().then(snapshot => {
          console.log("Course Data: ", snapshot.data())
          setCourseData(snapshot.data());
        });
      } catch (err) {
        console.error("Error: ", err);
      }
    }
    //eslint-disable-next-line
  }, [course, group]);

  useEffect(() => {
    try {
      firestore.collection('groups').doc(group).collection('courses').onSnapshot(({ docs }) => {
        const mappedData = docs?.map(d => ({ id: d.id, ...d.data() }));
        setCourses(mappedData);

      });

    } catch (err) {
      console.log('error=====>>>>', err)
    }
  }, [courseID]);

  useEffect(() => {
    try {
      firestore.collection('groups').doc(group).collection('courses').onSnapshot(({ docs }) => {
        const mappedData = docs?.map(d => ({ id: d.id, ...d.data() }));
        setCourses(mappedData);

      });

    } catch (err) {
      console.log('error=====>>>>', err)
    }
  }, [group]);

  const setCurrentCourse = async (newCourse) => {
    localStorage.setItem('currentCourse', newCourse);
    window.location.reload();//refresh page until we find something better
  }
  // End course picker setup

  //Create a string to reference for all db calls
  const groupCourse = firestore.collection('groups').doc(group).collection('courses').doc(course);

  const [teeSheets, setTeeSheets] = useState([]);
  const [teeTimeGroups, setTeeTimeGroups] = useState([]);

  const [newTeeGroup, setNewTeeGroup] = useState(null);
  const [teeGroupToEdit, setTeeGroupToEdit] = useState(null);

  const [teeBlockToEdit, setTeeBlockToEdit] = useState(null);

  const [teeSheetTimeSlots, setTeeSheetTimeSlots] = useState(Array);

  const [golferToEdit, setGolferToEdit] = useState(null);

  const [golferToDeleteFromGroup, setGolferToDeleteFromGroup] = useState(null);

  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [showTablePrintModal, setShowTablePrintModal] = useState(false);
  const [showGridPrintModal, setShowGridPrintModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showShirtColorPopover, setShowShirtColorPopover] = useState({ show: false, index: null, color: null, event: undefined });
  const [roundLength, setRoundLength] = useState(120);
  // const [time, setTime] = useState(null);
  //Dates
  if (!localStorage.getItem('chosenDate')) {
    localStorage.setItem('chosenDate', moment().format('YYYY-MM-DD'));
  }

  const [date, setThisDate] = useState<string>(localStorage.getItem('chosenDate'));
  // when date changes, update both the localstorage item and the ui
  function setNewDate(newDate) {
    localStorage.setItem('chosenDate', newDate);
    setThisDate(newDate);
  }


  // setInterval(() => setTime(moment().format('h:mm:ss a')), 1000);

  // delete panel
  const [confirmDelete, setConfirmDelete] = useState({ isOpen: false, type: '' });
  const [confirmPendingDelete, setConfirmPendingDelete] = useState({ isOpen: false, id: null });
  const [recurringResAlert, setRecurringResAlert] = useState({ isOpen: false });

  /* Button Group Options */
  const numPlayersRadioOptions = [1, 2, 3, 4];
  const numHolesRadioOptions = [9, 18];

  /* Use Below to set whether if Reround time is available */
  const [reRoundTimeSlotGolfers, setReRoundTimeSlotGolfers] = useState([]);

  const [userPermitted, setUserPermitted] = useState(false);

  useEffect(() => {
    const groupUser = firestore.collection('groups').doc(group).collection('users').doc(auth?.currentUser?.uid);
    try {
      groupUser.get().then(doc => {
        if (doc.exists) {
          if (doc.data().role === 'admin') {
            setUserPermitted(true);
          }
        } else {
          console.log("no permissions for this group");
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [group]);

  useEffect(() => {
    try {

      groupCourse.collection('teesheets').onSnapshot(({ docs }) => {
        const mappedData = docs?.map(d => ({ id: d.id, ...d.data() }));
        setTeeSheets(mappedData);
      });

      groupCourse.collection('teetimegroups').where('date', '==', date).onSnapshot(({ docs }) => {
        const mappedData = docs?.map(d => ({ id: d.id, ...d.data() }));
        setTeeTimeGroups(mappedData);
      });

    } catch (err) {
      console.log('error=====>>>>', err)
    }
    //eslint-disable-next-line
  }, [course, date]);

  // set timeslots for each tee sheet
  useEffect(() => {
    //eslint-disable-next-line
    teeSheets?.map((teeSheet) => {
      let thisArray = generateTimeslots(teeSheet.teeTimeInterval, teeSheet.startTime, teeSheet.endTime);
      teeSheet.timeslots = thisArray;
      setRoundLength(parseInt(teeSheet.roundLength));
    });
  }, [teeSheets]);

  const addTeeGroup = (timeslot, teeSheet) => {
    console.log('teeeime==>>', { timeslot, teeSheet })
    setNewTeeGroup({
      date, startingTime: timeslot,
      reroundTime: moment(timeslot, 'h:mm a').add(teeSheet.roundLength, 'minutes').format('h:mm a'),
      startingTeeID: teeSheet.id,
      reroundTeeID: teeSheet.reroundTeeID,
      golfers: [{ name: "Guest" }, { name: "Guest" }, { name: "Guest" }, { name: "Guest" }],
      numHoles: 18
    });
  }

  const toggleTeeGroupStatus = (key) => {
    if (teeGroupToEdit[key]) {
      setTeeGroupToEdit(prevState => ({ ...prevState, [key]: false }));
    } else {
      setTeeGroupToEdit(prevState => ({ ...prevState, [key]: true }));
    }
  }

  useEffect(() => {
    let timeSlotGolfers = [];
    if (newTeeGroup == null) {
      setReRoundTimeSlotGolfers([]);
    } else {

      //Recurring Tee Times

      teeSheets?.map((teeSheet) => {
        console.log('blocked>>>>>>: ', teeSheet.blockedTimes);
        teeSheet.blockedTimes?.map((blockedTime, i) => {
          if (teeSheet.id === newTeeGroup.reroundTeeID
            && blockedTime.time.includes(newTeeGroup.reroundTime)
            && blockedTime.days.includes(moment(date).format('dddd'))
          ) {
            timeSlotGolfers = ['blockedRecurringReround'];
          }
        });
      });

      //eslint-disable-next-line
      teeTimeGroups?.map((teeTimeGroup) => {

        // Block Tee Times
        if (
          (teeTimeGroup.blockTeeID === newTeeGroup.reroundTeeID && moment(newTeeGroup.reroundTime, 'h:mm a') >= moment(teeTimeGroup.blockStartTime, 'h:mm a') && moment(newTeeGroup.reroundTime, 'h:mm a') <= moment(teeTimeGroup.blockEndTime, 'h:mm a'))
          || (teeTimeGroup.blockReroundTeeID === newTeeGroup.reroundTeeID && teeTimeGroup.blockReroundStartTime && teeTimeGroup.blockReroundEndTime && moment(newTeeGroup.reroundTime, 'h:mm a') >= moment(teeTimeGroup.blockReroundStartTime, 'h:mm a') && moment(newTeeGroup.reroundTime, 'h:mm a') <= moment(teeTimeGroup.blockReroundEndTime, 'h:mm a'))
        ) {

          timeSlotGolfers = ['blockedReround'];

          // Regular Tee Times 
        } else if (
          //eslint-disable-next-line
          teeTimeGroup.startingTeeID === newTeeGroup.reroundTeeID && newTeeGroup.reroundTime === teeTimeGroup.startingTime
          //eslint-disable-next-line
          || (teeTimeGroup.reroundTeeID === newTeeGroup.reroundTeeID && newTeeGroup.reroundTime === teeTimeGroup.reroundTime && teeTimeGroup.numHoles === 18)
        ) {
          //eslint-disable-next-line
          teeTimeGroup.golfers?.map((golfer) => {
            timeSlotGolfers.push(golfer.name);
          });
        }


      });
      setReRoundTimeSlotGolfers(timeSlotGolfers);
    }
    //eslint-disable-next-line
  }, [newTeeGroup]);


  useEffect(() => {
    console.log("RRTSGOLFERS >>>>>>", reRoundTimeSlotGolfers);
  }, [reRoundTimeSlotGolfers]);

  function focusOnFirstInput(id) {
    setTimeout(() => {
      let firstInput = document.getElementById(id).getElementsByTagName("input")[0];
      if (typeof (firstInput) !== 'undefined' && firstInput !== null) {
        firstInput.focus();
      }
    }, 50);
  }

  const addTeeBlockTime = async () => {
    const newBlockedTeeTime = {
      date: newTeeGroup.date,
      blockTitle: 'Buffer',
      blockTeeID: newTeeGroup.startingTeeID,
      blockStartTime: newTeeGroup.startingTime,
      blockEndTime: newTeeGroup.startingTime,
    };
    try {
      const a = await groupCourse.collection('teetimegroups').add(newBlockedTeeTime)//).get()//.onSnapshot;
      setNewTeeGroup(null);
      blinkThis(a.id);
    } catch (err) {
      console.log('error adding ==>>', err);
    }
  }

  const handleAddGroup = async e => {
    e.preventDefault();
    try {
      const a = await groupCourse.collection('teetimegroups').add(newTeeGroup)//).get()//.onSnapshot;
      setNewTeeGroup(null);
      blinkThis(a.id);
    } catch (err) {
      console.log('error adding ==>>', err);
    }
  }

  const handleReservedTeeTimeClick = e => {
    e.stopPropagation();
    setRecurringResAlert({ isOpen: true })
  }

  const deleteItem = (type) => {
    if (type === 'group') {
      deleteGroup();
    } else if (type === 'golfer') {
      deleteGolfer();
    } else if (type === 'block') {
      deleteBlock();
    }
  }

  const deletePendingItem = async (id) => {
    try {
      await groupCourse.collection('teetimegroups').doc(id).delete();
      setConfirmPendingDelete({ isOpen: false, id: null });
    } catch (err) {
      console.log('error deleting ==>>', err);
    }
  }

  const deleteGroup = async () => {
    try {
      await groupCourse.collection('teetimegroups').doc(teeGroupToEdit.id).delete();
      setTeeGroupToEdit(null);
    } catch (err) {
      console.log('error deleting ==>>', err);
    }
  }

  const deleteBlock = async () => {
    try {
      await groupCourse.collection('teetimegroups').doc(teeBlockToEdit.id).delete();
      setTeeBlockToEdit(null);
    } catch (err) {
      console.log('error deleting ==>>', err);
    }
  }

  const editGroup = async e => {
    e.preventDefault();
    try {
      const golfers = teeGroupToEdit.golfers?.map(golfer => recursivelyReplaceNullToZero(golfer));
      teeGroupToEdit.golfers = golfers;
      await groupCourse.collection('teetimegroups').doc(teeGroupToEdit.id).update(teeGroupToEdit);
      blinkThis(teeGroupToEdit.id);
      setTeeGroupToEdit(null);
    } catch (err) {
      console.log('error editing ==>>', err);
    }
  }

  const editBlock = async e => {
    e.preventDefault();
    try {
      await groupCourse.collection('teetimegroups').doc(teeBlockToEdit.id).update(teeBlockToEdit);
      blinkThis(teeBlockToEdit.id);
      setTeeBlockToEdit(null);
    } catch (err) {
      console.log('error deleting ==>>', err);
    }
  }

  const recursivelyReplaceNullToZero = (j) => {
    for (var i in j) {
      if (typeof j[i] === "object") {
        recursivelyReplaceNullToZero(j[i]);
      }
      if (j[i] === undefined) {
        j[i] = '';
      }
    }
    return j;
  }

  const handleTeeGroupGolfersInputChange = ({ event, index }) => {
    const { value, name } = event.target;
    setTeeGroupToEdit(prev => ({ ...prev, golfers: prev.golfers?.map((g, i) => ({ ...g, [name]: index === i ? value : g[name] })) }));
  }

  const handleTeeGroupGolfersToggleChange = ({ event, index }) => {
    const { name, checked } = event.target;
    setTeeGroupToEdit(prev => ({ ...prev, golfers: prev.golfers?.map((g, i) => ({ ...g, [name]: index === i ? checked : g[name] })) }));
  }

  const handleTeeGroupGolfersKeyValueChange = ({ key, value, index }) => {
    setTeeGroupToEdit(prev => ({ ...prev, golfers: prev.golfers?.map((g, i) => ({ ...g, [key]: index === i ? value : g[key] })) }));
  }

  const deleteSingleGolfer = (i) => {
    setTeeGroupToEdit(prev => ({ ...prev, golfers: prev.golfers.filter(function (value, index, arr) { return index !== i; }) }));
  }

  const addGolferToGroup = (group) => {

    setTeeGroupToEdit(prev => ({
      ...prev, golfers: prev.golfers.concat({
        name: 'Guest',
        email: '',
        phone: '',
        paid: false,
        checkedIn: false,
        cart: false,
        league: '',
        color: '',
        notes: '',
        receipt: '',
      })
    }));

  }

  const deleteGolfer = async () => {
    try {
      const { golferIndex } = golferToEdit;
      const golfers = golferToEdit.golfers.filter((g, i) => i !== golferIndex);
      //golfers.splice(golferIndex, 1, { name, phone, email });
      await groupCourse.collection('teetimegroups').doc(golferToEdit.id).update({ golfers });
      setGolferToEdit(null);
    } catch (err) {
      console.log('error deleting ==>>', err);
    }
  }

  const getDaysArray = function (start, end) {
    //eslint-disable-next-line
    var arr = new Array(), dt = new Date(start);

    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }

    return arr;
  }

  //Get next 7 days
  const today = new Date();
  const todayPlus1 = new Date(today);
  todayPlus1.setDate(todayPlus1.getDate() + 1);
  const todayPlus7 = new Date(today);
  todayPlus7.setDate(todayPlus7.getDate() + 7);
  const thisWeekList = getDaysArray(today, todayPlus7);

  //Let's create an array of timeslots for each teeSheet
  const generateTimeslots = (timeInterval, startTime, endTime) => {

    startTime = moment(startTime, 'h:mm a');
    endTime = moment(endTime, 'h:mm a');

    let slotArray = [];
    slotArray.push(startTime.format('h:mm a'));

    let newTime = startTime;
    while (startTime.isBefore(endTime)) {
      newTime = newTime.add(timeInterval, 'minutes');
      slotArray.push(newTime.format('h:mm a'));
    }

    return slotArray;
  }

  const generateTeeSheetTimeSlots = (teeSheet) => {
    return generateTimeslots(teeSheet.teeTimeInterval, teeSheet.startTime, teeSheet.endTime);
  }

  const handleBlockClick = (event, teeTime) => {
    event.stopPropagation();
    setTeeBlockToEdit(teeTime);
  }

  const handlePendingClick = (event, teeTime) => {

    event.stopPropagation();
    setConfirmPendingDelete({ isOpen: true, id: teeTime.id });
  }

  //hack to scroll to selection on popovers
  const scrollToSelection = () => {
    setTimeout(() => {
      let selected = document.getElementsByClassName('sc-ion-select-popover')[0].querySelector('[aria-checked="true"]');
      if (selected) {
        selected.scrollIntoView({ block: "center" });
      }
    }, 500);
  }

  //function to blink a teetime a few times after changing it to indicate it has been changed
  const blinkThis = (id) => {
    document.querySelectorAll('[data-id="' + id + '"]').forEach((element) => {
      element.classList.add("blink_me");
    });
    setTimeout(function () {
      document.querySelectorAll('.blink_me').forEach((element) => {
        element.classList.remove("blink_me");
      });
    }, 2100);
  }

  const handleSwitchAll = (type) => {
    setTeeGroupToEdit(prev => ({ ...prev, golfers: prev.golfers?.map((g, i) => ({ ...g, [type]: true })) }));
  }

  const openPrintModal = (type) => {
    setShowLoading(true);
    setTimeout(() => {
      if (type === 'compact') {
        setShowTablePrintModal(true);
      } else if (type === 'expanded') {
        setShowGridPrintModal(true);
      }
    }, 1000);
  }

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row'
    },
    section: {
      marginLeft: 10,
      marginRight: 10,
      flexGrow: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    blockedTime: {
      // fontSize: '8',
      // backgroundColor: '#ddd',
      padding: '3',
      width: '100%',
    },
    teeGroup: {
      // fontSize: '8',
      padding: '3',
    },
    teeSheetGolfer: {
      // fontSize: '8',
      // padding: '5'
    },
    teeSheetGroupTitle: {
      textDecoration: 'underline',
    },
    pageTitle: {
      fontSize: 16,
      textAlign: 'center',
      marginTop: 0
    }
  });

  /* drag and drop - per tutorial here: https://www.javascripttutorial.net/web-apis/javascript-drag-and-drop/ */
  useEffect(() => {

    const items = document.querySelectorAll('.moveableGroup');

    items.forEach(item => {
      item.addEventListener('dragstart', dragStart);
    });

    function dragStart(e) {
      e.dataTransfer.setData('text/plain', e.target.id);
    }

    const boxes = document.querySelectorAll('.box');

    boxes.forEach(box => {
      box.addEventListener('dragenter', dragEnter)
      box.addEventListener('dragover', dragOver);
      box.addEventListener('dragleave', dragLeave);
      box.addEventListener('drop', drop);
    });


    function dragEnter(e) {
      e.preventDefault();
      e.target.classList.add('drag-over');
    }

    function dragOver(e) {
      e.preventDefault();
      e.target.classList.add('drag-over');
    }

    function dragLeave(e) {
      e.target.classList.remove('drag-over');
    }

    async function drop(e) {
      e.target.classList.remove('drag-over');

      const thisTeeTimeGroupData = e.dataTransfer.getData('text/plain');

      // get the draggable element
      const thisTee = thisTeeTimeGroupData.substr(0, thisTeeTimeGroupData.indexOf(' '));
      const teeTimeGroupID = thisTeeTimeGroupData.substr(thisTeeTimeGroupData.indexOf(' ') + 1);

      // const draggable = document.getElementById(teeTimeGroupID);

      const teeTimeGroup = teeTimeGroups.find(item => item.id === teeTimeGroupID);
      const newTime = e.target.dataset.time;
      const newStartingTee = e.target.dataset.starttee;
      const newReroundTee = e.target.dataset.reroundtee;
      const newStartingTime = thisTee === 'start' ? newTime : moment(newTime, 'h:mm a').subtract(roundLength, 'minutes').format('h:mm a');

      const newTeeTimeGroup = thisTee === 'start' ? {
        ...teeTimeGroup,
        startingTime: newStartingTime,
        reroundTime: thisTee === 'reround' ? newTime : moment(newTime, 'h:mm a').add(roundLength, 'minutes').format('h:mm a'),
        startingTeeID: thisTee === 'start' ? newStartingTee : newReroundTee,
        reroundTeeID: thisTee === 'reround' ? newStartingTee : newReroundTee
      } : {
        ...teeTimeGroup,
        reroundTime: thisTee === 'reround' ? newTime : moment(newTime, 'h:mm a').add(roundLength, 'minutes').format('h:mm a'),
        startingTeeID: thisTee === 'start' ? newStartingTee : newReroundTee,
        reroundTeeID: thisTee === 'reround' ? newStartingTee : newReroundTee
      }


      // add it to the drop target
      try {
        await groupCourse.collection('teetimegroups').doc(teeTimeGroupID).update(newTeeTimeGroup);
        blinkThis(teeTimeGroupID);
      } catch (err) {
        console.log('error deleting ==>>', err);
      }

    }
    //eslint-disable-next-line
  }, [teeTimeGroups]);

  if (!loggedIn) {
    return <Redirect to="/page/Login" />;
  } else {

    if (!currentCourse()) {
      return <Redirect to="/page/Courses" />;
    } else {
      return (
        <div>


          {userPermitted &&

            <IonPage>

              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="start">
                    <IonMenuButton />
                  </IonButtons>

                  <IonTitle>
                    <div className="teeSheetDateTitle">Tee-Times for {courseData?.shortName ? courseData?.shortName : courseData?.name}</div>

                    <IonDatetime className="teeSheetDatePicker" displayFormat="DDDD, MMM DD" min={moment().subtract('365', 'days').format('YYYY-MM-DD')} max={moment().add('365', 'days').format('YYYY-MM-DD')} value={date} onIonChange={e => setNewDate(moment(e.detail.value).format('YYYY-MM-DD'))}></IonDatetime>

                    {thisWeekList?.map((day, i) =>
                      moment(day).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') ?
                        <IonButton key={"thisWeekList" + moment(day).format('YYYYMMDD')} className="singleDayPicker" color="primary" onClick={() => setNewDate(moment(day).format('YYYY-MM-DD'))}>{moment(day).format('ddd')}<br></br>{moment(day).format('DD')}</IonButton>
                        :
                        <IonButton key={"thisWeekList" + moment(day).format('YYYYMMDD')} className="singleDayPicker" color="light" onClick={() => setNewDate(moment(day).format('YYYY-MM-DD'))}>{moment(day).format('ddd')}<br></br>{moment(day).format('DD')}</IonButton>
                    )}
                    {/* <div className="titleBarTime">{time}</div> */}

                  </IonTitle>

                  {/* <IonSelect id="coursePicker" onIonChange={e => setCurrentCourse(e.detail.value)} value={course} placeholder={courseID} interface="popover">
                    {courses?.map(course => <IonSelectOption key={course.id} value={course.id}>{course.name}</IonSelectOption>)}
                  </IonSelect> */}

                  <IonButton onClick={() => setShowPrintModal(true)} fill="clear" color="dark" slot="end"><IonIcon slot="icon-only" icon={printOutline}></IonIcon></IonButton>


                  <IonButton routerDirection="root" routerLink="/page/TeeSheetSettings" fill="clear" color="dark" slot="end"><IonIcon slot="icon-only" icon={settingsOutline}></IonIcon></IonButton>
                  {/* <IonButton onClick={handle.enter} fill="clear" color="medium" slot="end"><IonIcon slot="icon-only" icon={scanOutline}></IonIcon></IonButton> */}
                  <IonButtons className="cartIconBadge" slot="end">
                    <IonButton onClick={() => setShowCartModal(true)}>
                      <IonIcon slot="icon-only" icon={cartOutline}></IonIcon>
                    </IonButton>
                    <IonBadge className="cartCountBadge" color="dark">{cartItemsCount}</IonBadge>
                  </IonButtons>
                </IonToolbar>
                {date !== moment().format('YYYY-MM-DD') &&
                  <IonToolbar className="ion-text-center teeSheetWarning" color="warning">NOTICE: You are currently editing a different day's tee sheet.</IonToolbar>}
              </IonHeader>

              <IonContent>
                <IonHeader collapse="condense">
                  <IonToolbar>
                    <IonTitle size="large">Tee Sheet</IonTitle>
                  </IonToolbar>
                </IonHeader>

                <div className="tee-sheet-container">



                  <IonGrid>
                    <IonRow>
                      {teeSheets?.length ? (
                        teeSheets?.map((teeSheet) =>
                          teeSheet.active &&
                          <IonCol key={teeSheet.id}>
                            <div className="ion-text-center">
                              <h2 className="ion-text-center teeSheetTitle">{teeSheet.name}
                              </h2>

                            </div>

                            <table className="teeSheetTable">
                              <tbody>
                                {teeSheet.timeslots?.map((timeslot, i) =>
                                  <tr className={date === moment().format('YYYY-MM-DD') && moment() < moment(timeslot, 'h:mm a').add(5, 'minutes') ? "box futureTimeSlot" : date === moment().format('YYYY-MM-DD') ? "box previousTimeSlot" : 'box'} id={teeSheet.id + moment(timeslot, 'h:mm a').format('hmma')} key={teeSheet.id + moment(timeslot, 'h:mm a').format('hmma')}>

                                    <td>{timeslot}</td>
                                    <td
                                      data-time={timeslot}
                                      data-starttee={teeSheet.id}
                                      data-reroundtee={teeSheet.reroundTeeID}
                                      onClick={() => {
                                        addTeeGroup(timeslot, teeSheet);
                                        setTeeSheetTimeSlots(generateTeeSheetTimeSlots(teeSheet));
                                      }}
                                    >

                                      {teeSheet.blockedTimes?.map((blockedTime, i) =>
                                        blockedTime.time.includes(timeslot) && blockedTime.days.includes(moment(date).format('dddd')) &&
                                        <IonItem key={"blocked" + teeSheet.id + moment(blockedTime.time) + i} data-id={blockedTime.id} className="teeGroup teeBlock" lines="none" onClick={(e) => handleReservedTeeTimeClick(e)}>RECURRING: {blockedTime.title}</IonItem>
                                      )}

                                      {teeTimeGroups?.map((teeTime) =>
                                        // This is for pending slots only
                                        teeTime.date === date && teeTime.status === 'pending' && moment(teeTime.expiration, 'h:mm a').format('h:mm a') > moment().format('h:mm a') && (
                                          (teeTime.startingTeeID === teeSheet.id && timeslot === teeTime.startingTime)
                                          || (teeTime.reroundTeeID === teeSheet.id && timeslot === teeTime.reroundTime && teeTime.numHoles === 18)
                                          || (teeTime.blockTeeID === teeSheet.id && moment(timeslot, 'h:mm a') >= moment(teeTime.blockStartTime, 'h:mm a') && moment(timeslot, 'h:mm a') <= moment(teeTime.blockEndTime, 'h:mm a'))
                                        ) &&
                                        <IonItem key={teeSheet.id + teeTime.id} data-id={teeTime.id} className="teeBlock pendingTeeBlock" onClick={(event) => {
                                          handlePendingClick(event, teeTime)
                                        }} lines="none">
                                          {teeTime.reroundTeeID === teeSheet.id && <IonLabel color="medium" className="teeGroupNumHolesBadge">RR</IonLabel>}
                                          Pending online reservation (Expires: {moment(teeTime.expiration, 'h:mm a').add(1, 'minute').format('h:mm a')})</IonItem>
                                      )}

                                      {teeTimeGroups?.map((teeTime) =>
                                        // if this tee time's starting time or reround time is equal to this time slot...
                                        teeTime.date === date && teeTime.status !== 'pending' && (
                                          (teeTime.startingTeeID === teeSheet.id && timeslot === teeTime.startingTime)
                                          || (teeTime.reroundTeeID === teeSheet.id && timeslot === teeTime.reroundTime && teeTime.numHoles === 18)
                                          || (teeTime.blockTeeID === teeSheet.id && moment(timeslot, 'h:mm a') >= moment(teeTime.blockStartTime, 'h:mm a') && moment(timeslot, 'h:mm a') <= moment(teeTime.blockEndTime, 'h:mm a'))
                                          || (teeTime.blockReroundTeeID === teeSheet.id && moment(timeslot, 'h:mm a') >= moment(teeTime.blockReroundStartTime, 'h:mm a') && moment(timeslot, 'h:mm a') <= moment(teeTime.blockReroundEndTime, 'h:mm a'))

                                        ) &&
                                        //eslint-disable-next-line
                                        <IonItem key={teeSheet.id + teeTime.id} draggable={teeTime.blockTitle ? "false" : "true"} id={teeTime.reroundTeeID === teeSheet.id ? 'reround ' + teeTime.id : 'start ' + teeTime.id} data-id={teeTime.id} className={(teeTime.blockTitle || teeTime.blockTeeID ? ("teeGroup teeBlock") : ("teeGroup moveableGroup")) + (teeTime.status === "confirmed" ? (" onlineGroup") : (" no-source"))} lines="none" onClick={!teeTime.blockTitle && !teeTime.blockTeeID && ((event) => { event.stopPropagation(); setTeeGroupToEdit(teeTime); setTeeSheetTimeSlots(generateTeeSheetTimeSlots(teeSheet)); }) || (teeTime.blockTitle || teeTime.blockTeeID) && ((event) => {
                                          handleBlockClick(event, teeTime); setTeeSheetTimeSlots(generateTeeSheetTimeSlots(teeSheet));
                                        })}>
                                          {teeTime.blockReroundTeeID === teeSheet.id && <IonLabel color="medium" className="teeGroupNumHolesBadge">RR</IonLabel>}

                                          {teeTime.blockTitle && ("RESERVED: " + teeTime.blockTitle)}

                                          {teeTime.numHoles && timeslot === teeTime.startingTime &&
                                            <IonLabel color={teeTime?.teedOff ? "success" : "medium"} className="teeGroupNumHolesBadge">{teeTime.numHoles}</IonLabel>
                                          }
                                          {!teeTime.blockTitle && teeTime.reroundTeeID === teeSheet.id && <IonLabel color={teeTime?.reRounded ? "success" : "medium"} className="teeGroupNumHolesBadge">RR</IonLabel>}
                                          {teeTime.status === "confirmed" && <IonBadge className="teeSheetGolfer onlineBadge ion-no-padding" color="secondary">online</IonBadge>}
                                          {cartTeeGroups.includes(teeTime.id) && <IonBadge className="teeSheetGolfer onlineBadge ion-no-padding" color="tertiary">IN CART</IonBadge>}

                                          {!teeTime.blockTitle && Array.isArray(teeTime.golfers) && teeTime.golfers?.map((golfer, i) =>
                                            <IonBadge key={teeSheet.id + teeTime.id + "-golfer-" + i} className="teeSheetGolfer ion-no-padding" color={golfer.checkedIn ? "success" : "warning"}>
                                              {golfer.name}
                                              {// @ts-ignore
                                                golfer.paid && <FontAwesomeIcon className="golferIcon" icon={faDollarSign} />}
                                              {// @ts-ignore
                                                golfer.cart && golfer.cart !== "walk" && <FontAwesomeIcon className="golferIcon" icon={faCarSide} />}
                                              {// @ts-ignore
                                                (golfer.cart === "walk" || !golfer.cart) && <FontAwesomeIcon className="golferIcon" icon={faWalking} />}
                                              {// @ts-ignore
                                                teeTime.numHoles === 18 && golfer.numHoles === 9 && <span className="numHolesBadge">9</span>}
                                              {// @ts-ignore
                                                golfer.notes && <FontAwesomeIcon className="golferIcon" icon={faCommentAlt} />}
                                              {// @ts-ignore
                                                golfer.league > '' && <FontAwesomeIcon className="golferIcon" icon={faTrophy} />}
                                              {// @ts-ignore
                                                golfer.color > '' && <FontAwesomeIcon className="golferIcon" style={{ color: golfer.color }} icon={faTshirt} />}
                                            </IonBadge>
                                          )}


                                          {!teeTime.blockTitle &&
                                            <IonButton className="moveTeeTimeButton" color="medium" slot="end" fill="clear" size="small"><IonIcon slot="icon-only" icon={menuOutline}></IonIcon></IonButton>
                                          }

                                        </IonItem>
                                      )}
                                    </td>

                                  </tr>
                                )}
                              </tbody>
                            </table>

                          </IonCol>
                        )
                      ) : ('This course doesn\'t have any tee sheets yet.')}
                    </IonRow>
                  </IonGrid>

                </div>
                {/* Group Add Dialog Modal */}
                <IonModal isOpen={Boolean(newTeeGroup)} cssClass='groupAddDialog teeSheetModal' backdropDismiss={true} onDidDismiss={() => setNewTeeGroup(null)} onDidPresent={() => focusOnFirstInput('newTeeGroupName')}>
                  <div className="modalHeader ion-text-start">
                    Add a Group
                    <IonButton className="addBlockedTeeTimeButton" slot="end" color="medium" size="small" onClick={addTeeBlockTime} >
                      BLOCK THIS TIMESLOT
                    </IonButton>
                  </div>
                  <form onSubmit={handleAddGroup} >
                    <IonList lines="none">

                      <IonItem key="groupNameInput">
                        <IonLabel>Name</IonLabel>
                        <IonInput id="newTeeGroupName" required onIonChange={e => setNewTeeGroup(prevState => ({ ...prevState, golfers: prevState.golfers?.length ? prevState.golfers?.map((g, i) => ({ ...g, name: i === 0 ? e.detail.value : g.name })) : [{ name: e.detail.value }] }))} className="rightAlignedTextInput" placeholder="Golfer's Name"></IonInput>
                      </IonItem>

                      <IonItem key="addPlayersInput">
                        <IonLabel># Players</IonLabel>

                        <div className="numPlayersButtonGroup">
                          {numPlayersRadioOptions?.map((number, i) =>
                            <IonButton key={"numPlayers" + number} fill={newTeeGroup?.golfers?.length === number ? "solid" : "clear"} slot="end" onClick={e => {
                              const newGolfers = Array.from(Array(number - 1).keys())?.map(i => ({ name: 'Guest' }));
                              setNewTeeGroup(prevState => ({ ...prevState, golfers: [{ name: prevState.golfers[0]?.name }].concat(newGolfers) }));
                            }}>{number}</IonButton>
                          )}
                        </div>

                      </IonItem>

                      <IonItem key="numHolesInput">
                        <IonLabel># of Holes</IonLabel>
                        <div className="numPlayersButtonGroup">
                          {numHolesRadioOptions?.map((number, i) =>
                            <IonButton key={"numHoles" + number} fill={newTeeGroup?.numHoles === number ? "solid" : "clear"} slot="end" onClick={e => {
                              setNewTeeGroup(prevState => ({ ...prevState, numHoles: number }))
                            }}>{number}</IonButton>
                          )}
                        </div>

                      </IonItem>

                      <IonItem className="timeSelect">
                        <IonLabel key="groupStartTimeInput">Starting Tee Time</IonLabel>
                        <IonSelect onClick={scrollToSelection} interfaceOptions={{ cssClass: 'timeSelect' }} onIonChange={e => setNewTeeGroup(prevState => ({ ...prevState, startingTime: e.detail.value }))} value={newTeeGroup?.startingTime} interface="popover">
                          {teeSheetTimeSlots?.map((timeslot, i) =>
                            // @ts-ignore
                            <IonSelectOption key={"starttime" + timeslot} value={timeslot}>{timeslot}</IonSelectOption>
                          )}
                        </IonSelect>
                      </IonItem>

                      <IonItem className={reRoundTimeSlotGolfers?.length !== 0 && newTeeGroup?.numHoles !== 9 ? "timeslotIsFull" : "timeslotIsAvailable"}>
                        <IonLabel key="groupEndTimeInput">Re-round Tee Time</IonLabel>
                        <IonSelect onClick={scrollToSelection} interfaceOptions={{ cssClass: 'timeSelect' }} disabled={newTeeGroup?.numHoles === 9} onIonChange={e => setNewTeeGroup(prevState => ({ ...prevState, reroundTime: e.detail.value }))} value={newTeeGroup?.reroundTime} interface="popover">
                          {teeSheetTimeSlots?.map((timeslot, i) =>
                            // @ts-ignore
                            <IonSelectOption key={"reroundtime-" + timeslot} value={timeslot}>{timeslot}</IonSelectOption>
                          )}
                        </IonSelect>
                      </IonItem>
                      {reRoundTimeSlotGolfers?.length !== 0 && reRoundTimeSlotGolfers[0] !== 'blockedReround' && reRoundTimeSlotGolfers[0] !== 'blockedRecurringReround' && newTeeGroup?.numHoles !== 9 && <p className="timeslotError">The selected Reround time already has {reRoundTimeSlotGolfers?.length} golfer(s).{/*({reRoundTimeSlotGolfers.join(", ")})*/}</p>}
                      {reRoundTimeSlotGolfers[0] === 'blockedReround' && newTeeGroup?.numHoles !== 9 && <p className="timeslotError">The selected Reround time has been blocked.</p>}
                      {reRoundTimeSlotGolfers[0] === 'blockedRecurringReround' && newTeeGroup?.numHoles !== 9 && <p className="timeslotError">The selected Reround time is during a recurring blocked time.</p>}


                      <IonItem key="reroundTeeInput">
                        <IonLabel>Re-round Tee</IonLabel>
                        <IonSelect onIonChange={e => setNewTeeGroup(prevState => ({ ...prevState, reroundTeeID: e.detail.value }))} value={newTeeGroup?.reroundTeeID} disabled={newTeeGroup?.numHoles === 9} interface="popover">
                          <IonSelectOption value={null}>Please Select</IonSelectOption>
                          {teeSheets?.map((teeSheet, i) => <IonSelectOption key={"reroundteefornewteegroup" + teeSheet.id} value={teeSheet.id}>{teeSheet.name}</IonSelectOption>)}
                        </IonSelect>
                      </IonItem>

                    </IonList>
                    <IonRow>
                      <IonCol><IonButton type="submit" expand="block">Add Group</IonButton></IonCol>
                    </IonRow>
                  </form>
                </IonModal>
                {/* {console.log('teeGroupToEdit==>>', teeGroupToEdit)} */}
                {/* Group Edit Dialog Modal */}
                <IonModal isOpen={Boolean(teeGroupToEdit)} /*isOpen={true}*/ cssClass='groupEditDialog teeSheetModal' backdropDismiss={false} onDidDismiss={() => setTeeGroupToEdit(null)}>
                  <form onSubmit={editGroup}>

                    <IonToolbar id="groupEditDialogToolbar">
                      <IonTitle className="ion-float-left  ion-padding">Edit Group</IonTitle>


                      <div id="editGroupDetails">
                        <IonItem key="numHolesInput">
                          <IonLabel position="stacked">Number of Holes</IonLabel>
                          <IonSelect onIonChange={e => setTeeGroupToEdit(prevState => ({ ...prevState, numHoles: e.detail.value }))} value={teeGroupToEdit?.numHoles} interface="popover">
                            <IonSelectOption value={9}>9</IonSelectOption>
                            <IonSelectOption value={18}>18</IonSelectOption>
                          </IonSelect>
                        </IonItem>

                        <IonItem key="groupStartTimeInput">
                          <IonLabel position="stacked">Starting Tee Time</IonLabel>
                          <IonSelect className="addReroundTeeInput" onClick={scrollToSelection} interfaceOptions={{ cssClass: 'timeSelect' }} onIonChange={e => setTeeGroupToEdit(prevState => ({ ...prevState, startingTime: e.detail.value }))} value={teeGroupToEdit?.startingTime} interface="popover">
                            {teeSheetTimeSlots?.map((timeslot, i) =>
                              // @ts-ignore
                              <IonSelectOption key={"groupStartEdit" + timeslot} value={timeslot}>{timeslot}</IonSelectOption>
                            )}
                          </IonSelect>
                        </IonItem>

                        {teeGroupToEdit?.numHoles === 18 &&
                          <>
                            <IonItem key="groupReroundTimeInput">
                              <IonLabel position="stacked">Re-round Tee Time</IonLabel>
                              <IonSelect onClick={scrollToSelection} interfaceOptions={{ cssClass: 'timeSelect' }} disabled={teeGroupToEdit?.numHoles === 9} onIonChange={e => setTeeGroupToEdit(prevState => ({ ...prevState, reroundTime: e.detail.value }))} value={teeGroupToEdit?.reroundTime} interface="popover">
                                {teeSheetTimeSlots?.map((timeslot, i) =>
                                  // @ts-ignore
                                  <IonSelectOption key={"groupReroundEdit" + timeslot} value={timeslot}>{timeslot}</IonSelectOption>
                                )}
                              </IonSelect>
                            </IonItem>

                            <IonItem key="reroundTeeInput">
                              <IonLabel position="stacked">Re-round Tee</IonLabel>
                              <IonSelect onIonChange={e => setTeeGroupToEdit(prevState => ({ ...prevState, reroundTeeID: e.detail.value }))} value={teeGroupToEdit?.reroundTeeID} disabled={teeGroupToEdit?.numHoles === 9} interface="popover">
                                {teeSheets?.map((teeSheet, i) =>
                                  <IonSelectOption key={"groupRRTEE" + teeSheet.id} value={teeSheet.id}>{teeSheet.name}</IonSelectOption>
                                )}
                              </IonSelect>
                            </IonItem>
                          </>
                        }

                        {teeGroupToEdit?.status === "confirmed" && teeGroupToEdit?.receiptNumber &&
                          <IonItem key="receipt">
                            <IonLabel position="stacked">Receipt</IonLabel>
                            <div className="receiptNumber">{teeGroupToEdit?.receiptNumber}</div>
                          </IonItem>
                        }

                      </div>

                      <IonButtons slot="end" className="ion-padding-right editGroupButtons ion-padding">
                        <IonText color="medium ion-padding-end"><small>MARK ALL:</small></IonText>
                        <IonButton onClick={() => handleSwitchAll('paid')} color="warning">Paid</IonButton>
                        <IonButton onClick={() => handleSwitchAll('checkedIn')} color="warning">Checked-In</IonButton>
                        <IonButton onClick={() => handleSwitchAll('cart')} color="warning">Cart</IonButton>
                      </IonButtons>

                      <IonButtons slot="end" className="ion-padding-start ion-padding-vertical editGroupButtons editGroupSaveCancelButton">
                        <IonButton className="groupEditCancelButton" color="danger" fill="clear" onClick={(event) => { event.stopPropagation(); setTeeGroupToEdit(null); }}>Cancel</IonButton>
                        <IonButton className="groupEditSubmitButton" color="success" fill="clear" type="submit" onClick={(event) => { event.stopPropagation(); setTeeGroupToEdit(null); }}>Save Changes</IonButton>
                      </IonButtons>

                    </IonToolbar>

                    <IonGrid>

                      <IonRow>
                        {teeGroupToEdit?.golfers?.map((golfer, i) =>
                          <IonCol key={"groupEditModalGolfer" + teeGroupToEdit.id + i} className="groupEditModalGolfer">

                            <IonToolbar className="groupGolferNumber text-center">
                              <IonTitle>Golfer #{i + 1}</IonTitle>
                              {/* <IonButtons slot="end">
                              <IonButton slot="end" className="ion-margin-top" onClick={() => setGolferToDeleteFromGroup(i)} fill="clear" expand="block" color="danger"><IonIcon icon={trashOutline} slot="icon-only" size="small"></IonIcon></IonButton>
                            </IonButtons> */}

                            </IonToolbar>

                            <div className="groupEditModalGolferFields">
                              <IonItem key="golferNameInput" >
                                <IonLabel position="fixed" style={{ fontSize: "12px" }}>Name</IonLabel>
                                <IonInput name="name" onIonChange={event => handleTeeGroupGolfersInputChange({ event, index: i })} value={golfer?.name || ''} className="underlinedInput" placeholder="Golfer's Name"></IonInput>
                              </IonItem>


                              <IonRow className="golferStatus">

                                <IonCol sizeXs="5">
                                  <div className="toggleLabel">Paid</div>

                                  <IonToggle name="paid" checked={golfer?.paid || false} onIonChange={event => handleTeeGroupGolfersToggleChange({ event, index: i })} />
                                </IonCol>


                                <IonCol sizeXs="7">
                                  <div className="toggleLabel">Checked-In</div>
                                  <IonToggle name="checkedIn" checked={golfer?.checkedIn || false} onIonChange={event => handleTeeGroupGolfersToggleChange({ event, index: i })} />
                                </IonCol>

                              </IonRow>

                              <IonItem key="golferNumHoles">
                                <IonLabel position="fixed" style={{ fontSize: "12px" }}># Holes</IonLabel>
                                <div className="numPlayersButtonGroup buttonGroup">
                                  {numHolesRadioOptions?.map((number, index) =>
                                    //eslint-disable-next-line
                                    <IonButton disabled={number > teeGroupToEdit.numHoles ? true : false} className={"golfer" + i} key={"EditgolferNumHoles" + number} fill={(golfer?.numHoles === number || !golfer.numHoles && teeGroupToEdit.numHoles === number) ? "solid" : "clear"} slot="end" onClick={e => {
                                      handleTeeGroupGolfersKeyValueChange({ key: "numHoles", value: number, index: i })
                                    }}>{number}</IonButton>
                                  )}
                                </div>

                              </IonItem>

                              <IonItem key="golferCart">
                                <IonLabel position="fixed" style={{ fontSize: "12px" }}>Cart</IonLabel>
                                <div className="numPlayersButtonGroup buttonGroup">
                                  <IonButton key="walk" fill={golfer?.cart === "walk" || !golfer.cart ? "solid" : "clear"} slot="end" onClick={e => {
                                    handleTeeGroupGolfersKeyValueChange({ key: "cart", value: "walk", index: i })
                                  }}>
                                    {/* @ts-ignore */}
                                    <FontAwesomeIcon className="golferIcon" icon={faWalking} /></IonButton>
                                  {numHolesRadioOptions?.map((number, index) =>
                                    <IonButton key={"EditgolferCart" + number} fill={golfer?.cart === number || (golfer?.cart === true && number === teeGroupToEdit.numHoles) ? "solid" : "clear"} slot="end" onClick={e => {
                                      handleTeeGroupGolfersKeyValueChange({ key: "cart", value: number, index: i })
                                    }}>{number}</IonButton>
                                  )}
                                </div>

                              </IonItem>

                              <IonItem key="golferShirtColor" id="golferShirtColor">

                                <IonLabel position="fixed" style={{ fontSize: "12px" }}>Shirt Color</IonLabel>
                                <div className="colorPickerButton" slot="end" onClick={e => { e.persist(); setShowShirtColorPopover({ show: true, index: i, color: golfer?.color, event: e }) }}>
                                  <div className="colorPickerButtonInner" style={{ background: golfer?.color }}></div>
                                </div>

                              </IonItem>

                              {/* <IonItem key="leagueInput">
                                <IonLabel position="fixed" style={{ fontSize: "12px" }}>League</IonLabel>
                                <IonSelect className="underlinedInput" name="league" onIonChange={event => handleTeeGroupGolfersInputChange({ event, index: i })} value={golfer?.league || ''} interface="popover">
                                  <IonSelectOption value={null}>None</IonSelectOption>
                                  <IonSelectOption value="mens">Men's Association</IonSelectOption>
                                  <IonSelectOption value="womens">Women's Association</IonSelectOption>
                                  <IonSelectOption value="seniors">Seniors' Association</IonSelectOption>
                                  <IonSelectOption value="couples">Couples' Association</IonSelectOption>
                                  <IonSelectOption value="juniors">Junior's Association</IonSelectOption>
                                </IonSelect>
                              </IonItem> */}

                              <IonItem key="golferPhoneInput">
                                <IonLabel position="fixed" style={{ fontSize: "12px" }}>Phone</IonLabel>
                                <IonInput name="phone" className="underlinedInput" onIonChange={event => handleTeeGroupGolfersInputChange({ event, index: i })} value={golfer?.phone || ''} ></IonInput>
                              </IonItem>

                              <IonItem key="golferEmailInput">
                                <IonLabel position="fixed" style={{ fontSize: "12px" }}>Email</IonLabel>
                                <IonInput name="email" className="underlinedInput" onIonChange={event => handleTeeGroupGolfersInputChange({ event, index: i })} value={golfer?.email || ''} ></IonInput>
                              </IonItem>



                              <IonItem key="receiptInput">
                                <IonLabel position="fixed" style={{ fontSize: "12px" }}>Receipt #</IonLabel>
                                <IonInput className="underlinedInput" name="receipt" onIonChange={event => handleTeeGroupGolfersInputChange({ event, index: i })} value={golfer?.receipt || ''}></IonInput>
                              </IonItem>

                              <IonItem key="groupNotes">
                                <IonLabel position="stacked">Notes</IonLabel>
                                <IonTextarea name="notes" className="notesArea underlinedInput" value={golfer?.notes || ''} onIonChange={event => handleTeeGroupGolfersInputChange({ event, index: i })}></IonTextarea>
                              </IonItem>

                              {/* <IonButton className="ion-margin-top" onClick={() => setGolferToDeleteFromGroup(i)} fill="clear" expand="block" color="danger"><IonIcon icon={trashOutline} slot="start"></IonIcon>Remove Golfer</IonButton> */}
                              <IonButton className="opacityOnHover ion-margin-top ion-margin-bottom" onClick={() => deleteSingleGolfer(i)} fill="clear" size="small" expand="block" color="danger"><IonIcon icon={trashOutline} slot="start"></IonIcon>Remove Golfer</IonButton>

                            </div>
                          </IonCol>
                        )}

                        {teeGroupToEdit?.golfers?.length < 6 &&
                          <IonCol className="groupEditModalGolfer" >
                            <IonButton onClick={() => addGolferToGroup(teeGroupToEdit)} className="groupEditAddGolferButton" expand="block" color="light"><IonIcon icon={addOutline} slot="start"></IonIcon>Add A Golfer</IonButton>
                          </IonCol>
                        }

                      </IonRow>

                    </IonGrid>

                    <IonToolbar id="groupEditDialogFooter" className="ion-no-margin">
                      <IonButtons slot="start" className="ion-margin-start">
                        <IonButton className="opacityOnHover" onClick={() => setConfirmDelete({ isOpen: true, type: 'group' })} fill="clear" expand="block" color="danger"><IonIcon icon={trashOutline} slot="start"></IonIcon>Remove Group</IonButton>
                      </IonButtons>
                      <div className="editTeeGroupID">ID: {teeGroupToEdit?.id}</div>
                      <IonButtons slot="end" className="ion-padding-right ion-padding">
                        <IonButton onClick={e => toggleTeeGroupStatus("teedOff")} className="groupGoneButton" expand="block" color={teeGroupToEdit?.teedOff ? "success" : "warning"}><IonIcon icon={teeGroupToEdit?.teedOff ? checkmarkOutline : closeOutline} slot="start"></IonIcon>{teeGroupToEdit?.teedOff ? "Teed Off" : "Teed Off"}</IonButton>
                        <IonButton onClick={e => toggleTeeGroupStatus("reRounded")} className="groupGoneButton" expand="block" color={teeGroupToEdit?.reRounded ? "success" : "warning"}><IonIcon icon={teeGroupToEdit?.reRounded ? checkmarkOutline : closeOutline} slot="start"></IonIcon>{teeGroupToEdit?.reRounded ? "Rerounded" : "Rerounded"}</IonButton>
                        {cartTeeGroups && cartTeeGroups.includes(teeGroupToEdit?.id) &&
                          <IonButton fill="clear" onClick={() => removeCartTeeGroupItem(teeGroupToEdit?.id)} expand="block" color="danger"><IonIcon icon={cartOutline} slot="start"></IonIcon>Remove From Current Cart</IonButton>
                        }

                        {(!cartTeeGroups || !cartTeeGroups.includes(teeGroupToEdit?.id)) &&
                          <IonButton onClick={() => addCartTeeGroupItem(teeGroupToEdit?.id)} fill="clear" expand="block" color="primary"><IonIcon icon={cartOutline} slot="start"></IonIcon>Assign Group to Cart</IonButton>
                        }

                      </IonButtons>

                    </IonToolbar>

                  </form>

                </IonModal>


                <Cart showCartModal={showCartModal} setShowCartModal={setShowCartModal}></Cart>


                {/* Tee Block Edit Dialog Modal */}
                <IonModal isOpen={Boolean(teeBlockToEdit)} cssClass='blockEditDialog teeSheetModal' backdropDismiss={true} onDidDismiss={() => setTeeBlockToEdit(null)}>
                  <IonToolbar id="blockEditToolbar">
                    <IonTitle>Edit Block</IonTitle>

                    <IonButtons slot="end">
                      <IonButton onClick={() => setConfirmDelete({ isOpen: true, type: 'block' })} fill="clear" color="danger"><IonIcon icon={trashOutline} slot="icon-only"></IonIcon></IonButton>
                    </IonButtons>
                  </IonToolbar>

                  <form onSubmit={editBlock}>
                    <IonList lines="none">

                      <IonItem key="blockTitleInput">
                        <IonLabel>Block Title</IonLabel>
                        <IonInput onIonChange={e => setTeeBlockToEdit(prevState => ({ ...prevState, blockTitle: e.detail.value }))} value={teeBlockToEdit?.blockTitle} className="rightAlignedTextInput" placeholder="Name or description"></IonInput>
                      </IonItem>

                      <IonItem key="blockTeeInput">
                        <IonLabel>Block Tee</IonLabel>
                        <IonSelect onIonChange={e => setTeeBlockToEdit(prevState => ({ ...prevState, blockTeeID: e.detail.value }))} value={teeBlockToEdit?.blockTeeID} interface="popover">
                          <IonSelectOption value={null}>Please Select</IonSelectOption>
                          {teeSheets?.map(teeSheet => <IonSelectOption key={"blockTeeInput" + teeBlockToEdit?.id + teeSheet?.id} value={teeSheet.id}>{teeSheet.name}</IonSelectOption>)}
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel key="blockStartTimeInput">Block Start Time</IonLabel>
                        <IonSelect onClick={scrollToSelection} interfaceOptions={{ cssClass: 'timeSelect' }} onIonChange={e => setTeeBlockToEdit(prevState => ({ ...prevState, blockStartTime: e.detail.value, blockReroundStartTime: moment(e.detail.value, 'h:mm a').add(120, 'minutes').format('h:mm a') }))} value={teeBlockToEdit?.blockStartTime} interface="popover">
                          {teeSheetTimeSlots?.map((timeslot, i) =>
                            // @ts-ignore
                            <IonSelectOption key={"blockStartTime:" + teeBlockToEdit?.id + timeslot} value={timeslot}>{timeslot}</IonSelectOption>
                          )}
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel key="blockEndTimeInput">Block End Time</IonLabel>
                        <IonSelect onClick={scrollToSelection} interfaceOptions={{ cssClass: 'timeSelect' }} onIonChange={e => setTeeBlockToEdit(prevState => ({ ...prevState, blockEndTime: e.detail.value, blockReroundEndTime: moment(e.detail.value, 'h:mm a').add(120, 'minutes').format('h:mm a') }))} value={teeBlockToEdit?.blockEndTime} interface="popover">
                          {teeSheetTimeSlots?.map((timeslot, i) =>
                            // @ts-ignore
                            <IonSelectOption key={"blockEndTime:" + teeBlockToEdit?.id + timeslot} value={timeslot}>{timeslot}</IonSelectOption>
                          )}
                        </IonSelect>
                      </IonItem>

                      <IonItem key="blockReroundTeeInput">
                        <IonLabel>Block Reround Tee</IonLabel>
                        <IonSelect onIonChange={e => setTeeBlockToEdit(prevState => ({ ...prevState, blockReroundTeeID: e.detail.value }))} value={teeBlockToEdit?.blockReroundTeeID} interface="popover">
                          <IonSelectOption value={null}>Please Select</IonSelectOption>
                          {teeSheets?.map(teeSheet => <IonSelectOption key={"blockRRTee:" + teeSheet?.id} value={teeSheet.id}>{teeSheet.name}</IonSelectOption>)}
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel key="blockReroundStartTimeInput">Block Reround Start Time</IonLabel>
                        <IonSelect onClick={scrollToSelection} interfaceOptions={{ cssClass: 'timeSelect' }} onIonChange={e => setTeeBlockToEdit(prevState => ({ ...prevState, blockReroundStartTime: e.detail.value }))} value={teeBlockToEdit?.blockReroundStartTime} interface="popover">
                          {teeSheetTimeSlots?.map((timeslot, i) =>
                            // @ts-ignore
                            <IonSelectOption key={"blockRRStart:" + teeBlockToEdit?.id + timeslot} value={timeslot}>{timeslot}</IonSelectOption>
                          )}
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel key="blockReroundEndTimeInput">Block Reround End Time</IonLabel>
                        <IonSelect onClick={scrollToSelection} interfaceOptions={{ cssClass: 'timeSelect' }} onIonChange={e => setTeeBlockToEdit(prevState => ({ ...prevState, blockReroundEndTime: e.detail.value }))} value={teeBlockToEdit?.blockReroundEndTime} interface="popover">
                          {teeSheetTimeSlots?.map((timeslot, i) =>
                            // @ts-ignore
                            <IonSelectOption key={"blockRREnd:" + teeBlockToEdit?.id + timeslot} value={timeslot}>{timeslot}</IonSelectOption>
                          )}
                        </IonSelect>
                      </IonItem>



                    </IonList>

                    <IonRow>
                      <IonCol><IonButton type="submit" expand="block">Save Changes</IonButton></IonCol>
                    </IonRow>
                  </form>
                </IonModal>

                {/* Show Table Print Modal */}
                <IonModal isOpen={showPrintModal} backdropDismiss={true} onDidPresent={() => setShowLoading(false)} onDidDismiss={() => setShowPrintModal(false)} cssClass='printModal'>
                  <div className="printModalTitle">Which view do you want to print?</div>
                  <IonGrid className="ion-padding">
                    <IonRow>
                      <IonCol><IonButton expand="full" onClick={() => { openPrintModal('compact'); setShowPrintModal(false); }}>Compact View</IonButton></IonCol>
                      <IonCol><IonButton expand="full" onClick={() => { openPrintModal('expanded'); setShowPrintModal(false); }}>Expanded View</IonButton></IonCol>
                    </IonRow>
                  </IonGrid>
                </IonModal>

                {/* Show Table Print Modal */}
                <IonModal isOpen={showTablePrintModal} backdropDismiss={true} onDidPresent={() => setShowLoading(false)} onDidDismiss={() => setShowTablePrintModal(false)} cssClass='printTableModal'>
                  <PDFViewer width={1200} height={800} style={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', }}>
                    <Document>
                      <Page size="LETTER" orientation="portrait" style={{ padding: 20 }}>
                        <View fixed>
                          <Text style={{ fontSize: 16, textAlign: 'center', marginTop: 0 }}>Tee Times for {moment(date, 'YYYY-MM-DD').format("MMM Do, YYYY")}</Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                          {teeSheets?.length ? (
                            teeSheets?.map((teeSheet, i) =>
                              teeSheet.active &&
                              <View key={"PRINTSCREEN" + teeSheet.id}>
                                <Text fixed style={{ fontSize: '12', textAlign: 'center', width: '50%', marginBottom: 3 }}>{teeSheet.name}</Text>

                                <View style={{ marginLeft: 10, marginRight: 10, flexGrow: 1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                  {teeSheet.timeslots?.map((timeslot, i) =>
                                    <View key={"PRINTSCREEN" + teeSheet.id + timeslot} id={"PRINTSCREEN" + teeSheet.id + timeslot} wrap={false} style={{ marginTop: -1, width: '100%', borderColor: 'black', border: 1, fontSize: '9', flexDirection: 'row' }}>
                                      <Text style={{ backgroundColor: 'black', color: 'white', width: '17%', fontSize: '9', padding: '3', lineHeight: 1 }}>{timeslot}</Text>



                                      <View style={{ width: '83%' }}>

                                        {teeSheet.blockedTimes?.map((blockedTime, i) =>
                                          blockedTime.time.includes(timeslot) && blockedTime.days.includes(moment(date).format('dddd')) &&
                                          <Text key={"PRINTSCREEN" + teeSheet?.id + timeslot + blockedTime?.time[i]} data-id={blockedTime.id} style={{ padding: '3', width: '100%' }}>RECURRING: {blockedTime.title}</Text>
                                        )}

                                        {teeTimeGroups?.map((teeTime) =>
                                          // if this tee time's starting time or reround time is equal to this time slot...
                                          teeTime.date === date && teeTime.status !== 'pending' && (
                                            (teeTime.startingTeeID === teeSheet.id && timeslot === teeTime.startingTime)
                                            || (teeTime.reroundTeeID === teeSheet.id && timeslot === teeTime.reroundTime && teeTime.numHoles === 18)
                                            || (teeTime.blockTeeID === teeSheet.id && moment(timeslot, 'h:mm a') >= moment(teeTime.blockStartTime, 'h:mm a') && moment(timeslot, 'h:mm a') <= moment(teeTime.blockEndTime, 'h:mm a'))
                                            || (teeTime.blockReroundTeeID === teeSheet.id && moment(timeslot, 'h:mm a') >= moment(teeTime.blockReroundStartTime, 'h:mm a') && moment(timeslot, 'h:mm a') <= moment(teeTime.blockReroundEndTime, 'h:mm a'))

                                          ) &&

                                          <View key={"PRINTSCREEN" + teeSheet?.id + teeTime?.id} style={teeTime.blockTitle ? { padding: '3', width: '100%' } : { padding: '3', flexGrow: 1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}  >
                                            {teeTime.blockReroundTeeID === teeSheet.id && <Text>RESERVED: {teeTime.blockTitle} RR: {teeTime.blockStartTime}</Text>}

                                            {teeTime.blockTitle && teeTime.blockTeeID === teeSheet.id && <Text>RESERVED: {teeTime.blockTitle} </Text>}

                                            {teeTime.numHoles && teeTime.reroundTeeID !== teeSheet.id &&
                                              <Text>{teeTime.numHoles} HOLES: </Text>
                                            }
                                            {!teeTime.blockTitle && teeTime.reroundTeeID === teeSheet.id && <Text>RR: </Text>}

                                            {!teeTime.blockTitle && Array.isArray(teeTime.golfers) && teeTime.golfers?.map((golfer, i) =>
                                              <Text key={"PRINTSCREEN" + teeSheet?.id + teeTime?.id + "golfer" + i}>
                                                {golfer.name + ", "}
                                              </Text>
                                            )}


                                          </View>
                                        )}
                                      </View>



                                    </View>
                                  )}
                                </View>
                              </View>
                            )
                          ) : ('This course doesn\'t have any tee sheets yet.')}
                        </View>
                      </Page>
                    </Document>
                  </PDFViewer>
                </IonModal>



                {/* Show Grid Print Modal */}
                <IonModal isOpen={showGridPrintModal} backdropDismiss={true} onDidPresent={() => setShowLoading(false)} onDidDismiss={() => setShowGridPrintModal(false)} cssClass='printGridModal'>
                  <PDFViewer width={1200} height={800} style={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex' }}>
                    <Document>
                      <Page size="LETTER" orientation="landscape" style={{ padding: 20 }}>
                        <View fixed>
                          <Text style={styles.pageTitle}>Tee Times for {moment(date, 'YYYY-MM-DD').format("MMM Do, YYYY")}</Text>
                        </View>
                        <View style={styles.page}>
                          {teeSheets?.length ? (
                            teeSheets?.map((teeSheet, i) =>
                              teeSheet.active &&
                              <View key={"GridPrint" + teeSheet.id}>
                                <Text fixed style={{ fontSize: '12', textAlign: 'center', width: '50%', }}>{teeSheet.name}</Text>

                                <View style={styles.section}>
                                  {teeSheet.timeslots?.map((timeslot, i) =>
                                    <View wrap={false} key={"GridPrint" + teeSheet.id + timeslot} style={{ marginTop: 10, width: '49%' }}>
                                      <Text style={{ backgroundColor: 'black', color: 'white', fontSize: '10', padding: '3', border: '1', borderColor: 'black', lineHeight: 1 }}>{timeslot}</Text>
                                      <View style={{ borderColor: 'black', fontSize: '10', border: '1', height: 60, flexDirection: 'row' }}>

                                        {/* Recurring Blocked Times */}
                                        {teeSheet.blockedTimes?.map((blockedTime, i) =>
                                          blockedTime.time.includes(timeslot) && blockedTime.days.includes(moment(date).format('dddd')) &&
                                          <Text key={"GridPrint" + teeSheet + "blockedTime" + blockedTime.time[i]} data-id={blockedTime.id} style={styles.blockedTime}>RECURRING: {blockedTime.title}</Text>
                                        )}

                                        {teeTimeGroups?.map((teeTime) =>
                                          // if this tee time's starting time or reround time is equal to this time slot...
                                          teeTime.date === date && teeTime.status !== 'pending' && (
                                            (teeTime.startingTeeID === teeSheet.id && timeslot === teeTime.startingTime)
                                            || (teeTime.reroundTeeID === teeSheet.id && timeslot === teeTime.reroundTime && teeTime.numHoles === 18)
                                            || (teeTime.blockTeeID === teeSheet.id && moment(timeslot, 'h:mm a') >= moment(teeTime.blockStartTime, 'h:mm a') && moment(timeslot, 'h:mm a') <= moment(teeTime.blockEndTime, 'h:mm a'))
                                            || (teeTime.blockReroundTeeID === teeSheet.id && moment(timeslot, 'h:mm a') >= moment(teeTime.blockReroundStartTime, 'h:mm a') && moment(timeslot, 'h:mm a') <= moment(teeTime.blockReroundEndTime, 'h:mm a'))

                                          ) &&

                                          <View key={"GridPrint" + teeSheet.id + "blockedTime" + teeTime} style={teeTime.blockTitle ? styles.blockedTime : styles.teeGroup}  >
                                            {teeTime.blockReroundTeeID === teeSheet.id && <Text style={styles.teeSheetGroupTitle}>RESERVED: {teeTime.blockTitle} RR: {teeTime.blockStartTime}</Text>}

                                            {teeTime.blockTitle && teeTime.blockTeeID === teeSheet.id && <Text style={styles.teeSheetGroupTitle}>RESERVED: {teeTime.blockTitle}</Text>}

                                            {teeTime.numHoles && teeTime.reroundTeeID !== teeSheet.id &&
                                              <Text style={styles.teeSheetGroupTitle}>{teeTime.numHoles} Holes</Text>
                                            }
                                            {!teeTime.blockTitle && teeTime.reroundTeeID === teeSheet.id && <Text style={styles.teeSheetGroupTitle}>RR: {teeTime.startingTime}</Text>}

                                            {!teeTime.blockTitle && Array.isArray(teeTime.golfers) && teeTime.golfers?.map((golfer, i) =>
                                              <Text key={"GridPrint" + teeSheet + "blockedTime" + teeTime + "golfer" + i} style={styles.teeSheetGolfer}>
                                                {golfer.name}
                                              </Text>
                                            )}


                                          </View>
                                        )}



                                      </View>
                                    </View>
                                  )}
                                </View>
                              </View>
                            )
                          ) : ('This course doesn\'t have any tee sheets yet.')}
                        </View>
                      </Page>
                    </Document>
                  </PDFViewer>
                </IonModal>

                {/* Delete Panel */}

                <IonAlert
                  isOpen={confirmDelete.isOpen}
                  onDidDismiss={() => setConfirmDelete({ isOpen: false, type: '' })}
                  cssClass='confirm-delete-panel'
                  header={'Are You Sure?'}
                  message={'Please confirm you would like to delete this <strong>' + confirmDelete.type + '</strong>. This action can\'t be undone.'}
                  buttons={[
                    {
                      text: 'Cancel',
                      role: 'cancel',
                      cssClass: 'cancelDeletion',
                    },
                    {
                      text: 'Delete',
                      cssClass: 'confirmDeletion',
                      handler: () => {
                        deleteItem(confirmDelete.type);
                      }
                    }
                  ]}
                />

                {/* Delete Golfer from Group Panel */}

                <IonAlert
                  isOpen={golferToDeleteFromGroup !== null ? true : false}
                  onDidDismiss={() => setGolferToDeleteFromGroup(null)}
                  cssClass='confirm-delete-golfer-from-group-panel'
                  header={'Are You Sure?'}
                  message={'Please confirm you would like to delete this <strong>golfer</strong> from this group. This action can\'t be undone.'}
                  buttons={[
                    {
                      text: 'Cancel',
                      role: 'cancel',
                      cssClass: 'cancelDeletion',
                    },
                    {
                      text: 'Delete',
                      cssClass: 'confirmDeletion',
                      handler: () => {
                        deleteSingleGolfer(golferToDeleteFromGroup);
                      }
                    }
                  ]}
                />

                {/* Delete Pending Panel */}

                <IonAlert
                  isOpen={confirmPendingDelete.isOpen}
                  onDidDismiss={() => setConfirmPendingDelete({ isOpen: false, id: null })}
                  cssClass='confirm-delete-panel'
                  header={'Delete this pending online reservation?'}
                  message={'<strong>Warning:</strong> If the expired time has past, you can safely delete this online reservation. If not, you may cause problems for a potential customer. Please confirm below. This action can\'t be undone.'}
                  buttons={[
                    {
                      text: 'Cancel',
                      role: 'cancel',
                      cssClass: 'cancelDeletion',
                    },
                    {
                      text: 'Delete',
                      cssClass: 'confirmDeletion',
                      handler: () => {
                        deletePendingItem(confirmPendingDelete.id);
                      }
                    }
                  ]}
                />

                {/* Recurring Reservation Panel */}

                <IonAlert
                  isOpen={recurringResAlert.isOpen}
                  onDidDismiss={() => setRecurringResAlert({ isOpen: false })}
                  cssClass='confirm-delete-panel'
                  header={'Edit Recurring Reservation?'}
                  message={'This is a RECURRING RESERVATION which is set on the tee sheet settings screen. Click the button below to edit this.'}
                  buttons={[
                    {
                      text: 'Edit Tee Sheet',
                      cssClass: 'confirmDeletion',
                      handler: () => {
                        window.location.href = "/page/TeeSheetSettings";
                      }
                    }
                  ]}
                />

                <IonLoading
                  cssClass='appLoading'
                  isOpen={showLoading}
                  onDidDismiss={() => setShowLoading(false)}
                  message={'Please wait...'}
                  duration={9000}
                />

                <IonPopover
                  cssClass="my-custom-class"
                  event={showShirtColorPopover.event}
                  isOpen={showShirtColorPopover.show}
                  showBackdrop={false}
                  onDidDismiss={() => setShowShirtColorPopover({ show: false, index: null, color: null, event: undefined })}
                >
                  <SketchPicker
                    width="230"
                    color={teeGroupToEdit?.golfers[showShirtColorPopover.index]?.color}
                    presetColors={["#B80000", "#DB3E00", "#FF6900", "#FCB900", "#CDDC39", "#8BC34A", "#00D084", "#026D2A", "#73D8FF", "#0693e3", "#0047bf", "navy", "#662D86", "#9900EF", "#F78DA7", "#5D4037", "#000000", "#6291a7", "#697689", "#dddddd", "#ffffff"]}
                    onChangeComplete={color => { handleTeeGroupGolfersInputChange({ event: { target: { name: 'color', value: color.hex || '' } }, index: showShirtColorPopover.index }); }}

                  />
                </IonPopover>

              </IonContent>


            </IonPage>
            // END ADMIN ONLY
          }
        </div>
      );
    }
  }
};

export default TeeSheet;