import { IonButton, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonDatetime, IonGrid, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonRow, IonSelect, IonSelectOption, IonText, IonToggle, IonToolbar } from '@ionic/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { firestore } from '../config/firebase';
import { scrollToSelection, generateTimeslots } from './functions';

function SettingsReservationWidget() {

  const groupID = localStorage.getItem('group');
  const courseID = localStorage.getItem('currentCourse');
  const [widgetSettings, setWidgetSettings] = useState(null);
  const [widgetRules, setWidgetRules] = useState([]);
  const [groupCourse, setGroupCourse] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [paymentGateway, setPaymentGateway] = useState(null);
  const [showSavingSettingsLoader, setShowSavingSettingsLoader] = useState(false);
  const [showSavingRulesLoader, setShowSavingRulesLoader] = useState(false);
  const [ruleToEdit, setRuleToEdit] = useState(null);
  const [widgetURL, setWidgetURL] = useState('');

  const daysInWeekRadioOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


  //Create a string to reference for all db calls
  useEffect(() => {
    setGroupCourse(firestore.collection('groups').doc(groupID).collection('courses').doc(courseID));
    //eslint-disable-next-line
    setWidgetURL("https://trwidget.web.app/group/"+groupID+"/course/"+courseID);
  }, [groupID, courseID]);

  const [teeSheets, setTeeSheets] = useState([]);

  // GET WIDGET SETTINGS
  useEffect(() => {
    // Get Widget Settings

    if (groupCourse) {
      try {
        groupCourse.get().then(snapshot => {
          console.log("Start Widget Settings: ", snapshot.data().widgetSettings);
          setWidgetSettings(snapshot.data().widgetSettings);
          setPaymentGateway(snapshot.data().widgetSettings?.paymentGateway);
        });
      } catch (err) {
        console.error("Error: ", err);
      }

      try {
        groupCourse.collection('widgetRules').onSnapshot(({ docs }) => {
          const mappedData = docs.map(d => ({ id: d.id, ...d.data() }));
          setWidgetRules(mappedData);
        });

      } catch (err) {
        console.log('error=====>>>>', err)
      }

    }

  }, [groupCourse]);

  useEffect(() => {
    setWidgetSettings(prevState => ({ ...prevState, paymentGateway: paymentGateway }));
  }, [paymentGateway]);

  // Function to Edit a TeeSheet's Blocked Tee Times
  const handleAddBlockedTeeTime = async (teeSheetId) => {
    const teeSheetIndex = teeSheets.findIndex(t => t.id === teeSheetId);
    const clonedTeeSheets = [...teeSheets];
    const teeSheet = clonedTeeSheets[teeSheetIndex];
    teeSheet.blockedTimes = (teeSheet.blockedTimes || []).concat({ title: '', days: '', time: '' });
    clonedTeeSheets.splice(teeSheetIndex, 1, teeSheet);
    setTeeSheets(clonedTeeSheets);
  }

  // GET TEE SHEETS
  useEffect(() => {
    if (groupCourse != null) {
      try {

        groupCourse.collection('teesheets').onSnapshot(({ docs }) => {
          const mappedData = docs.map(d => ({ id: d.id, ...d.data() }));
          setTeeSheets(mappedData);
        });

      } catch (err) {
        console.log('error=====>>>>', err)
      }
    }
    //eslint-disable-next-line
  }, [groupCourse]);

  const updateWidgetSettings = async () => {
    try {
      await groupCourse.update({
        widgetSettings: widgetSettings
      });
      Array.from(document.querySelectorAll('.changed')).forEach((el) => el.classList.remove('changed'));
      setHasChanges(false);
      setTimeout(() => {
        setShowSavingSettingsLoader(false);
      }, 1500);
    } catch (err) {
      console.log('error updating widget settings', err);
    }
  }

  const addNewWidgetRule = () => {
    setRuleToEdit({
      name: 'New Rule Name',
      startTime: '5:00 am',
      endTime: '10:00 pm',
      active: false,
      when: { days: [] },
      allow18Holes: widgetSettings.allow18Holes,
      allow9Holes: widgetSettings.allow9Holes,
      requirePayment: "default"
    });
  }

  // Function to Edit a TeeSheet
  const editRule = async e => {
    setShowSavingRulesLoader(true);
    e.preventDefault();
    try {
      await groupCourse.collection('widgetRules').doc(ruleToEdit.id).update(ruleToEdit);
      setRuleToEdit(null);
      setShowSavingRulesLoader(false);
    } catch (err) {
      console.log('error updating rule: ', err);
      setShowSavingRulesLoader(false);
    }
  }

  // Function to add a new rule
  const addNewRule = async e => {
    setShowSavingRulesLoader(true);
    e.preventDefault();
    try {
      const a = await groupCourse.collection('widgetRules').add(ruleToEdit);
      console.log('New Widget Rule ID: ', a.id);
      setRuleToEdit(null);
      setShowSavingRulesLoader(false);
    } catch (err) {
      console.log('error adding rule ==>>', err);
    }
  }

  const handleUpdateWidgetSettings = () => {
    setShowSavingSettingsLoader(true);
    console.log("update payment gateway: ", paymentGateway);
    setWidgetSettings(prevState => ({ ...prevState, paymentGateway: paymentGateway }));
    updateWidgetSettings();
  }

  const markAsChanged = (event, compareValue) => {
    let type = event.target.getAttribute('data-type');
    let value = event.target.value;
    if (type === "checkbox") {
      value = event.target.detail;
    }
    if (type === "array") {
      if (Array.isArray(value) && Array.isArray(compareValue)) {
        if (value?.sort().toString() !== compareValue?.sort().toString()) {
          event.target.classList.add('changed');
          setHasChanges(true);
        }
      }
    } else if (type === "select") {
      if (value && value > '' && compareValue && compareValue > '' && compareValue !== undefined && value !== compareValue) {
        event.target.classList.add('changed');
        setHasChanges(true);
      }
    } else if (value !== compareValue) {
      event.target.classList.add('changed');
      setHasChanges(true);
    }
  }

  const setWhenForRule = (days) => {
    console.log(ruleToEdit);
    console.log(days);
    let newObject = { days: days }
    setRuleToEdit(prevState => ({ ...prevState, when: newObject }));
    console.log(ruleToEdit);
  }

  // function copyToClipboard(string) {

  //   let copyText = document.querySelector("#widgetURLInput");
  //   copyText.select();
  
  //    /* Copy the text inside the text field */
  //   navigator.clipboard.writeText(copyText);
  
  //   /* Alert the copied text */
  //   alert("Copied the following to the clipboard: " + copyText);
  // }

  console.log("Has Changes: ", hasChanges);

  const timePickerSlots = generateTimeslots(10, '5:00 am', '10:00pm');

  return (
    <div className="settingsPageContainer">
      <IonGrid>
      <IonRow>
          <IonCol className="ion-text-center">
            <h2 className="subHeading">Reservation Widget URL:</h2>
            <p>The URL below is your unique Reservation Widget URL. Use it wherever you want to link to your reservation widget.</p>
            <code className="displayCode">{widgetURL}</code>
            {/* <IonButton onClick={() => {copyToClipboard(widgetURL)}}>Copy</IonButton> */}
            </IonCol>
            </IonRow>
        <IonRow>
          <IonCol>
            <p className="settingsTitleText">Use the options below to modify your reservation widget settings.</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h2 className="subHeading">Default Settings</h2>

            <div className="settingsBox">

              <IonGrid>
                <IonRow className="resRules">
                  <IonCol className="reservationWidgetSettings">
                    <div className="settingsTitle">Reservation Availability</div>

                    <IonItem>
                      <IonLabel position="stacked" color="medium">From which tee sheets can golfers start an <strong>18 hole round?</strong></IonLabel><br></br>
                      <IonSelect value={widgetSettings?.allow18Holes} data-type="array" multiple placeholder="Select Tee Sheets" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, allow18Holes: e.detail.value })); markAsChanged(e, widgetSettings?.allow18Holes); }}>
                        {teeSheets.map((teeSheet, j) =>
                          <IonSelectOption key={j} value={teeSheet.id}>{teeSheet.name}</IonSelectOption>
                        )}
                      </IonSelect>

                    </IonItem>



                    <IonItem>
                      <IonLabel position="stacked" color="medium">From which tee sheets can golfers start a <strong>9 hole round?</strong></IonLabel><br></br>
                      <IonSelect value={widgetSettings?.allow9Holes} data-type="array" multiple placeholder="Select Tee Sheets" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, allow9Holes: e.detail.value })); markAsChanged(e, widgetSettings?.allow9Holes); }}>
                        {teeSheets.map((teeSheet, j) =>
                          <IonSelectOption key={j} value={teeSheet.id}>{teeSheet.name}</IonSelectOption>
                        )}
                      </IonSelect>

                    </IonItem>

                    {/* <IonItem>
                      <IonLabel>Show Tee Times Based On: <div className="labelSubtitle">COMING SOON!</div></IonLabel>

                      <IonSelect value={widgetSettings?.showTimesBasedOn} data-type="select" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, showTimesBasedOn: e.detail.value })); markAsChanged(e, widgetSettings?.showTimesBasedOn); }} placeholder="Select Show Tee Times">
                        <IonSelectOption key={1} value="groups">Groups</IonSelectOption>
                        <IonSelectOption key={2} value="golfers">Golfers</IonSelectOption>
                      </IonSelect>

                    </IonItem>
                    <div className="labelSubtitle">If set to groups, then timeslots without any groups will be available. If golfers, then timeslots with room for the required number of golfers will be available.</div> */}
                  </IonCol>

                  <IonCol className="reservationWidgetSettings">
                    <div className="settingsTitle">Time</div>

                    <IonItem>
                      <IonLabel>Days In Advance<div className="labelSubtitle">How many days in advance can golfers make a reservation?</div></IonLabel>
                      <IonInput className="rightAlignedTextInput" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, daysInAdvance: parseInt(e.detail.value) })); }} onClick={e => { markAsChanged(e, widgetSettings?.daysInAdvance); }} name="daysInAdvance" value={widgetSettings?.daysInAdvance} placeholder='0' type="number"></IonInput>
                    </IonItem>

                    <IonItem>
                      <IonLabel>Minutes In Advance<div className="labelSubtitle">For current day reservations, how many minutes in advance can golfers make a reservation?</div></IonLabel>
                      <IonInput className="rightAlignedTextInput" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, currentDayMinutesInAdvance: parseInt(e.detail.value) })); }} onClick={e => { markAsChanged(e, widgetSettings?.currentDayMinutesInAdvance); }} value={widgetSettings?.currentDayMinutesInAdvance} placeholder='0' type="number"></IonInput>
                    </IonItem>

                    <IonItem>
                      <IonLabel>Cancellation Period<div className="labelSubtitle">Golfers may cancel their reservation up to how many hours before their tee time?</div></IonLabel>
                      <IonInput className="rightAlignedTextInput" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, cancellationHoursBefore: parseInt(e.detail.value) })); }} onClick={e => { markAsChanged(e, widgetSettings?.cancellationHoursBefore); }} value={widgetSettings?.cancellationHoursBefore} placeholder='0' type="number"></IonInput>
                    </IonItem>

                  </IonCol>

                  <IonCol className="reservationWidgetSettings">
                    <div className="settingsTitle">Payment Options</div>

                    <IonItem key="activeInput">
                      <IonLabel>Always Require Advance Payment?</IonLabel>
                      <IonToggle data-type="checkbox" checked={widgetSettings?.requirePayment} onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, requirePayment: e.detail.checked })); }} onClick={e => { markAsChanged(e, widgetSettings?.requirePayment); }} />
                    </IonItem>
                    <IonItem>
                      <IonLabel><div className="labelSubtitle">Whenever payment is required up front, should it be for the golfer making the reservation only or for the whole group?</div></IonLabel>
                      <IonSelect value={widgetSettings?.requirePaymentFor} data-type="select" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, requirePaymentFor: e.detail.value })); markAsChanged(e, widgetSettings?.requirePaymentFor); }} placeholder="Please select">
                        <IonSelectOption key={1} value="golfer">Golfer Only</IonSelectOption>
                        <IonSelectOption key={2} value="group">Whole Group</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    {/* <IonItem>
                      <IonLabel>Require Payment After Set Number of Reservations<div className="labelSubtitle">Require payment in advance for each additional reservation once a golfer has a set number of future reservations (set below).</div></IonLabel>
                      <IonToggle checked={widgetSettings?.requirePaymentAfterXReservations} onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, requirePaymentAfterXReservations: e.detail.checked })); }} onClick={e => { markAsChanged(e, widgetSettings?.requirePaymentAfterXReservations); }} />
                    </IonItem>
                    <IonItem className={widgetSettings?.requirePaymentAfterXReservations !== true ? "disabled" : ""}>
                      <IonLabel>Number of Reservations Before Payment is Required<div className="labelSubtitle">Specify the number of future reservations each golfer can have before requiring advance payment.</div></IonLabel>
                      <IonInput disabled={!widgetSettings?.requirePaymentAfterXReservations} className="rightAlignedTextInput" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, reservationsRequiredForAdvancePayment: parseInt(e.detail.value) })); }} onClick={e => { markAsChanged(e, widgetSettings?.reservationsRequiredForAdvancePayment); }} value={widgetSettings?.reservationsRequiredForAdvancePayment} placeholder='0' type="number"></IonInput>
                    </IonItem> */}

                  </IonCol>

                  <IonCol className="reservationWidgetSettings">
                    <div className="settingsTitle">Payment Gateway</div>

                    <IonItem>
                      <IonLabel>Payment Processor:</IonLabel>
                      <IonSelect value={paymentGateway?.name} data-type="select" onIonChange={e => { setPaymentGateway(prevState => ({ ...prevState, name: e.detail.value })); markAsChanged(e, paymentGateway?.name); }} placeholder="Select Payment Processor">
                        <IonSelectOption key={1} value="authorizenet">Authorize.net</IonSelectOption>
                      </IonSelect>
                    </IonItem>

                    <IonItem>
                      <IonLabel>Environment:</IonLabel>
                      <IonSelect value={paymentGateway?.environment} data-type="select" onIonChange={e => { setPaymentGateway(prevState => ({ ...prevState, environment: e.detail.value })); markAsChanged(e, paymentGateway?.environment); }} placeholder="Select an environment">
                        <IonSelectOption key={1} value="production">Production</IonSelectOption>
                        <IonSelectOption key={2} value="sandbox">Sandbox</IonSelectOption>
                      </IonSelect>
                    </IonItem>

                    <IonItem>
                      <IonLabel>API Login ID:</IonLabel>
                      <IonInput className="ion-text-right" onIonChange={e => { setPaymentGateway(prevState => ({ ...prevState, apiLoginId: e.detail.value })); markAsChanged(e, paymentGateway?.apiLoginId); }} name="title" value={paymentGateway?.apiLoginId} placeholder="API Login ID" />
                    </IonItem>

                    <IonItem>
                      <IonLabel>Transaction Key:</IonLabel>
                      <IonInput className="ion-text-right" onIonChange={e => { setPaymentGateway(prevState => ({ ...prevState, transactionKey: e.detail.value })); markAsChanged(e, paymentGateway?.transactionKey); }} name="title" value={paymentGateway?.transactionKey} placeholder="Transaction Key" />
                    </IonItem>

                    <IonItem>
                      <IonLabel>Client Key:</IonLabel>
                      <IonInput className="ion-text-right" onIonChange={e => { setPaymentGateway(prevState => ({ ...prevState, clientKey: e.detail.value })); markAsChanged(e, paymentGateway?.clientKey); }} name="title" value={paymentGateway?.clientKey} placeholder="Client Key" />
                    </IonItem>

                  </IonCol>

                  <IonCol className="reservationWidgetSettings">
                    <div className="settingsTitle">Rates</div>

                    <IonItem>
                      <IonLabel>9 Hole Rate: $</IonLabel>
                      <IonInput type="number" className="ion-text-right" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, nineHoleRate: e.detail.value })); markAsChanged(e, paymentGateway?.nineHoleRate); }} name="nineHoleRate" value={widgetSettings?.nineHoleRate} placeholder="0.00" />
                    </IonItem>

                    <IonItem>
                      <IonLabel>18 Hole Rate: $</IonLabel>
                      <IonInput type="number" className="ion-text-right" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, eighteenHoleRate: e.detail.value })); markAsChanged(e, paymentGateway?.eighteenHoleRate); }} name="eighteenHoleRate" value={widgetSettings?.eighteenHoleRate} placeholder="0.00" />
                    </IonItem>

                    <IonItem>
                      <IonLabel>9 Hole with Cart Rate: $</IonLabel>
                      <IonInput type="number" className="ion-text-right" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, nineHoleWithCartRate: e.detail.value })); markAsChanged(e, paymentGateway?.nineHoleWithCartRate); }} name="nineHoleWithCartRate" value={widgetSettings?.nineHoleWithCartRate} placeholder="0.00" />
                    </IonItem>

                    <IonItem>
                      <IonLabel>18 Hole with Cart Rate: $</IonLabel>
                      <IonInput type="number" className="ion-text-right" onIonChange={e => { setWidgetSettings(prevState => ({ ...prevState, eighteenHoleWithCartRate: e.detail.value })); markAsChanged(e, paymentGateway?.eighteenHoleWithCartRate); }} name="eighteenHoleWithCartRate" value={widgetSettings?.eighteenHoleWithCartRate} placeholder="0.00" />
                    </IonItem>

                  </IonCol>



                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol className="ion-text-center">
                    <IonButton className="ion-margin-top" disabled={hasChanges === true ? false : true} onClick={handleUpdateWidgetSettings} color="primary">Save Default Settings</IonButton>
                  </IonCol>
                </IonRow>

              </IonGrid>


            </div>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <h2 className="subHeading">Additional Rules</h2>

            {(widgetRules === undefined || (Array.isArray(widgetRules) && widgetRules.length === 0)) && //If nothing exists...
              <IonRow>
                <IonCol className="ion-text-center">
                  <p className="ion-no-margin">No widget rules have been set yet. Add some below.</p>
                </IonCol>
              </IonRow>
            }
            {widgetRules?.map((rule, i) =>
              <div className="settingsBox">
                <IonCardHeader>
                  <IonToolbar>
                    <IonCardTitle>{rule?.name > '' ? rule?.name : 'Untitled'}</IonCardTitle>
                    <IonCardSubtitle>
                      {rule?.active ? <IonText color="success">Active</IonText> : <IonText color="danger">Inactive</IonText>}
                      {rule?.startTime > '' && rule?.endTime > '' && " | " + rule.startTime + " to " + rule.endTime}
                      {rule?.when?.days?.length > 0 && " | " + rule.when.days.join(", ")}
                      {rule?.requirePayment === "always" && " | Always Require Payment"}
                      {rule?.requirePayment === "never" && " | Never Require Payment"}
                      {/* Saturday, Sunday | 6:00 am to 2:00 pm | 9 Hole Bookings Allowed | Require Payment | Allow booking on Front Nine */}
                    </IonCardSubtitle>
                    <IonButton fill="outline" color="medium" slot="end" onClick={() => { setRuleToEdit(rule); }}>Edit</IonButton>
                  </IonToolbar>
                </IonCardHeader>
              </div>
            )}

          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center">
          <IonCol className="ion-text-center">
            <IonButton color="primary" className="ion-margin-top" onClick={addNewWidgetRule}>Add New Widget Rule</IonButton>

          </IonCol>
        </IonRow>



      </IonGrid>

      <IonModal isOpen={Boolean(ruleToEdit)} cssClass='widgetRuleEditDialog teeSheetModal' backdropDismiss={false}>
        <div className="modalHeader">{Boolean(ruleToEdit?.id) ? 'Edit Widget Rule' : 'Add New Widget Rule'}</div>
        <form onSubmit={Boolean(ruleToEdit?.id) ? editRule : addNewRule}>
          <IonList>

            <IonItem key="nameInput">
              <IonLabel>Name</IonLabel>
              <IonInput className="rightAlignedTextInput" onIonChange={e => setRuleToEdit(prevState => ({ ...prevState, name: e.detail.value }))} value={ruleToEdit?.name} placeholder='eg. Course Name or "Front Nine", "Back Nine", etc'></IonInput>
            </IonItem>

            <IonItem key="activeInput" className="toggleFormItem">
              <IonLabel className="toggleFormLabel">Active?</IonLabel>
              <IonToggle checked={ruleToEdit?.active} onIonChange={e => setRuleToEdit(prevState => ({ ...prevState, active: e.detail.checked }))} />
            </IonItem>

            <IonItem key="startTimeInput">
              <IonLabel>Start Time</IonLabel>
              <IonSelect onClick={scrollToSelection} onIonChange={e => setRuleToEdit(prevState => ({ ...prevState, startTime: e.detail.value }))} value={ruleToEdit?.startTime} interface="popover">
                {timePickerSlots.map((timeslot, j) =>
                  <IonSelectOption key={j} value={timeslot}>{timeslot}</IonSelectOption>
                )}
              </IonSelect>
            </IonItem>

            <IonItem key="endTimeInput">
              <IonLabel>End Time</IonLabel>
              <IonSelect onClick={scrollToSelection} onIonChange={e => setRuleToEdit(prevState => ({ ...prevState, endTime: e.detail.value }))} value={ruleToEdit?.endTime} interface="popover">
                {timePickerSlots.map((timeslot, j) =>
                  <IonSelectOption key={j} value={timeslot}>{timeslot}</IonSelectOption>
                )}
              </IonSelect>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked" color="medium">Which days to apply this rule?</IonLabel><br></br>
              <IonSelect value={ruleToEdit?.when.days} onIonChange={e => setWhenForRule(e.detail.value)} name="days" multiple placeholder="No days selected" className="dayPicker">
                {daysInWeekRadioOptions.map((day, j) =>
                  <IonSelectOption key={j} value={day}>{day}</IonSelectOption>
                )}
              </IonSelect>
            </IonItem>

            <IonItem key="paymentInput">
              <IonLabel position="stacked" color="medium" className="ion-text-wrap longLabelMods">When this rule is in effect, do you want to require payment?</IonLabel>
              <IonSelect value={ruleToEdit?.requirePayment} placeholder="Select when to require payment" onIonChange={e => { setRuleToEdit(prevState => ({ ...prevState, requirePayment: e.detail.value })); }}>
                <IonSelectOption key={'rules'} value="default">Follow Widget Settings</IonSelectOption>
                <IonSelectOption key={'always'} value="always">Always Require Payment</IonSelectOption>
                <IonSelectOption key={'never'} value="never">Never Require Payment</IonSelectOption>
              </IonSelect>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked" className="ion-text-wrap longLabelMods" color="medium">When this rule is in effect, from which tee-sheet can golfers start an <strong>18 hole round?</strong></IonLabel>
              <IonSelect value={ruleToEdit?.allow18Holes} data-type="array" multiple placeholder="No tee-sheets selected" onIonChange={e => { setRuleToEdit(prevState => ({ ...prevState, allow18Holes: e.detail.value })); }}>
                {teeSheets.map((teeSheet, j) =>
                  <IonSelectOption key={j} value={teeSheet.id}>{teeSheet.name}</IonSelectOption>
                )}
              </IonSelect>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked" className="ion-text-wrap longLabelMods" color="medium">When this rule is in effect, from which tee sheets can golfers start a <strong>9 hole round?</strong></IonLabel>
              <IonSelect value={ruleToEdit?.allow9Holes} data-type="array" multiple placeholder="No tee-sheets selected" onIonChange={e => { setRuleToEdit(prevState => ({ ...prevState, allow9Holes: e.detail.value })); }}>
                {teeSheets.map((teeSheet, j) =>
                  <IonSelectOption key={j} value={teeSheet.id}>{teeSheet.name}</IonSelectOption>
                )}
              </IonSelect>
            </IonItem>


          </IonList>


          <IonRow>
            <IonCol><IonButton expand="block" color="danger" onClick={() => setRuleToEdit(null)}>Cancel</IonButton></IonCol>
            <IonCol><IonButton type="submit" expand="block">Save Widget Rule</IonButton></IonCol>
          </IonRow>

          {/* <IonButton className="ion-text-center" onClick={() => setConfirmDelete({ isOpen: true, type: 'teesheet' })} fill="clear" expand="full" color="danger">Delete this Tee Sheet</IonButton> */}
        </form>
      </IonModal>

      <IonLoading
        cssClass='my-custom-class'
        isOpen={showSavingSettingsLoader}
        onDidDismiss={() => setShowSavingSettingsLoader(false)}
        message={'Saving Default Settings...'}
      />

      <IonLoading
        cssClass='my-custom-class'
        isOpen={showSavingRulesLoader}
        onDidDismiss={() => setShowSavingRulesLoader(false)}
        message={'Saving Widget Rule...'}
      />
    </div>

  );
};

export default SettingsReservationWidget;
