import React, {useState} from 'react';
import { IonModal, IonButton, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/react';
import RefundPopup from "../components/RefundPopup"

const RefundModal = ({ isOpen, onClose, data }) => {
    const [isRefundPopupOpen, setRefundPopupOpen] = useState(false);
    const [currentPayment, setCurrentPayment] = useState("")
    const [paymetnDocId, setPaymentDocId] = useState("")
  return (
    <IonModal className="payment-modal" isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Payments</IonTitle>
          <IonButton fill='clear' color="dark" slot="end" onClick={onClose}>Close</IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent style={{height : 'calc(100% - 65px)'}}>
        <div style={{margin : '10px'}} className='payments-table'>
            <table className="messageList " >
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Completed Payment Ids</th>
                        <th>Amount</th>
                        <th>Currency</th>
                        <th>Payment Type</th>
                        <th>Payment Status</th>
                        <th>Refund Status</th>
                        <th>Refund</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 && data.map((data, i) =>
                        <tr key={i}>
                            <td>{data?.data?.payment?.id}</td>
                            <td>
                                {data?.data?.payment?.hasOwnProperty("payment_ids") ? data?.data?.payment?.payment_ids?.map((id, index) => (
                                    <div key={index}>{id}</div>
                                )): "--"}
                            </td>
                            <td style={{ fontWeight: 900 }}>${data?.data?.payment?.amount_money?.amount / 100}</td>
                            <td>{data?.data?.payment?.amount_money?.currency}</td>
                            <td>{data?.data?.payment?.payment_type == "CARD_PRESENT" ? "CARD" : "CASH"}</td>
                            <td><span className={`payment-status ${
                                data?.data?.payment?.status === "PENDING" ? 'pending' :
                                data?.data?.payment?.status === "CANCELED" ? 'canceled' :
                                data?.data?.payment?.status === "COMPLETED" ? 'completed' :
                                'progress'
                                }`}>{data?.data?.payment?.status.replace(/_/g, ' ')}</span></td>
                            <td>{data?.data?.refund ? <span className={`payment-status ${
                                data?.data?.refund?.status === "PENDING" ? 'pending' :
                                (data?.data?.refund?.status === "FAILED" || data?.data?.refund?.status === "REJECTED") ? 'canceled' :
                                data?.data?.refund?.status === "COMPLETED" ? 'completed' :
                                null
                                }`}>{data?.data?.refund?.status.replace(/_/g, ' ')}</span>: "--"}</td>
                            <td>{data?.data?.payment?.status === "COMPLETED" && (!data?.data?.hasOwnProperty("refund") || (data?.data?.refund?.status === "FAILED" || data?.data?.refund?.status === "REJECTED")) ? <button onClick={()=> {setRefundPopupOpen(true); setCurrentPayment(data.data); setPaymentDocId(data?.docId)}} className='payment-status' >Process Refund</button> : "--"}</td>
                        </tr>
                    
                    )}
                </tbody>
            </table>
        </div>
      </IonContent>
      {
        isRefundPopupOpen &&
        <RefundPopup
            isOpen={isRefundPopupOpen}
            onClose={() => setRefundPopupOpen(false)}
            payment={currentPayment}
            docId={paymetnDocId}
        />
    }
    </IonModal>
  );
};

export default RefundModal;
