import { IonAlert, IonBadge, IonButton, IonButtons, IonChip, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonRow, IonSpinner, IonText, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { cash, closeOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';

import './Cart.css';
import { firestore } from '../config/firebase';
import { focusOnFirstInput } from './functions';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase';
import axios from 'axios';
import * as CONSTANT from "../config/constants"
import PaymentConfirmationModal from './PaymentConfirmationModal';
import PaymentErrorModal from './PaymentErrorModal';

function Cart({ showCartModal, setShowCartModal }) {
    const group = localStorage.getItem('group') ? (localStorage.getItem('group')) : ("undefined")
    const course = localStorage.getItem('currentCourse') ? (localStorage.getItem('currentCourse')) : ("undefined")

    const [posID, setPosID] = useState(localStorage.getItem('currentPOS') ? (localStorage.getItem('currentPOS')) : ("undefined"));

    const [posscreens, setPosScreens] = useState([]);
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
    const [posData, setPosData] = useState(null);
    const [cartTotal, setCartTotal] = useState<any>(0);
    const [cartSubTotal, setCartSubTotal] = useState(0);
    const [cartTaxTotal, setCartTaxTotal] = useState(0);
    const [cartItems, setCartItems] = useState([]);
    // const [cartTeeGroups, setCartTeeGroups] = useState(null);
    const [payCard, setPayCard] = useState(false);
    const [payCash, setPayCash] = useState(false);
    const [showCardSuccess, setShowCardSuccess] = useState(false);
    const [showCashSuccess, setShowCashSuccess] = useState(false);
    const [cashGiven, setCashGiven] = useState<any>(0);
    const cart = localStorage.getItem('cart') ? (localStorage.getItem('cart')) : (null);
    const [cartTeeGroups, setCartTeeGroups] = useState(null);
    const [cartTeeGroupsItems, setCartTeeGroupsItems] = useState([]);
    const [cartTeeGroupToEdit, setCartTeeGroupToEdit] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const [submitButtonText, setSubmitButtonText] = useState("Request Card Payment")
    const [showAlert, setShowAlert] = useState(false)
    const [showAlertMessage, setShowAlertMessage] = useState('')
    const [paymentResponse, setPaymentResponse] = useState<any>(null)
    const [disablePayment, setDisablePayment] = useState(false)
    const [tipAmount, setTipAmount] = useState(0)
    const [onlinePayable, setOnlinePayable] = useState<any>(0)
    const [currencyType, setCurrencyType] = useState("")
    const [remainingAmount, setRemaingAmount] = useState<any>(localStorage.getItem('remainingAmountLocal') ? localStorage.getItem('remainingAmountLocal') : (0))
    const [isRemaining, setIsRemaining] = useState(localStorage.getItem('isRemainingFound') ? JSON.parse(localStorage.getItem('isRemainingFound')) : (false))
    const [cashGivenByBuyer, setCashGivenByBuyer] = useState<any>("")
    const [changeGiven, setChangeGiven] = useState(0)
    const [paymentInitiated, setPaymentInitiated] = useState(false)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    //subscription to local storage
    useEffect(() => {
        function checkCartTeeGroups() {
            console.log("Ran checkCartTeeGroups");
            const item = localStorage.getItem('cartTeeGroups')

            if (item) {
                setCartTeeGroups(item)
                console.log("set checkCartTeeGroups");
            }
        }

        window.addEventListener('storage', checkCartTeeGroups)
        return () => {
            window.removeEventListener('storage', checkCartTeeGroups)
        }
    }, [])

    useEffect(() => {
        console.log("started listening to storage");
        console.log('Cart Tee Groups Items: ', cartTeeGroupsItems);

        window.addEventListener("storage", () => {
            // When storage changes refetch
            console.log("detected storage change");
        });
        getMerchantList()
    }, []);


    useEffect(() => {
        console.log("To Edit: ", cartTeeGroupsItems);
        console.log('Cart Tee Groups Items: ', cartTeeGroupsItems);

    }, [cartTeeGroupToEdit, cartTeeGroupsItems])

    useEffect(() => {
        setCartTeeGroups(localStorage.getItem('cartTeeGroups') ? localStorage.getItem('cartTeeGroups') : ('null'));
        refreshCartTeeGroups();
        console.log('Cart Tee Groups Items: ', cartTeeGroupsItems);

    }, []);

    useEffect(() => {
        refreshCartTeeGroups();
    }, [cartTeeGroups]);

    const refreshCartTeeGroups = async () => {
        if (cartTeeGroups) {
            console.log("CART TEE GROUPS STRING: ", cartTeeGroups);
            let cartTeeGroupsArray = cartTeeGroups.split(",");
            console.log("CART TEE GROUPS ARRAY: ", cartTeeGroupsArray);
            let cartTeeGroupsItemsArray = [];

            try {
                cartTeeGroupsArray.forEach((cartTeeGroup) => {
                    let cartTeeGroupDoc = firestore.collection('groups').doc(group).collection('courses').doc(course).collection('teetimegroups').doc(cartTeeGroup);

                    // Get Widget Settings
                    try {
                        cartTeeGroupDoc.get().then(snapshot => {
                            if (snapshot.data()) {
                                cartTeeGroupsItemsArray.push(
                                    {
                                        id: cartTeeGroup,
                                        golfers: snapshot.data().golfers,
                                        startingTime: snapshot.data().startingTime,
                                        numHoles: snapshot.data().numHoles,
                                        date: snapshot.data().date,
                                    }
                                );
                            }
                        });
                    } catch (err) {
                        console.error("Error: ", err);
                    }
                });
            } finally {
                setCartTeeGroupsItems(cartTeeGroupsItemsArray);
            }
        }

        console.log('Cart Tee Groups Items: ', cartTeeGroupsItems);

    }
    // Get POS Screens
    useEffect(() => {
        try {
            firestore.collection('groups').doc(group).collection('posscreens').onSnapshot(({ docs }) => {
                const mappedData = docs.map(d => ({ id: d.id, name: d.data().name }));
                setPosScreens(mappedData);

            });

        } catch (err) {
            console.error('Error:', err)
        }
        //eslint-disable-next-line
    }, [group]);

    // Get POS Data
    useEffect(() => {

        if (posID > '') {
            let posToEditDoc = firestore.collection('groups').doc(group).collection('posscreens').doc(posID);

            // Get Widget Settings
            try {
                posToEditDoc.get().then(snapshot => {
                    setPosData(snapshot.data());
                });
            } catch (err) {
                console.error("Error: ", err);
            }
        }

        //eslint-disable-next-line
    }, [posscreens, posID]);

    useEffect(() => {
        updateCartItems();
        console.log('Cart: ', cart);
        //eslint-disable-next-line
    }, [cart]);

    useEffect(() => {
        console.log('Cart Tee Groups: ', cartTeeGroups);
        console.log('Cart Tee Groups Items: ', cartTeeGroupsItems);

    }, [cartTeeGroups]);

    const addCartItem = (data) => {
        if (!isRemaining) {
            let newItems = [];
            if (cartItems && Array.isArray(cartItems) && cartItems.length > 0) {
    
                cartItems.map((cartItem) => {
                    newItems.push(cartItem);
                });
            }
            newItems.push(data);
    
            let stringifiedCart = JSON.stringify(newItems);
            localStorage.setItem("cart", stringifiedCart);
            updateCartItems();
        } else {
            setToastMessage("Oops, Must complete this payment to continue further payments");
            setShowToast(true);
        }
    }

    const updateCartItems = () => {
        let cartArray = JSON.parse(cart);
        let newCartTotal = Number(0);

        if (cartArray && Array.isArray(cartArray) && cartArray.length > 0) {
            setCartItems(cartArray);
            cartArray.map((cartItem) => {
                newCartTotal += Number(cartItem.price);
            });
            setCartSubTotal(newCartTotal);
            setCartTaxTotal(newCartTotal * 0.06875);
            setCartTotal(newCartTotal * 1.06875);
        } else {
            setCartItems([]);
            setCartSubTotal(0);
            setCartTaxTotal(0);
            setCartTotal(0);
        }
    }

    const handleClearCart = () => {
        setCartItems([]);
        localStorage.setItem("cart", null);
    }

    const removeItemFromCart = (i) => {
        let cartArray = JSON.parse(cart);
        cartArray = cartArray.filter(function (object, index) {
            return index !== i;
        });
        let stringifiedCart = JSON.stringify(cartArray);
        localStorage.setItem("cart", stringifiedCart);
        updateCartItems();
    }

    const addCash = (amount) => {
        let tempCash = Number(cashGiven);
        setCashGiven(tempCash += amount);
    }

    const removeGroupFromCart = (groupID, index) => {
        removeCartTeeGroupItem(groupID);
    }

    const removeCartTeeGroupItem = (data) => {
        console.log("First Trying to remove: ", data);

        let oldCartTeeGroups = cartTeeGroups;

        recurse(oldCartTeeGroups);

        function recurse(oldCartTeeGroups) {
            if (oldCartTeeGroups.includes(data)) {
                let newCartTeeGroups = oldCartTeeGroups.replace(data, '');
                recurse(newCartTeeGroups);
            } else if (oldCartTeeGroups.slice(-1) === ',') { //if last character is comma
                let newCartTeeGroups = oldCartTeeGroups.replace(/,\s*$/, "");;
                recurse(newCartTeeGroups);
            } else if (oldCartTeeGroups.substring(0, 1) === ',') { //if first character is comma
                let newCartTeeGroups = oldCartTeeGroups.replace(/^,/, '');
                recurse(newCartTeeGroups);
            } else {
                finishRecursion(oldCartTeeGroups);
            }
        }

        function finishRecursion(teeGroups) {
            console.log("New Cart Tee Groups after remove: ", teeGroups)

            localStorage.setItem("cartTeeGroups", teeGroups);
            refreshCartTeeGroups();
        }

    }

    const createCardPayment = async (e) => {
        getAccessTokenAndDeviceId();
    }

    const getAccessToken = async () => {
        let accessToken;
    
        if (paymentAccountId) {
            const paymentAccountRef = firebase.firestore().collection('groups').doc(groupId).collection('paymentAccounts').doc(paymentAccountId);
    
            try {
                const paymentAccountDoc = await paymentAccountRef.get();
    
                if (paymentAccountDoc.exists) {
                    accessToken = paymentAccountDoc.data()?.productionMode ? paymentAccountDoc.data().prodAccessToken : paymentAccountDoc.data().sandboxAccessToken;
                    return accessToken;
                } else {
                    console.log('Payment account document not found.');
                }
            } catch (error) {
                console.error('Error retrieving payment account document:', error);
            }
        } else {
            setToastMessage("Oops, Must select current square account from Point of Sale page.");
            setShowToast(true);
        }
    };

    const handlePaymentAmount =(amountInCents)=> {
        amountInCents = Number(amountInCents);
        const remainder = amountInCents % 5;
      
        if (remainder === 0) {
            return amountInCents;
        } else if (remainder <= 2) {
            return amountInCents - remainder;
        } else {
            return amountInCents + (5 - remainder);
        }
    }
      
    const getMerchantList = async()=> {
        const accessToken = await getAccessToken()
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        }

        const response = await axios.get(CONSTANT.CLOUD_FUNCTION_BASE_URL + "checkout-squareCheckout/square-merchants", { headers });
        setCurrencyType(response?.data?.data?.merchant?.[0]?.currency)
    }


    const isRemainingFound = JSON.parse(localStorage.getItem("isRemainingFound"))
    const remainingAmountLocal = localStorage.getItem("remainingAmountLocal")
    const total = isRemainingFound ? remainingAmountLocal : cartTotal

    const createCashPayment = async (e) => {
        setLoading(true)
        localStorage.setItem("paymentMode", "cash")
        const accessToken = await getAccessToken()
        const amount: any = Number(total).toFixed(2)
        const buyerAmount: any = Number(cashGiven).toFixed(2)
        const data = {
            "idempotency_key": generateIdempotencyKey(),
            "source_id": "CASH",
            "amount_money": {
              "amount": currencyType == "CAD" ? handlePaymentAmount(amount * 100) : amount * 100,
              "currency": currencyType
            },
            "tip_money": {
              "amount": 0,
              "currency": currencyType
            },
            "cash_details": {
                "buyer_supplied_money": {
                    "amount": currencyType == "CAD" ? handlePaymentAmount(buyerAmount * 100) : buyerAmount * 100,
                    "currency": currencyType
                }
            }
        }

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        }

        axios.post(CONSTANT.CLOUD_FUNCTION_BASE_URL + `checkout-squareCheckout/square-cash-payment`, data, {
            headers: headers
        })
        .then(response => {
            if (response?.status == 200) {
                managePaymentsData(response, "cash")
                manageInvoicesOnStatusBased(response?.data?.data?.payment?.id)
                openCashDrawer()
            }
        })
        .catch(error => {
            setLoading(false)
            // setPayCard(false);
            console.error('Error:', error)
            setToastMessage("Oops, seems like there is an issue with your square access token")
            setShowToast(true);
        });
    }

    const openCashDrawer = async () => {
        const data = {
            header: {
                title: "Schneiter's Bluff Golf Course",
                address: "300 N 3500 W - West Point, UT",
                phone: "801-773-0731",
                invoice_number: "339404",
                date_time: "6/28/2024 6:27:15 AM",
                cashier: "Eric",
                station: "01"
            },
            items: [
                { name: "SMALL BUCKET", price: "$5.00" },
                { name: "Bag Chips", price: "$2.00" },
                { name: "18 Hole Green Fee", price: "$42.00" }
            ],
            summary: {
                subtotal: "$49.00",
                tax: "$3.50",
                grand_total: "$52.50",
                cash: "$52.50",
                amt_tendered: "$60.00",
                change: "$7.50",
                payment_id: "26EHG8KZvWjqO",
                amount_remaining: "$0.00"
            }
        };
    
        const copy = "customer copy";
    
        try {
            const response = await fetch('https://teerocket.wp-guppy.com/local-server/printReceipt', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ data, copy })
            });
    
            if (response.ok) {
                console.log('Receipt printed and cash drawer opened');
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const generateIdempotencyKey = () => {
        return uuidv4();
    }

    const groupId = localStorage.getItem('group');
    const paymentAccountId = localStorage.getItem('currentSquareApi');
    const currentTerminalDevice = localStorage.getItem('currentTerminalDevice');
    const mode = localStorage.getItem('paymentMode');

    const getAccessTokenAndDeviceId = () => {
        let accessToken;
        let deviceId;
    
        if (paymentAccountId && currentTerminalDevice) {
            const paymentAccountRef = firebase.firestore().collection('groups').doc(groupId).collection('paymentAccounts').doc(paymentAccountId);
            const deviceRef = firebase.firestore().collection('groups').doc(groupId).collection('devices').doc(currentTerminalDevice);
    
            Promise.all([
                paymentAccountRef.get(),
                deviceRef.get()
            ]).then(([paymentAccountDoc, deviceDoc]) => {
                if (paymentAccountDoc.exists) {
                    accessToken = paymentAccountDoc.data()?.productionMode ? paymentAccountDoc.data().prodAccessToken : paymentAccountDoc.data().sandboxAccessToken;
                } else {
                    console.log('Payment account document not found.');
                }
    
                if (deviceDoc.exists) {
                    deviceId = deviceDoc?.data()?.device_code.device_id;
                } else {
                    console.log('Device document not found.');
                }
                submitPayWithCard(accessToken, deviceId);
            }).catch(error => {
                console.error('Error retrieving documents:', error);
            });
        } else {
            setToastMessage("Oops, Must select current square account and current terminal device from Point of Sale page.");
            setShowToast(true);
        }
    }
    
    const submitPayWithCard = (accessToken, deviceId) => {
        setLoading(true)
        localStorage.setItem("paymentMode", "card")
        const amount: any = Number(onlinePayable).toFixed(2)

        const data = {
            "idempotency_key": generateIdempotencyKey(),
            "checkout": {
              "amount_money": {
                "amount":  Math.floor(amount * 100),
                "currency": currencyType
              },
              "device_options": {
                "device_id": deviceId
              },
            }
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        }

        axios.post(CONSTANT.CLOUD_FUNCTION_BASE_URL + `checkout-squareCheckout/square-checkout`, data, {
            headers: headers
        })
        .then(response => {
            if (response?.status == 200) {
                managePaymentsData(response, "card")
            }
        })
        .catch(error => {
            setLoading(false)
            // setPayCard(false);
            console.error('Error:', error)
            setToastMessage("Oops, seems like there is an issue with your square access token")
            setShowToast(true);
        });
    }

    const managePaymentsData = async (response: any, type: string) => {
        try {
            const id = type !== "cash" ? response?.data?.data?.checkout?.id : response?.data?.data?.payment?.id
            if (type !== "cash") {
                firebase.firestore()
                .collection('groups')
                .doc(groupId)
                .collection('payments')
                .onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((item) => {
                        const paymentData = item.doc.data().payment;

                        if (id === paymentData.id) {
                            handlePaymentStatus(paymentData.status, id);
                        }
                    });
                });
            }

            const groupRef = firebase.firestore().collection('groups').doc(groupId);
            const paymentsRef = groupRef.collection('payments').doc();
            const paymentData = {type: type, payment: type == "cash" ? response?.data?.data?.payment : response?.data?.data?.checkout}
            
            const batch = firebase.firestore().batch();
            batch.set(paymentsRef, paymentData);
            localStorage.setItem("paymentsRef", paymentsRef.id)
            await batch.commit();

            setLoading(false);
            setShowAlert(true);
            setPayCash(false)
            type !== "cash" && setShowAlertMessage("Payment is in pending. Please Tap/Swipe/insert your card on the Square Terminal to make it complete.");
            !isRemaining && type == "cash" && localStorage.setItem("cart", null);
            !isRemaining && type == "cash" && setCartItems([]);
            !isRemaining && type == "cash" && setShowCartModal(false)
            !isRemaining && type == "cash" && setIsModalOpen(true); setTimeout(() => setIsModalOpen(false), 5000);
            setDisablePayment(false)
        } catch (error) {
            console.error('Error adding record: ', error);
            setLoading(false);
            setToastMessage("Oops, there seems to be an issue while updating record in database");
            setShowToast(true);
        }
    };

    const manageInvoicesOnStatusBased = async(cashPaymentId)=> {
        try {

            const groupRef = firebase.firestore().collection('groups').doc(groupId);
            const invoicesRef = groupRef.collection('invoices').doc();
            const paymentsRef: any = localStorage.getItem("paymentsRef")
            const paymentMode = localStorage.getItem("paymentMode")

            const paidAmount = parseFloat(localStorage.getItem("paidAmount") || "0");
            const paidAmountData: number = isRemainingFound
            ? (paymentMode === "cash" ?  Number(cartTotal) : (Number(onlinePayable) + Number(paidAmount)))
            : (paymentMode === "cash" ? Number(cartTotal) : Number(cartTotal) - Number(remainingAmount));
            
            const remainingAmountData: any = isRemainingFound ? Number(cartTotal) - Number(paidAmountData) : Number(remainingAmount).toFixed(2)
            const isRemainingFoundData :any = Number(paidAmountData).toFixed(2) === Number(cartTotal).toFixed(2) ? JSON.stringify(false) : JSON.stringify(true)
            
            isRemaining && localStorage.setItem("remainingAmountLocal", Number(remainingAmountData).toFixed(2));
            isRemaining && localStorage.setItem("paidAmount", Number(paidAmountData).toFixed(2));
            isRemaining && localStorage.setItem("isRemainingFound", isRemainingFoundData);

            const data = {
                cartItems: cartItems,
                payment: [{docId: paymentsRef, paymentId: cashPaymentId,}],
                paid: Number(paidAmountData).toFixed(2),
                remaining: Number(remainingAmountData).toFixed(2),
                invoiceId: invoicesRef.id
            };

            const batch = firebase.firestore().batch();
            const ref: any = localStorage.getItem("invoiceRef")
            if (isRemainingFound && ref) {
                const docRef = firebase.firestore().collection('groups').doc(groupId).collection('invoices').doc(ref);
                const data =  {docId: paymentsRef, paymentId: cashPaymentId}
                batch.update(docRef, {
                    payment: firebase.firestore.FieldValue.arrayUnion(data),
                    paid: Number(paidAmountData).toFixed(2),
                    remaining:  Number(remainingAmountData).toFixed(2),
                    invoiceId: ref
                });
            } else {
                batch.set(invoicesRef, data);
                localStorage.setItem("invoiceRef", invoicesRef.id)
            }
            await batch.commit();
               
            JSON.parse(isRemainingFoundData) == false && setCartItems([]);
            JSON.parse(isRemainingFoundData) == false && localStorage.setItem("cart", null);
            if (JSON.parse(isRemainingFoundData) == false) {
                localStorage.removeItem("remainingAmountLocal")
                localStorage.removeItem("paidAmount");
                localStorage.removeItem("isRemainingFound")
                localStorage.setItem("cart", null)
                localStorage.removeItem("invoiceRef")
                setPayCash(false)
                setCartItems([])
                setIsRemaining(false)
                setShowCartModal(false)
            }
        } catch (error) {
        }
    }

    const handlePaymentStatus = (status, id) => {
        switch (status) {
            case "PENDING":
                setPaymentInitiated(true);
                setShowAlertMessage("Payment is pending. Please Tap/Swipe/insert your card on the Square Terminal to complete.");
                break;
            case "CANCELED":
            case "CANCEL_REQUESTED":
                setPaymentInitiated(false);
                setShowAlertMessage("Your payment has been cancelled");
                setPayCard(false);
                setShowAlert(false);
                setIsErrorModalOpen(true);
                setTimeout(() => setIsErrorModalOpen(false), 5000);
                break;
            case "IN_PROGRESS":
                setPaymentInitiated(true);
                setShowAlertMessage("Your payment is in progress");
                break;
            case "COMPLETED":
                setPaymentInitiated(false);
                manageInvoicesOnStatusBased(id);
                setPayCard(false);
                setShowAlert(false);
                setIsModalOpen(true);
                if (!isRemaining) {
                    localStorage.setItem("cart", null);
                    setCartItems([]);
                }
                setTimeout(() => setIsModalOpen(false), 5000);
                break;
            default:
                console.warn(`Unhandled payment status: ${status}`);
        }
    };

    const submitPayWithCash = () => {
        setPayCash(false)
        setShowCashSuccess(true)
        localStorage.setItem("cart", null)
    }

    const handleCashInputChange = (e: any) => {
        const cashValue = e.target.value;
        setCashGiven(cashValue)

        const shouldDisablePayment = cashValue < total;
        setDisablePayment(shouldDisablePayment);
        const changeValue = cashValue - total;
        setChangeGiven(changeValue);
        manageRemainingAmountData(cashValue, "cash")
    }
    
    const managePayment = (event) => {
        const newCartTotal = event.target.value;
        setOnlinePayable(newCartTotal)
        
        if (newCartTotal > total || newCartTotal <= 0) {
            setDisablePayment(true);
        } else {
            setDisablePayment(false);
        }
        manageRemainingAmountData(newCartTotal, "card")
    };

    const manageRemainingAmountData = (amount, type) => {
        const cartTotalAmount: any = Number(cartTotal).toFixed(2)
        if ((type === "card" || type === "cash") && amount < cartTotalAmount) {
          setIsRemaining(true);
          const data = cartTotalAmount - amount;
          setRemaingAmount(data);
        }
    };

    const handleDelete = () => {
        localStorage.removeItem("remainingAmountLocal")
        localStorage.removeItem("paidAmount");
        localStorage.removeItem("isRemainingFound")
        localStorage.setItem("cart", null)
        localStorage.removeItem("invoiceRef")
        setPayCash(false)
        setCartItems([])
        setIsRemaining(false)
        setShowDeleteAlert(false);
    };

    return (
        <>
            <IonModal isOpen={showCartModal} backdropDismiss={true} onDidDismiss={() => setShowCartModal(false)} cssClass='cartEditDialog teeSheetModal'>
                <IonToolbar id="groupEditDialogToolbar">
                    <IonTitle className="ion-float-left  ion-padding">Point of Sale</IonTitle>
                    {cartItems && Array.isArray(cartItems) && cartItems?.length > 0 && <IonButton onClick={()=> setShowDeleteAlert(true)} slot="end">Clear cart</IonButton>}
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShowCartModal(false)} fill="clear" color="danger"><IonIcon icon={closeOutline} slot="icon-only"></IonIcon></IonButton>
                    </IonButtons>
                </IonToolbar>

                <IonGrid>
                    <IonRow>
                        <IonCol size="9" className="cartPOS">
                            <div className="cartPOSCategories">
                                {
                                    posData?.Categories?.map((cat, i) =>
                                        <div className={currentCategoryIndex === i ? "cartPOSCategory active" : "cartPOSCategory inactive"} key={i} data-index={i} onClick={() => setCurrentCategoryIndex(i)}>
                                            {cat?.name}

                                        </div>
                                    )
                                }

                            </div>
                            {
                                posData?.Categories?.map((cat, i) =>
                                    <div className={currentCategoryIndex === i ? "cartPOSItems active" : "cartPOSItems inactive"} key={i} data-index={i}>

                                        {cat.items?.length && cat.items?.map((item, i) =>

                                            <div key={i} className="cartPOSItem"
                                                style={{
                                                    background: item?.backgroundColor ? item.backgroundColor : "#f7f7f7",
                                                    color: item?.textColor ? item.textColor : "black"
                                                }}
                                                onClick={() => addCartItem({ name: item?.name, price: item?.price ? Number(item?.price).toFixed(2) : Number("0.00").toFixed(2) })}>
                                                <div>{item.name}</div>
                                                <div><small>${item.price ? Number(item.price).toFixed(2) : Number(0).toFixed(2)}</small></div>
                                            </div>


                                        )}

                                        {!cat.items?.length && <p className="ion-padding">No items in this category.</p>}


                                    </div>
                                )
                            }

                        </IonCol>

                        <IonCol className="cartItemsCol" size="3">
                            {cartTeeGroupsItems && Array.isArray(cartTeeGroupsItems) && cartTeeGroupsItems?.length > 0 &&
                                <div className="cartTeeGroups">
                                    <div className="cartTeeGroupsHeading">Attached Tee Groups:</div>
                                    {cartTeeGroupsItems?.map((cartTeeGroup, i) =>
                                        <IonChip key={i} onClick={() => setCartTeeGroupToEdit(i)} color="secondary">{cartTeeGroup.golfers[0].name}{cartTeeGroup.golfers.length > 1 && " + " + (cartTeeGroup.golfers.length - 1)}</IonChip>
                                    )}
                                </div>
                            }
                            {cartItems?.length === 0 &&
                                <p className="ion-text-center">There are no items in the cart.</p>
                            }

                            {cartItems && Array.isArray(cartItems) && cartItems?.length > 0 &&
                                <>   <div className='cart-wrap'>
                                    <table className="cartTable cartTableItems">
                                        <tbody>
                                            {cartItems?.map((cartItem, i) =>
                                                <tr key={i}>
                                                    <td>{!isRemainingFound && <IonButton onClick={() => removeItemFromCart(i)} className="cartItemCloseButton" fill="clear" size="small" color="danger"><IonIcon slot="icon-only" icon={closeOutline}></IonIcon></IonButton>}{cartItem.name}</td>
                                                    <td>${cartItem?.price ? Number(cartItem?.price).toFixed(2) : Number("0.00").toFixed(2)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                </div>
                                {
                                    !isRemainingFound &&
                                    <IonButton expand="block" fill="clear" color="danger" onClick={() => handleClearCart()}>Clear All Items</IonButton>
                                }

                                    <table className="cartTable cartTableTotals">
                                        <tbody>
                                            <tr>
                                                <td>Subtotal:</td>
                                                <td>${Number(cartSubTotal).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td>Tax:</td>
                                                <td>${Number(cartTaxTotal).toFixed(2)}</td>
                                            </tr>
                                
                                            <tr className="cartTableTotal">
                                                <td>Total:</td>
                                                <td className="ion-text-end">${Number(cartTotal).toFixed(2)}</td>
                                            </tr>
                                            {
                                                isRemainingFound && localStorage.getItem("paidAmount") &&
                                                <tr>
                                                    <td>Paid Amount:</td>
                                                    <td>${localStorage.getItem("paidAmount")}</td>
                                                </tr>
                                            }
                                            {
                                                isRemainingFound && localStorage.getItem("remainingAmountLocal") &&
                                                <tr>
                                                    <td style={{color: '#cb1a27'}}>Remaining:</td>
                                                    <td style={{color: '#cb1a27'}}>${localStorage.getItem("remainingAmountLocal")}</td>
                                                </tr>
                                            }

                                            <tr className="cartTableActions">
                                                <td><IonButton disabled={isRemainingFound && paymentInitiated} expand="block" onClick={() => { setPayCard(true); setDisablePayment(false); {isRemaining ? setOnlinePayable((localStorage.getItem('remainingAmountLocal') ? localStorage.getItem('remainingAmountLocal') : (0))) : setOnlinePayable(Number(cartTotal).toFixed(2))}; setShowAlert(false);  }}>Pay Debit/Credit</IonButton></td>
                                                <td><IonButton disabled={isRemainingFound && paymentInitiated} expand="block" onClick={() => { setPayCash(true); {isRemaining ? setCashGiven((localStorage.getItem('remainingAmountLocal') ? localStorage.getItem('remainingAmountLocal') : (0))) : setCashGiven(Number(cartTotal).toFixed(2))}; setShowAlert(false);  }}>Pay Cash</IonButton></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonModal>

            {/* Pay With Card Modal */}
            <IonModal onDidPresent={() => focusOnFirstInput('customAmount')} isOpen={Boolean(payCard)} cssClass='teeSheetModal modalOverModal' backdropDismiss={true} onDidDismiss={() => {setIsRemaining(localStorage.getItem('isRemainingFound') ? JSON.parse(localStorage.getItem('isRemainingFound')) : (false)); setRemaingAmount(localStorage.getItem('remainingAmountLocal') ? localStorage.getItem('remainingAmountLocal') : (0)); setPayCard(false); setDisablePayment(false); setPayCash(false) }}>
                <IonToolbar id="blockEditToolbar">
                    <IonTitle className="ion-text-center">
                        <IonLabel className="titleEditLabel ion-text-center">Pay With Card</IonLabel>
                    </IonTitle>
                </IonToolbar>

                <IonGrid className="ion-text-center ion-margin-top ion-margin-bottom">
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonInput type='number' className='add-payment' id="customAmount" value={onlinePayable} onIonChange={managePayment}></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonRow>
                    <IonCol>
                        {/* <IonButton type="submit" expand="block" onClick={createCardPayment}>Request Card Payment</IonButton> */}
                        {
                            showAlert ?
                            <div className="posChangesDetectedAlert ion-margin-horizontal">{showAlertMessage}</div>
                            :
                            <>
                                <IonButton type="submit" disabled={loading || disablePayment} expand="block" onClick={createCardPayment}>{submitButtonText} {loading && <IonSpinner style={{width:'20px' , outerHeight:'20px'}} color="light" className='ion-margin-start'></IonSpinner>}</IonButton>
                            </>
                        }
                        </IonCol>
                </IonRow>
            </IonModal>

            {/* Pay With Cash Modal */}
            <IonModal onDidPresent={() => focusOnFirstInput('customAmount')} isOpen={Boolean(payCash)} cssClass='teeSheetModal modalOverModal' backdropDismiss={true} onDidDismiss={() => {setIsRemaining(localStorage.getItem('isRemainingFound') ? JSON.parse(localStorage.getItem('isRemainingFound')) : (false)); setRemaingAmount(localStorage.getItem('remainingAmountLocal') ? localStorage.getItem('remainingAmountLocal') : (0)); setPayCash(false); setPayCard(false); setChangeGiven(0); setCashGivenByBuyer(""); setDisablePayment(false) }}>
                <IonToolbar id="blockEditToolbar">
                    <IonTitle className="ion-text-center">
                        <IonLabel className="titleEditLabel ion-text-center">Pay With Cash</IonLabel>
                    </IonTitle>
                </IonToolbar>

                <IonGrid className="ion-text-center ion-margin-top">
                    <IonRow>
                        <IonCol><div className="modalInstructions">Use the options below to input how much cash was given.</div></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton className="cashButton" onClick={() => addCash(1)}>$1</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton className="cashButton" onClick={() => addCash(5)}>$5</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton className="cashButton" onClick={() => addCash(10)}>$10</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton className="cashButton" onClick={() => addCash(20)}>$20</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton className="cashButton" onClick={() => addCash(50)}>$50</IonButton>
                        </IonCol>

                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton color="warning" className="cashButton" onClick={() => addCash(0.01)}>1&cent;</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton color="warning" className="cashButton" onClick={() => addCash(0.05)}>5&cent;</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton color="warning" className="cashButton" onClick={() => addCash(0.10)}>10&cent;</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton color="warning" className="cashButton" onClick={() => addCash(0.25)}>25&cent;</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton className="cashButton" onClick={() => addCash(100)}>$100</IonButton>
                        </IonCol>

                    </IonRow>
                    <IonRow>
                        <IonCol className='pay-with-cash'>
                            {/* <IonRow className='ion-margin-top'>
                                <div className="modalInstructions">The amount that buyer paid:</div>
                            </IonRow>
                            <IonItem>
                                <IonInput type='number' id="customAmount" className='add-payment' value={cashGivenByBuyer} onIonChange={handleBuyerAmountInputChange}></IonInput>
                            </IonItem>
                            <IonRow className='ion-margin-top'>
                                <div className="modalInstructions">The actual amount to be charged:</div>
                            </IonRow> */}
                            <IonItem>
                                <IonInput type='number' id="customAmount" className='add-payment' value={cashGiven} onIonChange={handleCashInputChange}></IonInput>
                            </IonItem>
                            {changeGiven > 0 && <IonRow className='ion-margin-top'>
                                <div className="modalInstructions"  style={{flex: 'auto' , textAlign: 'left'}}>Change given:</div>
                                <div className="modalInstructions">${Number(changeGiven).toFixed(2)}</div>
                            </IonRow>}
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonRow>
                    <IonCol>
                        {/* <IonButton disabled={disablePayment} type="submit" expand="block" onClick={createCashPayment}>Record Cash Payment</IonButton> */}
                        <IonButton type="submit" disabled={loading || disablePayment} expand="block" onClick={createCashPayment}>Record Cash Payment{loading && <IonSpinner style={{width:'20px' , outerHeight:'20px'}} color="light" className='ion-margin-start'></IonSpinner>}</IonButton>
                    </IonCol>
                </IonRow>
            </IonModal>

            {/* Tee Group To Edit Modal */}
            <IonModal isOpen={cartTeeGroupToEdit !== null} cssClass='teeSheetModal modalOverModal' backdropDismiss={true} onDidDismiss={() => { setCartTeeGroupToEdit(null); }}>
                <IonToolbar id="blockEditToolbar">
                    <IonTitle className="ion-text-center">
                        <IonLabel className="titleEditLabel ion-text-center">This Tee Group is Currently Attached to the Cart</IonLabel>
                    </IonTitle>
                </IonToolbar>

                <IonList>
                    <IonItem>
                        <IonLabel>Golfers:</IonLabel>
                        <IonText>
                            {cartTeeGroupsItems[cartTeeGroupToEdit]?.golfers.map((golfer, i) =>
                                <IonBadge color="medium" className="inlineBadgelistItem" key={i}>{golfer?.name}</IonBadge>
                            )}
                        </IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Date:</IonLabel>
                        <IonText>{moment(cartTeeGroupsItems[cartTeeGroupToEdit]?.date, 'YYYY-MM-DD').format("MMM D, YYYY")}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Tee Time:</IonLabel>
                        <IonText>{cartTeeGroupsItems[cartTeeGroupToEdit]?.startingTime}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Number of Holes:</IonLabel>
                        <IonText>{cartTeeGroupsItems[cartTeeGroupToEdit]?.numHoles}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel>ID:</IonLabel>
                        <IonBadge color="medium">{cartTeeGroupsItems[cartTeeGroupToEdit]?.id}</IonBadge>
                    </IonItem>
                    <IonItem>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton type="submit" expand="block" color="danger" onClick={() => removeGroupFromCart(cartTeeGroupsItems[cartTeeGroupToEdit]?.id, cartTeeGroupToEdit)}>Detach From Cart</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton type="submit" expand="block" onClick={() => setCartTeeGroupToEdit(null)}>Close Window</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonItem>
                </IonList>

            </IonModal>

            {
                isModalOpen &&
                <PaymentConfirmationModal
                    isOpen={isModalOpen}
                    amount={mode == "card" ? onlinePayable : cashGiven}
                    onClose={() => setIsModalOpen(false)}
                />
            }

            {
                isErrorModalOpen &&
                <PaymentErrorModal
                    isOpen={isErrorModalOpen}
                    amount={mode == "card" ? onlinePayable : cashGiven}
                    onClose={() => setIsErrorModalOpen(false)}
                    message={showAlertMessage}
                />
            }

            <IonAlert
                isOpen={showDeleteAlert}
                onDidDismiss={() => setShowDeleteAlert(false)}
                header={'Confirmation'}
                message={'Are you sure you want to delete this cart?'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {
                            console.log('Cancel clicked');
                            setShowDeleteAlert(false);
                        }
                    },
                    {
                        text: 'Ok',
                        handler: handleDelete
                    }
                ]}
            />

            <IonAlert
                isOpen={showCardSuccess}
                onDidDismiss={() => {
                    setShowCardSuccess(false);
                    setShowCartModal(false);
                    setCartItems([]);
                    localStorage.setItem("cart", null);
                }}
                header="Payment Accepted"
                subHeader="The card was successfully processed."
                buttons={['OK']}
            // inputs={[
            //     {
            //       placeholder: 'Name',
            //     },
            //     {
            //       placeholder: 'Nickname (max 8 characters)',
            //       attributes: {
            //         maxlength: 8,
            //       },
            //     },
            //     {
            //       type: 'number',
            //       placeholder: 'Age',
            //       min: 1,
            //       max: 100,
            //     },
            //     {
            //       type: 'textarea',
            //       placeholder: 'A little about yourself',
            //     },
            //   ]}
            />

            <IonAlert
                isOpen={showCashSuccess}
                onDidDismiss={() => {
                    setShowCardSuccess(false);
                    setShowCartModal(false);
                    setCartItems([]);
                    localStorage.setItem("cart", null);
                }}
                header="Payment Accepted"
                subHeader="Cash was received."
                message="Don't forget to put the cash in the till!"
                buttons={['OK']}
            />
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toastMessage}
                duration={3000} // Adjust as needed
            />
        </>
    )
}

export default Cart;