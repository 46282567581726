import Menu from './components/Menu';
import React, { useEffect, useState } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, IonLoading, getPlatforms, IonToast } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';

/* Pages */
import TeeSheet from './pages/TeeSheet';
import POS from './pages/POS';
import People from './pages/People';
import Marketing from './pages/Marketing';
import Weather from './pages/Weather';
import Settings from './pages/Settings';
import TeeSheetSettings from './pages/TeeSheetSettings';
import Login from './pages/Login';
import Register from './pages/Register';
import Account from './pages/Account';
import Courses from './pages/Courses';
import Groups from './pages/Groups';
import Messages from './pages/Messages';
import Payments from './pages/Payments';
import Invoices from './pages/Invoices';
import InvoiceDetail from './pages/InvoiceDetail'

import { AuthContext, useAuthInit } from './auth';
import StoreSettings from './pages/StoreSettings';
import WidgetSettings from './pages/WidgetSettings';
import firebase from 'firebase';
import useFirestoreListener from './hooks/useFirestoreListner';

const App: React.FC<React.PropsWithChildren<unknown>> = () => {

  const updateDarkMode = () => {
    document.body.classList.add('dark');
  }
  updateDarkMode();

  const { loading, auth } = useAuthInit();
  const [loginToast, setLoginToast] = useState(false);
  useFirestoreListener()

  if (loading) {
    return <IonLoading isOpen />;
  }

  return (

    <IonApp>
      <AuthContext.Provider value={auth}>
        <IonReactRouter>
          <IonSplitPane when="(min-width: 2560px)" contentId="main">
            {auth.loggedIn && <Menu />}
            <IonRouterOutlet id="main">
              <Route key="1" path="/page/TeeSheet" component={TeeSheet} exact />
              <Route key="2" path="/page/Groups" component={Groups} exact />
              <Route key="3" path="/page/POS" component={POS} exact />
              <Route key="3" path="/page/StoreSettings" component={StoreSettings} exact />
              <Route key="4" path="/page/People" component={People} exact />
              <Route key="5" path="/page/Marketing" component={Marketing} exact />
              <Route key="6" path="/page/Weather" component={Weather} exact />
              <Route key="7" path="/page/Settings" component={Settings} exact />
              <Route key="8" path="/page/Settings/:tab" component={Settings} exact />
              <Route key="9" path="/page/TeeSheetSettings" component={TeeSheetSettings} exact />
              <Route key="10" path="/page/StoreSettings" component={StoreSettings} exact />
              <Route key="11" path="/page/Courses" component={Courses} exact />
              <Route key="12" path="/page/Login" component={Login} exact />
              <Route key="13" path="/page/Register" component={Register} exact />
              <Route key="14" path="/page/Account" component={Account} exact />
              <Route key="15" path="/page/Messages" component={Messages} exact />
              <Route key="16" path="/page/Payments" component={Payments} exact />
              <Route key="17" path="/page/Invoices" component={Invoices} exact />
              <Route key="18" path="/page/Invoices/:id" component={InvoiceDetail} exact />
              {/* <Route path="/page/Reservations/" component={Reservations} exact ></Route>
              <Route path="/page/Reservations/:course" component={Reservations} exact ></Route> */}
              <Route path="/page/WidgetSettings" component={WidgetSettings} exact ></Route>

              <Redirect from="/" to="/page/Login" exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </AuthContext.Provider>
      <IonToast
        isOpen={loginToast}
        onDidDismiss={() => setLoginToast(false)}
        message="Login successful!"
        duration={2000}
        color="success"
      />
    </IonApp>

  );
};

export default App;
