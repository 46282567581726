import React, { useEffect, useState, useRef } from 'react';
import { IonBadge, IonButton, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonLoading, IonPage, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from '@ionic/react';
import PageHeader from '../components/PageHeader';
import { firestore } from '../config/firebase';
import './Invoices.css';
import moment from 'moment';
import firebase from 'firebase';
import { Link, useHistory } from 'react-router-dom';
import InvoiceDetail from './InvoiceDetail';
import Cart from '../components/Cart';
import RefundModal from './RefundModal';

const PAGE_SIZE = 15;

const Invoices: React.FC<React.PropsWithChildren<unknown>> = () => {
    const group = localStorage.getItem('group') || "undefined";
    const course = localStorage.getItem('currentCourse') || "undefined";
    const [invoices, setInvoices] = useState([]);
    const [pageLoader, setPageLoader] = useState(true);
    const [lastDocument, setLastDocument] = useState<firebase.firestore.DocumentSnapshot | null>(null);
    const [fetchingData, setFetchingData] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [docsLength, setDocLength] = useState(0);
    const contentRef = useRef<HTMLIonContentElement>(null);
    const [statusFilter, setStatusFilter] = useState("");
    const [showDetail, setShowDetail] = useState(false)
    const [currentInvoice, setCurrentInvoice] = useState<any>("")
    const [currentInvoiceId, setCurrentInvoiceId] = useState<any>(null)
    const [showCartModal, setShowCartModal] = useState(false);
    const [paymentsData, setPaymentsData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const data = [{key: "", value : "ALL"}, {key: "PENDING", value : "PENDING"}, {key: "COMPLETED", value : "COMPLETED"}, {key: "CANCELED", value : "CANCELED"}, {key: "IN_PROGRESS", value : "IN PROGRESS"}];

    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (action === 'POP') {
                setShowDetail(false);
            }
        });

        return () => {
            unlisten();
        };
    }, [history]);

    useEffect(() => {
        firebase.firestore().collection('groups').doc(group).collection("invoices").get().then(querySnapshot => {
            setDocLength(querySnapshot.size);
        })

        if (group && course && !fetchingData && lastDocument === null) {
            try {
                setFetchingData(true);

                var db = firebase.firestore();
                var invoiceRef = db.collection('groups').doc(group).collection("invoices").limit(PAGE_SIZE);

                invoiceRef.onSnapshot((querySnapshot) => {
                    const invoiceData = [];
                    querySnapshot.forEach((doc) => {
                        invoiceData.push({ id: doc.id, ...doc.data() });
                    });

                    if (querySnapshot.size > 0) {
                        setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
                    } else {
                        setLastDocument(null);
                    }
                    setInvoices(invoiceData);
                    setPageLoader(false);
                    setFetchingData(false);
                });
            } catch (err) {
                console.log('error getting invoices: ', err)
                setPageLoader(false);
                setFetchingData(false);
            }
        }
    }, [group, course,]);

    useEffect(() => {
        if (currentPage > 1 && !fetchingData && lastDocument !== null) { 
            try {
                setFetchingData(true); 

                var db = firebase.firestore();
                var invoiceRef = db.collection('groups').doc(group).collection("invoices")
                                    .startAfter(lastDocument)
                                    .limit(PAGE_SIZE);

                invoiceRef.onSnapshot((querySnapshot) => {
                    const invoiceData = [];
                    querySnapshot.forEach((doc) => {
                        invoiceData.push({ id: doc.id, ...doc.data() });
                    });

                    if (querySnapshot.size > 0) {
                        setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
                    } else {
                        setLastDocument(null);
                    }

                    setInvoices(prevInvoices => [...prevInvoices, ...invoiceData]);
                    setPageLoader(false);
                    setFetchingData(false);
                });
            } catch (err) {
                console.log('error getting invoices: ', err)
                setPageLoader(false);
                setFetchingData(false);
            }
        }
    }, [currentPage]);
    
    const loadMore = () => {
        setCurrentPage(page => page + 1);
    };

    const renderPagination = () => {
        if (invoices.length < docsLength && !fetchingData && lastDocument !== null) {
            return (
                <IonButton onClick={loadMore}>Load More</IonButton>
            );
        }
        return null;
    };

    const onBackPress = ()=> {
        setShowDetail(false)
    }

    const manageIncompletePayments = (invoice)=> {
        localStorage.setItem("isRemainingFound", JSON.stringify(true))
        localStorage.setItem("invoiceRef", invoice.invoiceId)
        localStorage.setItem("remainingAmountLocal", invoice.remaining)
        localStorage.setItem("paidAmount", invoice.paid)
        localStorage.setItem("cart", JSON.stringify(invoice?.cartItems))
        setShowCartModal(true)
    }

    const fetchPaymentsData = (ids) => {
        const db = firebase.firestore();
        const paymentsRef = db.collection('groups').doc(group).collection('payments');
        const unsubscribeFunctions = [];
        const fetchedData = [];
  
        ids.forEach((id) => {
          const unsubscribe = paymentsRef.doc(id.docId).onSnapshot((doc) => {
            if (doc.exists) {
              const data = { data: doc.data(), docId: id.docId };
              const existingIndex = fetchedData.findIndex(item => item.docId === id.docId);
              if (existingIndex > -1) {
                fetchedData[existingIndex] = data;
              } else {
                fetchedData.push(data);
              }
              setPaymentsData([...fetchedData]);
            }
          });

          openModal()
          unsubscribeFunctions.push(unsubscribe);
        });
    };
  

    console.log("payments Data", paymentsData)
  
    return (
        <>
            
                <IonPage>
                    <PageHeader title="Invoices" settings="" backlink="" />

                    {
                        !showDetail ? 
                        <IonContent ref={contentRef} fullscreen>
                            <IonHeader collapse="condense">
                                <IonToolbar>
                                    <IonTitle size="large">Invoices</IonTitle>
                                </IonToolbar>
                            </IonHeader>
                            {!pageLoader &&
                                <div className="ion-margin-top ion-padding payment-wrap">
                                    <div className='payment-title'>
                                        <h2 className="ion-text-center">Invoices List</h2>
                                    </div>
                                    <div className='payments-table'>
                                        <table className="messageList">
                                            <thead>
                                                <tr>
                                                    <th style={{width: '25%'}} >Id</th>
                                                    <th style={{width: '25%'}} >Cart Items</th>
                                                    <th style={{width: '10%'}}>Payments Count</th>
                                                    <th style={{width: '10%'}}>Paid Amount</th>
                                                    <th style={{width: '5%'}}>Remaining Amount</th>
                                                    <th style={{width: '10%'}}>Invoice</th>
                                                    <th style={{width: '5%'}}>Pending Payments</th>
                                                    <th style={{width: '20%'}}>Refund</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {invoices.map((invoice, i) => {
                                                return (
                                                    <tr key={invoice.id}>
                                                        <td >
                                                            {invoice.payment.map((payment, i) => <span key={i}> {payment.paymentId + (invoice.payment.length - 1 != i ? " , " : "")}</span>)}
                                                        </td>
                                                        <td style={{ width: '20%' }}>
                                                            <div className='cart-list'>
                                                                {invoice?.cartItems.map((item, index) => (
                                                                    <span key={index} className="cart-item">{item.name}<span>${item.price}</span></span>
                                                                ))}
                                                            </div>
                                                        </td>
                                                        <td><span>{invoice.payment.length}</span></td>
                                                        <td><span>${invoice.paid}</span></td>
                                                        <td><span>{invoice.remaining > 0 ? "$" + invoice.remaining : "--"}</span></td>
                                                        <td>{invoice.remaining <= 0 ?<button onClick={() => { setCurrentInvoice(invoice); setShowDetail(true); }} className='fetch-btn' style={{color: 'black'}}>See Invoice</button>: "--"}</td>
                                                        <td>{invoice.remaining > 0 ? <button onClick={()=> manageIncompletePayments(invoice)} className='fetch-btn' style={{color: '#fff', backgroundColor:'#fc030395'}}>Incomplete</button> : "--"}</td>
                                                        <td><button onClick={()=> fetchPaymentsData(invoice.payment)} className='payment-btnvtwo' >Initiate Refund</button></td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {renderPagination()}
                                </div>
                            }
                            <IonLoading
                                cssClass='my-custom-class'
                                isOpen={pageLoader}
                                onDidDismiss={() => setPageLoader(false)}
                                message={'Please wait...'}
                            />
                        </IonContent>
                       :
                       <>
                        <InvoiceDetail invoiceDetail={currentInvoice} onBackPress={onBackPress}></InvoiceDetail>
                       </>
                    }
                </IonPage>
                {showCartModal && <Cart showCartModal={showCartModal} setShowCartModal={setShowCartModal}></Cart>}
                {isModalOpen && <RefundModal isOpen={isModalOpen} onClose={closeModal} data={paymentsData} />}
        </>
    );
};

export default Invoices;


