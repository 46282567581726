// PaymentConfirmationModal.js
import React from 'react';
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonText
} from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import './PaymentConfirmationModal.css';

const PaymentConfirmationModal = ({ isOpen, amount, onClose }) => {
  return (
    <IonModal className="payment-modal-wrap" isOpen={isOpen} onDidDismiss={onClose}>
      <IonContent className="payment-confirmation-modal">
        <IonHeader>
          <IonToolbar>
            <IonButton slot="end" fill="clear" onClick={onClose}>✖️</IonButton>
          </IonToolbar>
        </IonHeader>
        <div className="modal-content">
          <IonIcon icon={checkmarkCircleOutline} className="success-icon" />
          <IonText className="amount-text">${Number(amount).toFixed(2)}</IonText>
          <IonText className="success-text">Payment Successful</IonText>
          <IonText className="thank-you-text">
            Your payment has been successfully processed.<br />
            Thank you for your transaction!
          </IonText>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default PaymentConfirmationModal;
