import React, { useState } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { v4 as uuidv4 } from 'uuid';
import * as CONSTANT from "../config/constants"

import './RefundPopup.css'; // Importing the CSS file
import axios from 'axios';
import useAccessToken from '../hooks/useAccessToken';
import firebase from 'firebase';

interface RefundPopupProps {
  isOpen: boolean;
  onClose: () => void;
  payment: any,
  docId: any
}

const RefundPopup: React.FC<RefundPopupProps> = ({ isOpen, onClose, payment, docId }) => {

    const groupId = localStorage.getItem('group');
    const paymentAccountId = localStorage.getItem('currentSquareApi');

    const [amount, setAmount] = useState<number | undefined>(undefined);
    const [reason, setReason] = useState<string>('');
    const [totalAmount, setTotalAmount] = useState<number>(payment?.payment?.amount_money?.amount / 100); // Example total amount
    const [selectedTab, setSelectedTab] = useState<string>('complete');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const accessToken = useAccessToken(groupId, paymentAccountId, setToastMessage, setShowToast);

    const generateIdempotencyKey = () => {
        return uuidv4();
    }

    const refundPayment = async()=> {
        const data = {
            "idempotency_key": generateIdempotencyKey(),
            "payment_id": payment?.payment?.payment_ids?.[0],
            "amount_money": {
                "amount": totalAmount * 100,
                "currency": payment?.payment?.amount_money?.currency
            },
            "refunded_money": {
                "amount": amount * 100,
                "currency": payment?.payment?.amount_money?.currency
            },
            "reason": reason
        }

        const groupRef = firebase.firestore().collection('groups').doc(groupId);
        const refundsRef = groupRef.collection('refunds').doc();

        const response = {
              "id":"6aVfI",
              "status":"PENDING",
              "amount_money":{
                "amount": totalAmount * 100,
                  "currency": payment?.payment?.amount_money?.currency
              },
              "payment_id":"sXps2bKrr05VXDaOV0dgexample",
              "order_id":"NF446edlDGTq7fzlEzYKSvHJ6xbZY",
              "created_at":"2019-07-15T00:25:08.275Z",
              "updated_at":"2019-07-15T00:25:08.978Z",
              "location_id":"X9XWRESTK1CZ1",
              "reason": reason
            }

        const updatedPaymentResponse = {
            merchant_id: payment.merchant_id,
            event_id: payment.event_id,
            payment: payment.payment,
            refund: response
        };
        await firebase.firestore().collection('groups').doc(groupId).collection('payments').doc(docId).update(updatedPaymentResponse);
        onClose();
        // const headers = {
        //     'Content-Type': 'application/json',
        //     'Authorization': `Bearer ${accessToken}`,
        // }

        // axios.post(CONSTANT.CLOUD_FUNCTION_BASE_URL + `checkout-squareCheckout/square-payment-refund`, data, {
        //     headers: headers
        // })
        // .then(response => {
        //     if (response?.status == 200) {
        //     }
        // })
        // .catch(error => {

        // });
    }

    return (
        <IonModal cssClass={'payment-refund'} isOpen={isOpen} onDidDismiss={onClose}>
        <IonCard className="refund-inner">
            <IonHeader>
            <IonToolbar>
                <IonTitle>Refund Payment</IonTitle>
                <IonButtons slot="end">
                <IonButton onClick={onClose}>Close</IonButton>
                </IonButtons>
            </IonToolbar>
            </IonHeader>
            <IonCardContent>
                <IonSegment value={selectedTab} onIonChange={(e) => setSelectedTab(e.detail.value!)}>
                    <IonSegmentButton value="complete">
                        <IonLabel>Complete Refund</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="partial">
                        <IonLabel>Partial Refund</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                {selectedTab === 'complete' && (
                    <>
                        <IonItem className="refund-item">
                            <IonLabel position="stacked">Total Amount</IonLabel>
                            <IonInput value={totalAmount ? "$" + totalAmount : "--"} readonly />
                        </IonItem>
                        <IonItem className="refund-item">
                            <IonLabel position="stacked">Reason for Refund</IonLabel>
                            <IonTextarea
                                value={reason}
                                onIonChange={(e) => setReason(e.detail.value!)}
                                placeholder="Enter reason"
                            />
                        </IonItem>
                    </>
                )}

                {selectedTab === 'partial' && (
                    <>
                        <IonItem className="refund-item">
                            <IonLabel position="stacked">Total Amount</IonLabel>
                            <IonInput value={totalAmount ? "$" + totalAmount : "--"} readonly />
                        </IonItem>
                        <IonItem className="refund-item">
                            <IonLabel position="stacked">Amount to Refund</IonLabel>
                            <IonInput
                                type="number"
                                value={amount}
                                onIonChange={(e) => setAmount(parseFloat(e.detail.value!))}
                                placeholder="Enter amount"
                            />
                        </IonItem>
                        <IonItem className="refund-item">
                            <IonLabel position="stacked">Reason for Refund</IonLabel>
                            <IonTextarea
                                value={reason}
                                onIonChange={(e) => setReason(e.detail.value!)}
                                placeholder="Enter reason"
                            />
                        </IonItem>
                    </>
                )}

                <IonButton expand="block" disabled={selectedTab == "partial" && (amount <= 0 || amount > totalAmount)} onClick={refundPayment} className="refund-button">
                    Confirm Refund
                </IonButton>
                <IonButton expand="block" fill="outline" onClick={onClose} className="refund-button cancel-button">
                    Cancel
                </IonButton>
            </IonCardContent>
        </IonCard>
        </IonModal>
    )
}

export default RefundPopup;
