import moment from "moment";
import { useState } from "react";

export const currentCourse = () => {
  return localStorage.getItem('currentCourse');
};

export const currentCart = () => {
  return localStorage.getItem('cart');
};

export const group = () => {
  return localStorage.getItem('group');
};

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

//hack to scroll to selection on popovers
export function scrollToSelection() {
  setTimeout(() => {
    if (document.getElementsByClassName('sc-ion-select-popover')[0].querySelector('[aria-checked="true"]')) {
      document.getElementsByClassName('sc-ion-select-popover')[0]
        .querySelector('[aria-checked="true"]')
        .scrollIntoView({ block: "center" });
    }
  }, 500);
}

export function generateTimeslots(timeInterval, startTime, endTime) {

  startTime = moment(startTime, 'h:mm a');
  endTime = moment(endTime, 'h:mm a');

  let slotArray = [];
  slotArray.push(startTime.format('h:mm a'));

  let newTime = startTime;
  while (startTime.isBefore(endTime)) {
    newTime = newTime.add(timeInterval, 'minutes');
    slotArray.push(newTime.format('h:mm a'));
  }

  return slotArray;
}
// export const currentCourseInfo = () => {
// 	const course = localStorage.getItem( 'currentCourse' );
// 	return course;
// }

// function to allow modals to highlight a particular field upon opening.
// Use OnDidPresent={"inputIDHERE"} on the modal
export function focusOnFirstInput(id) {
  setTimeout(() => {
    let firstInput = document?.getElementById(id)?.getElementsByTagName("input")[0];
    if (typeof (firstInput) !== 'undefined' && firstInput !== null) {
      firstInput.focus();
    }
  }, 50);
}