import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonInput,
    IonLabel,
    IonItem,
    IonList,
    IonText,
    IonLoading
  } from '@ionic/react';
  import React, { useState } from 'react';
  import { Redirect } from 'react-router';
  import { useAuth } from '../auth';
  import { auth } from '../config/firebase';
  
  const Register: React.FC<React.PropsWithChildren<unknown>> = () => {
    const {loggedIn } = useAuth();
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [status, setStatus] = useState({loading:false,error:false});
    const [errorDesc,setErrorDesc] = useState('');
  
    const handleRegister = async () => {
      try {
        setStatus({loading:true,error:false});
        const credential = await auth.createUserWithEmailAndPassword(email,password);
        console.log('credential:',credential);
      } catch(error) {
        setStatus({loading:false, error:true});
        console.log('Error Object:',error);
        if(error.code === "auth/email-already-in-use") {
          setErrorDesc('That email already exists.');
        } else if(error.code === "auth/weak-password") {
          setErrorDesc('Please use a stronger password.');
        } else if(error.code === "auth/invalid-email") {
          setErrorDesc('You need to use a valid email address.');
        } else {
          setErrorDesc('There was an error registering. Please try again.');
        }
      }
    };
  
    if(loggedIn) {
      return <Redirect to="/page/TeeSheet" />;
    } else {
      return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Register</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              <IonItem className="ion-no-padding">
                <IonLabel position="stacked">Email</IonLabel>
                <IonInput type="email" value={email} onIonChange={(event) => setEmail(event.detail.value)} />
              </IonItem>
              <IonItem className="ion-no-padding">
                <IonLabel position="stacked">Password</IonLabel>
                <IonInput type="password" value={password} onIonChange={(event) => setPassword(event.detail.value)}/>
              </IonItem>
            </IonList>
            {status.error &&
              <div className="ion-text-center ion-margin-top"><IonText color="danger">{errorDesc}</IonText></div>
            }
            <IonButton className="ion-margin-top" expand="block" onClick={handleRegister}>Create Account</IonButton>
            <IonButton expand="block" fill="clear" routerLink="/login">Already have an account?</IonButton>
  
            <IonLoading isOpen={status.loading} />
          </IonContent>
        </IonPage>
      );
    }
  };
  
  export default Register;