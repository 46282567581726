import { IonBadge, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonLoading, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import PageHeader from '../components/PageHeader';
import { firestore } from '../config/firebase';
import './Messages.css';
import moment from 'moment';

const Messages: React.FC<React.PropsWithChildren<unknown>> = () => {

    const group = localStorage.getItem('group') ? (localStorage.getItem('group')) : ("undefined");
    const course = localStorage.getItem('currentCourse') ? (localStorage.getItem('currentCourse')) : ("undefined");
    const [messages, setMessages] = useState([]);
    const [pageLoader, setPageLoader] = useState(true);

    useEffect(() => {
        if (group > '' && course > '') {
            try {
                firestore.collection('messages').where("courseID", "==", course).where("groupID", "==", group).orderBy("delivery.startTime", "desc").limit(100).onSnapshot(({ docs }) => {
                    const mappedData = docs.map(d => ({ id: d.id, ...d.data() }));
                    setMessages(mappedData);
                });

            } catch (err) {
                console.log('error getting messages: ', err)
            } finally {
                setPageLoader(false);
            }
        }
    }, [group, course]);

    return (
        <IonPage>
            <PageHeader title="Messages" settings="" backlink=""></PageHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Messages</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {pageLoader === false &&
                    <div className="ion-margin-top ion-padding-top">
                        <h2 className="ion-text-center">Here are your last {messages.length} messages sent.</h2>
                        <table className="messageList">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>To</th>
                                    <th>Phone</th>
                                    <th>Message</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            {messages.length > 0 && messages.map((message, i) =>
                                <tr key={message.id}>

                                    <td>{moment(message?.delivery?.startTime?.toDate()).format('MMM D, YYYY')}</td>
                                    <td>{moment(message?.delivery?.startTime?.toDate()).format('h:mm a')}</td>
                                    <td>{(message?.userLastName || message?.userFirstName) && message?.userLastName + ", " + message?.userFirstName}</td>
                                    <td>{message?.to}</td>
                                    <td>{message?.body}</td>
                                    <td><IonBadge className="messageStatusBadge" color={message?.delivery?.state === "SUCCESS" ? "success" : message?.delivery?.errorCode === "21604" ? "warning" : "danger"}>{message?.delivery?.state === "SUCCESS" ? "Delivered" : message?.delivery?.errorCode === "21211" ? "Invalid Phone Number" : message?.delivery?.errorCode === "21604" ? "No Phone Number Provided" : message?.delivery?.errorMessage.split('.')[0]}</IonBadge></td>
                                </tr>
                            )}
                        </table>
                    </div>
                }
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={pageLoader}
                    onDidDismiss={() => setPageLoader(false)}
                    message={'Please wait...'}
                />
            </IonContent>
        </IonPage>
    );
};

export default Messages;
