import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PageHeader from '../components/PageHeader';
import { useAuth } from '../auth';
import { Redirect } from 'react-router';

const Weather: React.FC<React.PropsWithChildren<unknown>> = () => {
  // Weather
 
  const [todaysWeather,setTodaysWeather]=useState([]);
  
  useEffect(() => {
    axios.get('https://api.openweathermap.org/data/2.5/weather?zip=84015,us&units=imperial&appid=3ee8d57c6896597837e21c3cb55ad615')
    .then(function (response) {
      // handle success
      setTodaysWeather(response.data);
      console.log('WEATHER: ',todaysWeather);
    })
    .catch(function (error) {
      // handle error
      console.log('WEATHER ERROR: ',error);
    })
    .then(function () {
      // always executed
    });
  }, [todaysWeather]);
  const { loggedIn } = useAuth();
  if(!loggedIn) {
    return <Redirect to="/page/Login" />;
  } else {

  return (
    <IonPage>
      <PageHeader title="Weather" settings="" backlink=""></PageHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Weather</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <div className="centered-app-container">
          <ul>
            {/* <li>Current Temp: {todaysWeather.main.temp}</li> */}
          </ul>
        </div>
        
      </IonContent>
    </IonPage>
  );
  }
};

export default Weather;
