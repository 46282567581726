import { IonButton, IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Redirect } from 'react-router';
import PageHeader from '../components/PageHeader';
import { useAuth } from '../auth';
import { auth } from '../config/firebase';

const handleLogout = () => {
  localStorage.removeItem('currentCourse');
  localStorage.removeItem('group');
  auth.signOut();
}

const Account: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { loggedIn } = useAuth();
  console.log("Logged In: ",loggedIn);



  if (loggedIn) {  
    return (
      <IonPage>
        <PageHeader title="Account" settings="" backlink=""></PageHeader>

        <IonContent fullscreen>


          <div className="ion-padding">
            <p>Use the button below to logout. More coming soon.</p>
            <IonButton className="ion-margin-top" onClick={handleLogout}>Logout</IonButton>
          </div>

        </IonContent>
      </IonPage>
    );
  } else {
    return <Redirect to="/page/Login" />;

  }
};

export default Account;
