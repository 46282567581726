import { IonContent, IonPage, IonSegment, IonSegmentButton, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import PageHeader from '../components/PageHeader';
import SettingsCourse from '../components/SettingsCourse';
import SettingsReservationWidget from '../components/SettingsReservationWidget';
import SettingsTeeSheets from '../components/SettingsTeeSheets';
import { firestore } from '../config/firebase';
import './Settings.css';

interface RouteParams {
  tab: string;
}

function Settings() {
  const [segment, setSegment] = useState('course');
  const groupID = localStorage.getItem('group');
  const courseID = localStorage.getItem('currentCourse');
  const [course, setCourse] = useState(null);
  const { tab } = useParams<RouteParams>();
  const history = useHistory();


  useEffect(() => {
    if (groupID > '' && courseID > '') {
      const groupCourse = firestore.collection('groups').doc(groupID).collection('courses').doc(courseID);

      // Get Widget Settings
      try {
        groupCourse.get().then(snapshot => {
          setCourse(snapshot.data());
        });
      } catch (err) {
        console.error("Error: ", err);
      }
    }
    //eslint-disable-next-line
  }, [courseID, groupID]);

  useEffect(() => {
    if(tab > '')
    setSegment(tab);

  }, [tab])

  return (

    <IonPage>

      <PageHeader title="Settings" settings="" backlink=""></PageHeader>

      <IonToolbar className="settingsMenu">
        <IonSegment value={segment} onIonChange={(e) => {setSegment(e.detail.value as any);history.replace("/page/Settings/"+e.detail.value);}}>
          <IonSegmentButton value="course">
            Course
          </IonSegmentButton>
          <IonSegmentButton value="teesheets">
            TeeSheets
          </IonSegmentButton>
          <IonSegmentButton value="widget">
            Reservation Widget
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>

      <IonContent fullscreen>

        {segment === 'course' && <SettingsCourse course={course} />}
        {segment === 'teesheets' && <SettingsTeeSheets />}
        {segment === 'widget' && <SettingsReservationWidget />}

      </IonContent>


    </IonPage>

  );
};

export default Settings;
