import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import './InvoiceDetail.css';
import { IonButton, IonIcon, IonLoading } from '@ionic/react';
import { arrowBack, colorFill } from 'ionicons/icons';

export default function InvoiceDetail({invoiceDetail, onBackPress }) {
    const [invoice, setInvoice] = useState(null);
    const [subTotal, setSubTotal] = useState(0);
    const [pageLoader, setPageLoader] = useState(true);

    useEffect(() => {
        const fetchInvoiceData = async () => {
            try {
                    const group = localStorage.getItem('group') || "undefined";

                    const invoiceData = invoiceDetail;
                    const paymentPromises = invoiceData.payment.map(async (payment) => {
                        const paymentDoc = await firebase.firestore().collection('groups').doc(group).collection('payments').doc(payment.docId).get();
                        return paymentDoc.exists ? paymentDoc.data() : null;
                    });

                    const payments = await Promise.all(paymentPromises);
                    invoiceData.payments = payments.filter(payment => payment !== null);

                    setInvoice(invoiceData);

                    if (invoiceData.cartItems && Array.isArray(invoiceData.cartItems)) {
                        const amount = invoiceData.cartItems.reduce((total, item) => total + parseFloat(item.price), 0);
                        setSubTotal(amount);
                    }
                    setPageLoader(false);
            } catch (error) {
                console.error("Error fetching invoice data: ", error);
                setPageLoader(false);
            }
        };

        fetchInvoiceData();
        
    }, [invoiceDetail]);

    const extractDate = (isoString) => {
        if (!isoString) return '';
        return isoString.split('T')[0];
    };

    let totalAmount;

    const calculateTotalAmount = () => {
        totalAmount = 0
        invoice.payments.forEach(item => {
            totalAmount += Number(item?.payment?.amount_money?.amount || 0);
        });
        return totalAmount;
    };

    return (
        <div className='invoice-wrap'>
            {invoice ? 
                <div>
                    <table style={{ width: '100%' }} className='invoice-table'>
                        <thead>
                            <tr style={{ height: '50px' }}>
                                <th
                                    style={{
                                        textAlign: 'left',
                                        fontSize: '24px',
                                        color: 'black',
                                        verticalAlign: 'top',
                                    }}
                                    colSpan={5}
                                >
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                    <IonButton fill='clear' style={{margin : '0' , color: 'black', height: '20px', marginLeft: '-18px'}} onClick={onBackPress}>
                                        <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                                    </IonButton>
                                        Invoice
                                    </div>
                                </th>
                                <th colSpan={6} style={{ textAlign: 'right' }}>
                                    <strong
                                        style={{
                                            width: '250px',
                                            height: '60px',
                                            display: 'flex',
                                            marginLeft: 'auto',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <img
                                            style={{ borderRadius: '8px', objectFit: 'contain', width: '250px', height: '100%', display: 'block' }}
                                            src={require("../img/teerocket-logo.png")}
                                            alt="img"
                                        />
                                    </strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{height: '25px'}}>
                                <td >
                                    <strong style={{ color: 'black' }}> Invoice number:</strong>
                                </td>
                                    {invoice.payments.map((item, index) => (
                                <td key={index}>
                                    <strong style={{ color: 'black' }}>TeeRocket-{item?.payment?.id}</strong>
                                </td>
                            ))}
                            </tr>
                            <tr style={{height: '25px'}}>
                                <td style={{ color: 'black' }}>Payment Amounts:</td>
                                {invoice.payments.map((item, index) => {
                                    return (
                                        <td key={index} style={{ color: 'black' }}>
                                            ${item?.payment?.amount_money?.amount / 100}
                                        </td>
                                    );
                                })}
                            </tr>
                            <tr style={{height: '25px'}}>
                                <td style={{ color: 'black' }}>Created date:</td>
                                {invoice.payments.map((item, index) => (
                                    <td key={index} style={{ color: 'black' }}>{extractDate(item?.payment?.created_at)} </td>
                                ))}
                            </tr>
                            <tr style={{height: '25px'}}>
                                <td style={{ color: 'black' }}>Updated date:</td>
                                {invoice.payments.map((item, index) => (
                                    <td key={index} style={{ color: 'black' }}>{extractDate(item?.payment?.updated_at)} </td>
                                ))}
                            </tr>
                            <tr style={{height: '25px'}}>
                                <td style={{ color: 'black' }}>Payment Type:</td>
                                {invoice.payments.map((item, index) => (
                                    <td key={index} style={{ color: 'black' }}>{item?.payment?.payment_type == "CARD_PRESENT" ? "Card": "Cash"}</td>
                                ))}
                            </tr>
                            <tr style={{height: '30px'}}>
                                <td style={{ color: 'black' }}>Status:</td>
                                {invoice.payments.map((item, index) => (
                                    <td key={index} style={{ color: 'black'  }}> <span className={`payment-status ${
                                        item?.payment?.status === "PENDING" ? 'pending' :
                                            item?.payment?.status === "CANCELED" ? 'canceled' :
                                                item?.payment?.status === "COMPLETED" ? 'completed' :
                                                    'progress'
                                        }`} style={{fontSize: '14px'}}>{item?.payment?.status.replace(/_/g, ' ')}</span> </td>
                                ))}
                            </tr>
                            <tr style={{height: '25px'}}>
                                <td style={{ color: 'black' }}>Total number of payments:</td>
                                <td style={{ color: 'black' }}>{invoiceDetail?.payment?.length}</td>

                            </tr>
                            <tr style={{ height: '50px', verticalAlign: 'bottom' }}>
                                <td>
                                    <strong style={{ color: 'black' }}>Invoice to:</strong>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ color: 'black' }}>AmentoTech Dev Group</td>
                            </tr>
                            <tr style={{ height: '70px', verticalAlign: 'bottom' }}>
                                <td style={{ paddingBottom: '10px', color: 'black' }} colSpan={8}>
                                    Description
                                </td>
                                <td style={{ textAlign: 'right', paddingBottom: '10px', color: 'black' }}>Qty</td>
                                <td style={{ textAlign: 'right', paddingBottom: '10px', color: 'black' }}>
                                    Unit price
                                </td>
                                <td style={{ textAlign: 'right', paddingBottom: '10px', color: 'black' }}>
                                    Amount
                                </td>
                            </tr>
                            {invoice?.cartItems?.map((item, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        borderTop: '1px solid #dddddd',
                                        height: '50px',
                                        verticalAlign: 'top',
                                    }}
                                >
                                    <td colSpan={8} style={{ paddingTop: '10px', color: 'black' }}>
                                        {item.name}
                                    </td>
                                    <td style={{ paddingTop: '10px', textAlign: 'right', color: 'black' }}>1</td>
                                    <td style={{ paddingTop: '10px', textAlign: 'right', color: 'black' }}>${item.price}</td>
                                    <td style={{ paddingTop: '10px', textAlign: 'right', color: 'black' }}>${item.price}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={5}></td>
                                <td colSpan={6}>
                                    <table style={{ width: '100%', marginTop: '50px' }}>
                                        <tbody>
                                            <tr style={{ borderTop: '1px solid #dddddd', height: '40px' }}>
                                                <td
                                                    colSpan={5}
                                                    style={{
                                                        color: 'black',
                                                        paddingTop: '10px',
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    Subtotal:
                                                </td>
                                                <td
                                                    colSpan={6}
                                                    style={{
                                                        color: 'black',
                                                        paddingTop: '10px',
                                                        textAlign: 'right',
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    ${subTotal}
                                                </td>
                                            </tr>
                                            <tr style={{ borderTop: '1px solid #dddddd', height: '40px' }}>
                                                <td
                                                    colSpan={5}
                                                    style={{
                                                        color: 'black',
                                                        paddingTop: '10px',
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    Tax:
                                                </td>
                                                <td
                                                    colSpan={6}
                                                    style={{
                                                        color: 'black',
                                                        paddingTop: '10px',
                                                        textAlign: 'right',
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    ${((calculateTotalAmount() / 100) - Number(subTotal)).toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr style={{ borderTop: '1px solid #dddddd', height: '40px' }}>
                                                <td
                                                    colSpan={5}
                                                    style={{
                                                        color: 'black',
                                                        paddingTop: '10px',
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                <strong style={{ color: 'black' }}>Total:</strong>
                                                </td>
                                                <td
                                                    colSpan={6}
                                                    style={{
                                                        paddingTop: '10px',
                                                        color: 'black',
                                                        textAlign: 'right',
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <strong style={{ color: 'black' }}>${calculateTotalAmount() /100}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> :
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={pageLoader}
                    onDidDismiss={() => setPageLoader(false)}
                    message={'Please wait...'}
                />
        }
    </div>
  )
}
