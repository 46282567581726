// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfig = {
    // apiKey: "AIzaSyCTyKVSFKCLOYSfdLMgbrJQzkZxn8P2bQw",
    // authDomain: "teerocket.firebaseapp.com",
    // projectId: "teerocket",
    // storageBucket: "teerocket.appspot.com",
    // messagingSenderId: "567336895147",
    // appId: "1:567336895147:web:a9de84a2558af665032dee",
    // measurementId: "G-TRLN5VF4QG"
    apiKey: "AIzaSyA-VgO0VQHDGMPTmtIqejWVG1Hngxt0mbo",
    authDomain: "teerocketdevelopment.firebaseapp.com",
    projectId: "teerocketdevelopment",
    storageBucket: "teerocketdevelopment.appspot.com",
    messagingSenderId: "290794491417",
    appId: "1:290794491417:web:2f43e5a65ce5e50c886488",
    measurementId: "G-Q29Q8JY9C0"
};

const app = firebase.initializeApp(firebaseConfig);
// const analytics = firebase.analytics();

export const auth = app.auth();
export const firestore = app.firestore();
export const db = app.firestore();
export const storage = app.storage();