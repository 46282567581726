import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';

import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { golfOutline, layersOutline, megaphoneOutline, newspaperOutline, peopleOutline, personOutline, rainyOutline, settingsOutline, storefrontOutline, documentTextOutline, cashOutline } from 'ionicons/icons';
import './Menu.css';
import { useAuth } from '../auth';
import { currentCourse } from './functions';
// import Cart from './Cart';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

//eslint-disable-next-line
const extraPages = [
  {
    title: 'Store',
    url: '/page/Store',
    iosIcon: storefrontOutline,
    mdIcon: storefrontOutline,
  },
  {
    title: 'People',
    url: '/page/People',
    iosIcon: peopleOutline,
    mdIcon: peopleOutline,
  },
  {
    title: 'Marketing',
    url: '/page/Marketing',
    iosIcon: megaphoneOutline,
    mdIcon: megaphoneOutline,
  },
  {
    title: 'Weather',
    url: '/page/Weather',
    iosIcon: rainyOutline,
    mdIcon: rainyOutline,
  },
  {
    title: 'Groups',
    url: '/page/Groups',
    iosIcon: layersOutline,
    mdIcon: layersOutline,
  },
  {
    title: 'Settings',
    url: '/page/Settings',
    iosIcon: settingsOutline,
    mdIcon: settingsOutline,
  }
];

const appPages: AppPage[] = [
  {
    title: 'Tee Sheet',
    url: '/page/TeeSheet',
    iosIcon: golfOutline,
    mdIcon: golfOutline,
  },
  {
    title: 'Courses',
    url: '/page/Courses',
    iosIcon: newspaperOutline,
    mdIcon: newspaperOutline,
  },
  {
    title: 'People',
    url: '/page/People',
    iosIcon: peopleOutline,
    mdIcon: peopleOutline,
  },
  {
    title: 'Messages',
    url: '/page/Messages',
    iosIcon: megaphoneOutline,
    mdIcon: megaphoneOutline,
  },
  {
    title: 'Point of Sale',
    url: '/page/POS',
    iosIcon: storefrontOutline,
    mdIcon: storefrontOutline,
  },
  {
    title: 'Payments',
    url: '/page/Payments',
    iosIcon: cashOutline,
    mdIcon: cashOutline,
  },
  {
    title: 'Invoices',
    url: '/page/Invoices',
    iosIcon: documentTextOutline,
    mdIcon: documentTextOutline,
  },
  {
    title: 'Settings',
    url: '/page/Settings',
    iosIcon: settingsOutline,
    mdIcon: settingsOutline,
  },
  {
    title: 'Account',
    url: '/page/Account',
    iosIcon: personOutline,
    mdIcon: personOutline,
  }
];

const Menu: React.FC<React.PropsWithChildren<unknown>> = () => {

  const location = useLocation();
  const { loggedIn } = useAuth();

  const pathname = window.location.pathname.substring(0, 18);

  //below we allow non-authenticated users to view the Reservations page
  if (!loggedIn && pathname !== '/page/Reservations') {
    return <Redirect to="/page/Login"></Redirect>
  }
  if (currentCourse()) {
    return (
      <>
        <IonMenu contentId="main" side="start" menuId="menu" type="overlay">
          <IonContent>
            <IonList id="inbox-list">
              <img id="menuLogo" alt="" src={require("../img/teerocket-logo.png")}></img>
              {/* <IonListHeader className="teeRocketDateHeader">
            

              {moment().format('dddd, MMM Do')} @ {moment().format('h:mma')}
              </IonListHeader> */}
              {/* <IonNote>H:72, L:56, Sunny day</IonNote> */}
              {appPages.map((appPage, index) => {
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem className={location.pathname === appPage.url ? 'selected' : 'undefined'} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                      <IonIcon slot='start' ios={appPage.iosIcon} md={appPage.mdIcon} />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              })}
            </IonList>

          </IonContent>
        </IonMenu>
        {/* <IonMenu contentId="main" side="end" menuId="cart" type="overlay">
          <IonContent>
            TExt here.
            <Cart></Cart>
          </IonContent>
        </IonMenu> */}

      </>
    );
  } else {
    return null;
  }
};

export default Menu;
