import { useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

const updateDevice = async (group, documentData, documentId, change) => {
  try {
    const devicesSnapshot = await firebase.firestore().collection('groups').doc(group).collection('devices').get();
    devicesSnapshot.forEach(async (deviceDoc) => {
      const deviceId = deviceDoc.data().device_code.id;
      if (documentId === deviceId && deviceDoc.data().device_code.status === 'UNPAIRED') {
        const updatedResponse = {
          merchant_id: documentData.merchant_id,
          event_id: documentData.event_id,
          device_code: documentData.data.object.device_code,
          squareAccountTitle: deviceDoc.data().squareAccountTitle
        };

        await firebase.firestore().collection('groups').doc(group).collection('devices').doc(deviceDoc.id).update(updatedResponse);
        console.log("Document updated in 'devices' collection");

        await firebase.firestore().collection('webhooksResponse').doc(change.doc.id).delete();
        console.log("Document removed from 'webhooksResponse': ", change.doc.data());
      }
    });
  } catch (error) {
    console.error("Error updating device: ", error);
  }
};

const updatePayment = async (group, documentData, documentId, change) => {
  try {
    const paymentsSnapshot = await firebase.firestore().collection('groups').doc(group).collection('payments').get();
    paymentsSnapshot.forEach(async (paymentDoc) => {
      const paymentId = paymentDoc.data().payment.id;
      if (documentId === paymentId) {
        const updatedResponse = {
          merchant_id: documentData.merchant_id,
          event_id: documentData.event_id,
          payment: documentData.data.object.checkout
        };

        await firebase.firestore().collection('groups').doc(group).collection('payments').doc(paymentDoc.id).update(updatedResponse);
        console.log("Document updated in 'payments' collection");

        await firebase.firestore().collection('webhooksResponse').doc(change.doc.id).delete();
        console.log("Document removed from 'webhooksResponse': ", change.doc.data());
      }
    });
  } catch (error) {
    console.error("Error updating payment: ", error);
  }
};

const updateRefund = async (group, documentData, documentId, change) => {
  try {
        const paymentsSnapshot = await firebase.firestore().collection('groups').doc(group).collection('payments').get();
        paymentsSnapshot.forEach(async (paymentDoc) => {
          if (paymentDoc.data().payment?.status == "COMPLETED" && paymentDoc.data().payment?.payment_ids?.includes(change.doc.data().data.object.refund.payment_id) ) {
            const updatedPaymentResponse = {
              merchant_id: paymentDoc.data().merchant_id,
              event_id: paymentDoc.data().event_id,
              payment: paymentDoc.data().payment,
              refund: documentData.data
            };
            await firebase.firestore().collection('groups').doc(group).collection('payments').doc(paymentDoc.id).update(updatedPaymentResponse);
          }
        })

        await firebase.firestore().collection('webhooksResponse').doc(change.doc.id).delete();
  } catch (error) {
    console.error("Error updating payment: ", error);
  }
};

const handleSnapshotChange = async (change, group) => {
  const documentData = change.doc.data();
  const documentId = documentData.data.id;

  if (change.type === "added" && documentData.type === "device.code.paired") {
    await updateDevice(group, documentData, documentId, change);
  }

  if (documentData.type === "terminal.checkout.updated" || documentData.type === "terminal.checkout.created") {
    await updatePayment(group, documentData, documentId, change);
  }

  if (documentData.type === "refund.created" || documentData.type === "refund.updated") {
    await updateRefund(group, documentData, documentId, change);
  }
};

const useFirestoreListener = () => {
  useEffect(() => {
    const group = localStorage.getItem('group') || "undefined";

    if (group) {
      const unsubscribe = firebase.firestore().collection('webhooksResponse').onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          handleSnapshotChange(change, group);
        });
      });
      return () => unsubscribe();
    }
  }, []);
};

export default useFirestoreListener;
