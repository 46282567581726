import { IonAlert, IonButton, IonCardContent, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { add } from 'ionicons/icons';
import { trashOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import PageHeader from '../components/PageHeader';
import { auth, firestore } from '../config/firebase';
import './TeeSheetSettings.css';
import moment from 'moment';


const TeeSheetSettings: React.FC<React.PropsWithChildren<unknown>> = () => {

    //Hard code group and course for now
    const group = localStorage.getItem('group');
    const course = localStorage.getItem('currentCourse');
    //Create a string to reference for all db calls
    const groupCourse = firestore.collection('groups').doc(group).collection('courses').doc(course);
    const [courseData, setCourseData] = useState(null);


    const [teeSheets, setTeeSheets] = useState([]);

    const [newTeeSheetTimeSlots, setNewTeeSheetTimeSlots] = useState([]);

    const [newTeeSheet, setNewTeeSheet] = useState(null);
    const [teeSheetToEdit, setTeeSheetToEdit] = useState(null);

    const daysInWeekRadioOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const [userPermitted, setUserPermitted] = useState(false);
    useEffect(() => {
        const groupUser = firestore.collection('groups').doc(group).collection('users').doc(auth?.currentUser.uid);
        try {
            groupUser.get().then(doc => {
                if (doc.exists) {
                    if (doc.data().role === 'admin') {
                        setUserPermitted(true);
                        console.log(doc.data().role);
                    }
                } else {
                    console.log("no permissions for this group");
                }
            });
        } catch (error) {
            console.log(error);
        }
    }, [group]);

    //Get Course Data
    useEffect(() => {
        console.log("Group ++++", group);
        console.log("Course ++++", course);
        if (group > '' && course > '') {
          const groupCourse = firestore.collection('groups').doc(group).collection('courses').doc(course);
    
          // Get Widget Settings
          try {
            groupCourse.get().then(snapshot => {
              console.log("Course Data: ", snapshot.data())
              setCourseData(snapshot.data());
            });
          } catch (err) {
            console.error("Error: ", err);
          }
        }
        //eslint-disable-next-line
      }, [course, group]);

    // Function to Add a TeeSheet
    const addTeeSheet = async e => {
        e.preventDefault();
        try {
            const a = await groupCourse.collection('teesheets').add(newTeeSheet);
            console.log('New Tee Sheet ID: ', a.id);
            setNewTeeSheet(null);
        } catch (err) {
            console.log('error adding ==>>', err);
        }
    }

    const addNewTeeSheet = () => {
        setNewTeeSheet({
            name: '',
            startTime: '6:00 am',
            endTime: '6:00 pm',
            reroundTeeID: '',
            active: false,
            teeTimeInterval: 10,
            roundLength: '120',
            numHoles: '18',
            blockedTimes: []
        });
    }

    // Function to Edit a TeeSheet
    const editTeeSheet = async e => {
        e.preventDefault();
        try {
            await groupCourse.collection('teesheets').doc(teeSheetToEdit.id).update(teeSheetToEdit);
            setTeeSheetToEdit(null);
            window.location.reload();//refresh page until we find something better like on Snapshot
        } catch (err) {
            console.log('error updating teesheet: ', err);
        }
    }

    // Function to Edit a TeeSheet's Blocked Tee Times
    const handleAddBlockedTeeTime = async (teeSheetId) => {
        const teeSheetIndex = teeSheets.findIndex(t => t.id === teeSheetId);
        const clonedTeeSheets = [...teeSheets];
        const teeSheet = clonedTeeSheets[teeSheetIndex];
        teeSheet.blockedTimes = (teeSheet.blockedTimes || []).concat({ title: '', days: '', time: '' });
        clonedTeeSheets.splice(teeSheetIndex, 1, teeSheet);
        setTeeSheets(clonedTeeSheets);
    }

    // Function to Delete a TeeSheet
    const deleteTeeSheet = async () => {
        console.log('DELETING', teeSheetToEdit.id);
        try {
            await groupCourse.collection('teesheets').doc(teeSheetToEdit.id).delete();
            setTeeSheetToEdit(null);
        } catch (err) {
            console.log('error deleting ==>>', err);
        }
    }

    //potentially change timeslots if tee time interval changes
    useEffect(() => {
        console.log('ADDING:', newTeeSheet);
        setNewTeeSheetTimeSlots(generateTimeslots(10, "5:00am", "9:30pm"));
        // console.log('ADDING SLOTS:',newTeeSheetTimeSlots);
    }, [newTeeSheet]);

    // Get teesheets for active course
    useEffect(() => {
        try {
            groupCourse.collection('teesheets').onSnapshot(({ docs }) => {
                const mappedData = docs.map(d => ({ id: d.id, ...d.data() }));
                setTeeSheets(mappedData);
            });

        } catch (err) {
            console.log('error=====>>>>', err)
        }
        //eslint-disable-next-line
    }, [course]);

    console.log('TeeSheets: ', teeSheets);

    //Let's create an array of timeslots for each teeSheet
    const generateTimeslots = (timeInterval, startTime, endTime) => {

        startTime = moment(startTime, 'h:mm a');
        endTime = moment(endTime, 'h:mm a');

        let slotArray = [];
        slotArray.push(startTime.format('h:mm a'));

        let newTime = startTime;
        while (startTime.isBefore(endTime)) {
            newTime = newTime.add(timeInterval, 'minutes');
            slotArray.push(newTime.format('h:mm a'));
        }

        return slotArray;
    }

    const generateTeeSheetTimeSlots = (teeSheet) => {
        return generateTimeslots(teeSheet.teeTimeInterval, "5:00am", "9:30pm");
    }

    //hack to scroll to selection on popovers
    const scrollToSelection = () => {
        setTimeout(() => {
            if (document.getElementsByClassName('sc-ion-select-popover')[0].querySelector('[aria-checked="true"]')) {
                document.getElementsByClassName('sc-ion-select-popover')[0]
                    .querySelector('[aria-checked="true"]')
                    .scrollIntoView({ block: "center" });
            }
        }, 500);
    }

    const updateBlockedTimes = async (teeSheetId) => {
        try {
            const teeSheet = teeSheets.find(t => t.id === teeSheetId);
            await groupCourse.collection('teesheets').doc(teeSheetId).update({
                blockedTimes: teeSheet.blockedTimes
            });
            // disableSaveButton(teeSheetId);
            Array.from(document.querySelectorAll('.changed')).forEach((el) => el.classList.remove('changed'));

        } catch (err) {
            console.log('error updating blockedtimes', err);
        }
    }

    // delete panel
    const [confirmDelete, setConfirmDelete] = useState({ isOpen: false, type: '' });

    const handleInputChange = ({ event, teeSheet, index }) => {
        // enableSaveButton(teeSheet.id);
        const { value, name } = event.target;
        event.target.classList.add('changed');
        const teeSheetIndex = teeSheets.findIndex(t => t.id === teeSheet.id);
        const blockedTime = teeSheet.blockedTimes[index];
        blockedTime[name] = value;
        setTeeSheets(prev => {
            prev[teeSheetIndex] = teeSheet;
            return prev;
        });
    }

    const handleBlockedTimeRemove = async ({ teeSheet, index }) => {
        try {
            const teeSheetIndex = teeSheets.findIndex(t => t.id === teeSheet.id);
            teeSheet.blockedTimes.splice(index, 1);
            await groupCourse.collection('teesheets').doc(teeSheet.id).update({
                blockedTimes: teeSheet.blockedTimes
            });
            setTeeSheets(prev => {
                prev[teeSheetIndex] = teeSheet;
                return prev;
            });
        } catch (err) {
            console.log('error updating blockedtimes', err);
        }
    }

    return (
        <div>

            {userPermitted &&
                <IonPage>

                    <PageHeader title={"Tee-Sheet Settings for " + courseData?.name} settings="" backlink="/page/TeeSheet"></PageHeader>

                    <IonContent fullscreen className="ion-padding">
                        <IonGrid>
                            <IonRow>

                                {teeSheets.length ?
                                    teeSheets.map((teeSheet) =>
                                        <IonCol key={teeSheet.id}>

                                            <div className="teeSheet">

                                                <IonHeader>
                                                    <IonToolbar>
                                                        <IonButton routerDirection="root" routerLink="/page/TeeSheetSettings" fill="outline" color="medium" slot="end" onClick={() => { generateTeeSheetTimeSlots(teeSheet); setTeeSheetToEdit(teeSheet); }}>Edit</IonButton>

                                                        <IonTitle>{teeSheet.name}</IonTitle>
                                                    </IonToolbar>
                                                </IonHeader>
                                                <IonCardContent>
                                                    <IonList lines="none">

                                                        <IonItem key="activeInput">
                                                            <IonLabel>Status</IonLabel>
                                                            {teeSheet.active && <IonText color="success">Active</IonText>}
                                                            {!teeSheet.active && <IonText color="danger">Not Active</IonText>}
                                                        </IonItem>

                                                        <IonItem key="numHolesInput">
                                                            <IonLabel>Number of Holes</IonLabel>
                                                            <IonText className="rightAlignedTextInput">{teeSheet.numHoles}</IonText>
                                                        </IonItem>

                                                        <IonItem key="roundLengthInput">
                                                            <IonLabel>Round Length (in minutes)</IonLabel>
                                                            <IonText className="rightAlignedTextInput">{teeSheet.roundLength}</IonText>
                                                        </IonItem>

                                                        <IonItem key="teeTimeIntervalInput">
                                                            <IonLabel>Tee Time Interval (minutes)</IonLabel>
                                                            <IonText className="rightAlignedTextInput">{teeSheet.teeTimeInterval}</IonText>
                                                        </IonItem>

                                                        <IonItem>
                                                            <IonLabel key="startTimeInput">Start Time</IonLabel>
                                                            <IonText className="rightAlignedTextInput">{teeSheet.startTime}</IonText>
                                                        </IonItem>

                                                        <IonItem>
                                                            <IonLabel key="endTimeInput">End Time</IonLabel>
                                                            <IonText className="rightAlignedTextInput">{teeSheet.endTime}</IonText>
                                                        </IonItem>

                                                        <IonItem key="reroundTeeInput">
                                                            <IonLabel>Re-round Tee ID</IonLabel>
                                                            <IonText className="rightAlignedTextInput">{teeSheet.reroundTeeID}</IonText>

                                                        </IonItem>



                                                    </IonList>

                                                    <IonGrid className="blockedTimesContainer">
                                                        <IonText className="blockedTimesHeader">Recurring Blocked Tee Times</IonText>
                                                        {(teeSheet.blockedTimes === undefined || (Array.isArray(teeSheet.blockedTimes) && teeSheet.blockedTimes.length === 0)) && //If nothing exists...
                                                            <IonRow>
                                                                <IonCol className="ion-text-center">
                                                                    <p className="ion-no-margin">No recurring blocked times added yet.</p>
                                                                </IonCol>
                                                            </IonRow>
                                                        }
                                                        {teeSheet.blockedTimes?.map((time, i) =>
                                                            <IonRow key={i}>

                                                                <IonCol size="3">
                                                                    <IonItem className="ion-no-padding">
                                                                        <IonLabel position="stacked" color="medium">Description</IonLabel>
                                                                        <IonInput onIonChange={event => handleInputChange({ event, teeSheet, index: i })} name="title" value={time.title} placeholder="Add a description" />
                                                                    </IonItem>
                                                                </IonCol>

                                                                <IonCol size="3">
                                                                    <IonItem className="ion-no-padding">
                                                                        <IonLabel key="startTimeInput" position="stacked" color="medium">Times</IonLabel>
                                                                        <IonSelect multiple onIonChange={event => handleInputChange({ event, teeSheet, index: i })} name="time" value={time.time} interface="popover" placeholder="Select Time">
                                                                            {teeSheet?.timeslots.map((timeslot, j) =>
                                                                                <IonSelectOption key={j} value={timeslot}>{timeslot}</IonSelectOption>
                                                                            )}
                                                                        </IonSelect>

                                                                    </IonItem>
                                                                </IonCol>



                                                                <IonCol size="5">
                                                                    <IonItem className="ion-no-padding">
                                                                        <IonLabel position="stacked" color="medium">Days</IonLabel><br></br>
                                                                        <IonSelect value={time.days} onIonChange={event => handleInputChange({ event, teeSheet, index: i })} name="days" multiple placeholder="Select Days" className="dayPicker">
                                                                            {daysInWeekRadioOptions.map((day, j) =>
                                                                                <IonSelectOption key={j} value={day}>{day}</IonSelectOption>
                                                                            )}
                                                                        </IonSelect>

                                                                    </IonItem>
                                                                </IonCol>


                                                                <IonCol size="1" className="ion-align-items-end">
                                                                    <IonButton onClick={() => handleBlockedTimeRemove({ teeSheet, index: i })} fill="clear" size="small" className="remove-line-item-icon">
                                                                        <IonIcon icon={trashOutline} slot="icon-only" color="danger" />
                                                                    </IonButton>
                                                                </IonCol>

                                                            </IonRow>



                                                        )}
                                                    </IonGrid>
                                                    <IonRow className="ion-text-center">
                                                        <IonCol className="ion-text-center">
                                                            <IonButton fill="clear" className="ion-margin-top" onClick={() => { handleAddBlockedTeeTime(teeSheet.id) }}>Add a Blocked Tee Time</IonButton>

                                                            <IonButton id={"saveBlockedTeeTimesButton-" + teeSheet.id} className="ion-margin-top" onClick={() => updateBlockedTimes(teeSheet.id)}>Save Blocked Tee Times</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </div>
                                        </IonCol>

                                    ) : ('This course doesn\'t have any tee sheets yet.')

                                }

                            </IonRow>
                        </IonGrid>

                        <IonFab className="bottomAddContentButton" vertical="bottom" horizontal="end" edge slot="fixed">
                            <IonFabButton>
                                <IonIcon icon={add} onClick={() => { addNewTeeSheet() }} />
                            </IonFabButton>
                        </IonFab>


                        {/* Group Edit Dialog Modal */}
                        <IonModal isOpen={Boolean(teeSheetToEdit)} cssClass='teeSheetEditDialog teeSheetModal' backdropDismiss={false}>
                            <div className="modalHeader">Edit Tee Sheet</div>
                            <form onSubmit={editTeeSheet}>
                                <IonList>

                                    <IonItem key="nameInput">
                                        <IonLabel>Name</IonLabel>
                                        <IonInput className="rightAlignedTextInput" onIonChange={e => setTeeSheetToEdit(prevState => ({ ...prevState, name: e.detail.value }))} value={teeSheetToEdit?.name} placeholder='eg. Course Name or "Front Nine", "Back Nine", etc'></IonInput>
                                    </IonItem>

                                    <IonItem key="activeInput">
                                        <IonLabel>Active?</IonLabel>
                                        <IonToggle checked={teeSheetToEdit?.active} onIonChange={e => setTeeSheetToEdit(prevState => ({ ...prevState, active: e.detail.checked }))} />
                                    </IonItem>

                                    <IonItem key="numHolesInput">
                                        <IonLabel>Number of Holes</IonLabel>
                                        <IonInput className="rightAlignedTextInput" onIonChange={e => setTeeSheetToEdit(prevState => ({ ...prevState, numHoles: e.detail.value }))} value={teeSheetToEdit?.numHoles} placeholder='typically 9 or 18' type="number"></IonInput>
                                    </IonItem>

                                    <IonItem key="roundLengthInput">
                                        <IonLabel>Round Length (in minutes)</IonLabel>
                                        <IonInput className="rightAlignedTextInput" onIonChange={e => setTeeSheetToEdit(prevState => ({ ...prevState, roundLength: e.detail.value }))} value={teeSheetToEdit?.roundLength} placeholder='120' type="number"></IonInput>
                                    </IonItem>

                                    <IonItem key="teeTimeIntervalInput">
                                        <IonLabel>Tee Time Interval (minutes)</IonLabel>
                                        <IonInput className="rightAlignedTextInput" onIonChange={e => setTeeSheetToEdit(prevState => ({ ...prevState, teeTimeInterval: e.detail.value }))} value={teeSheetToEdit?.teeTimeInterval} placeholder='10' type="number"></IonInput>
                                    </IonItem>

                                    <IonItem>
                                        <IonLabel key="startTimeInput">Start Time</IonLabel>
                                        <IonSelect onClick={scrollToSelection} onIonChange={e => setTeeSheetToEdit(prevState => ({ ...prevState, startTime: e.detail.value }))} value={teeSheetToEdit?.startTime} interface="popover">
                                            {teeSheetToEdit?.timeslots?.map((timeslot, j) =>
                                                <IonSelectOption key={j} value={timeslot}>{timeslot}</IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </IonItem>

                                    <IonItem>
                                        <IonLabel key="endTimeInput">End Time</IonLabel>
                                        <IonSelect onClick={scrollToSelection} onIonChange={e => setTeeSheetToEdit(prevState => ({ ...prevState, endTime: e.detail.value }))} value={teeSheetToEdit?.endTime} interface="popover">
                                            {teeSheetToEdit?.timeslots?.map((timeslot, j) =>
                                                <IonSelectOption key={j} value={timeslot}>{timeslot}</IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </IonItem>

                                    <IonItem key="reroundTeeInput">
                                        <IonLabel>Re-round Tee</IonLabel>
                                        <IonSelect onIonChange={e => setTeeSheetToEdit(prevState => ({ ...prevState, reroundTeeID: e.detail.value }))} value={teeSheetToEdit?.reroundTeeID} interface="popover">
                                            <IonSelectOption value={null}>Please Select</IonSelectOption>
                                            {teeSheets.map((teeSheet, j) => <IonSelectOption key={j} value={teeSheet.id}>{teeSheet.name}</IonSelectOption>)}
                                        </IonSelect>
                                    </IonItem>

                                </IonList>


                                <IonRow>
                                    <IonCol><IonButton expand="block" color="danger" onClick={() => setTeeSheetToEdit(null)}>Cancel</IonButton></IonCol>
                                    <IonCol><IonButton type="submit" expand="block">Save Group</IonButton></IonCol>
                                </IonRow>

                                <IonButton className="ion-text-center" onClick={() => setConfirmDelete({ isOpen: true, type: 'teesheet' })} fill="clear" expand="full" color="danger">Delete this Tee Sheet</IonButton>
                            </form>
                        </IonModal>


                        {/* TeeSheet Add Dialog Modal */}
                        <IonModal isOpen={Boolean(newTeeSheet)} cssClass='teeSheetEditDialog teeSheetModal' backdropDismiss={false}>
                            <div className="modalHeader">Add A Tee-Sheet</div>
                            <form onSubmit={addTeeSheet}>
                                <IonList>

                                    <IonItem key="nameInput">
                                        <IonLabel>Name</IonLabel>
                                        <IonInput className="rightAlignedTextInput" onIonChange={e => setNewTeeSheet(prevState => ({ ...prevState, name: e.detail.value }))} placeholder='eg. Course Name or "Front Nine", "Back Nine", etc'></IonInput>
                                    </IonItem>

                                    <IonItem key="activeInput">
                                        <IonLabel>Active?</IonLabel>
                                        <IonToggle checked={newTeeSheet?.active} onIonChange={e => setNewTeeSheet(prevState => ({ ...prevState, active: e.detail.checked }))} />
                                    </IonItem>

                                    <IonItem key="numHolesInput">
                                        <IonLabel>Number of Holes</IonLabel>
                                        <IonInput className="rightAlignedTextInput" onIonChange={e => setNewTeeSheet(prevState => ({ ...prevState, numHoles: e.detail.value }))} value={newTeeSheet?.numHoles} placeholder='typically 9 or 18' type="number"></IonInput>
                                    </IonItem>

                                    <IonItem key="roundLengthInput">
                                        <IonLabel>Round Length (in minutes)</IonLabel>
                                        <IonInput className="rightAlignedTextInput" onIonChange={e => setNewTeeSheet(prevState => ({ ...prevState, roundLength: e.detail.value }))} value={newTeeSheet?.roundLength} placeholder='120' type="number"></IonInput>
                                    </IonItem>

                                    <IonItem key="teeTimeIntervalInput">
                                        <IonLabel>Tee Time Interval (minutes)</IonLabel>
                                        <IonInput className="rightAlignedTextInput" onIonChange={e => setNewTeeSheet(prevState => ({ ...prevState, teeTimeInterval: e.detail.value }))} value={newTeeSheet?.teeTimeInterval} placeholder='10' type="number"></IonInput>
                                    </IonItem>

                                    <IonItem>
                                        <IonLabel key="startTimeInput">Start Time</IonLabel>
                                        <IonSelect onClick={scrollToSelection} onIonChange={e => setNewTeeSheet(prevState => ({ ...prevState, startTime: e.detail.value }))} value={newTeeSheet?.startTime} interface="popover">
                                            {newTeeSheetTimeSlots?.map((timeslot, j) =>
                                                <IonSelectOption key={j} value={timeslot}>{timeslot}</IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </IonItem>

                                    <IonItem>
                                        <IonLabel key="endTimeInput">End Time</IonLabel>
                                        <IonSelect onClick={scrollToSelection} onIonChange={e => setNewTeeSheet(prevState => ({ ...prevState, endTime: e.detail.value }))} value={newTeeSheet?.endTime} interface="popover">
                                            {newTeeSheetTimeSlots.map((timeslot, j) =>
                                                <IonSelectOption key={j} value={timeslot}>{timeslot}</IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </IonItem>

                                    <IonItem key="reroundTeeInput">
                                        <IonLabel>Re-round Tee</IonLabel>
                                        <IonSelect onIonChange={e => setNewTeeSheet(prevState => ({ ...prevState, reroundTeeID: e.detail.value }))} interface="popover">
                                            <IonSelectOption value={null}>Please Select</IonSelectOption>
                                            {teeSheets.map((teeSheet, j) => <IonSelectOption key={j} value={teeSheet.id}>{teeSheet.name}</IonSelectOption>)}
                                        </IonSelect>
                                    </IonItem>

                                </IonList>


                                <IonRow>
                                    <IonCol><IonButton expand="block" color="danger" onClick={() => setNewTeeSheet(null)}>Cancel</IonButton></IonCol>
                                    <IonCol><IonButton type="submit" expand="block">Save Group</IonButton></IonCol>
                                </IonRow>
                            </form>
                        </IonModal>

                        <IonAlert
                            isOpen={confirmDelete.isOpen}
                            onDidDismiss={() => setConfirmDelete({ isOpen: false, type: '' })}
                            cssClass='confirm-delete-panel'
                            header={'Are You Sure?'}
                            message={'Please confirm you would like to delete this Tee Sheet. <strong>This action can\'t be undone!</strong>'}
                            buttons={[
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'cancelDeletion',
                                },
                                {
                                    text: 'Delete',
                                    cssClass: 'confirmDeletion',
                                    handler: () => {
                                        deleteTeeSheet();
                                    }
                                }
                            ]}
                        />

                    </IonContent>
                </IonPage>
            }
        </div>
    );
};

export default TeeSheetSettings;