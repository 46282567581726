import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import PageHeader from '../components/PageHeader';

const Marketing: React.FC<React.PropsWithChildren<unknown>> = () => {

  return (
    <IonPage>
      <PageHeader title="Marketing" settings="" backlink=""></PageHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Marketing</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <div className="centered-app-container">
          <p>This feature is in development. Stay tuned!</p>
        </div>
        
      </IonContent>
    </IonPage>
  );
};

export default Marketing;
