import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';

function SettingsCourse({ course }) {

  return (
    <div className="settingsPageContainer">
      <IonGrid>
        <IonRow>
          <IonCol>
          <h2 className="subHeading">Course Settings (Coming Soon!)</h2>
            <p>View and edit your course settings below.</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <table className="settingsTable">
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td>{course?.name}</td>
                </tr>
                <tr>
                  <td>Address:</td>
                  <td>{course?.address}</td>
                </tr>
                <tr>
                  <td>City:</td>
                  <td>{course?.city}</td>
                </tr>
                <tr>
                  <td>State:</td>
                  <td>{course?.state}</td>
                </tr>
                <tr>
                  <td>Zip:</td>
                  <td>{course?.zip}</td>
                </tr>
                <tr>
                  <td>Phone:</td>
                  <td>{course?.phone}</td>
                </tr>
              </tbody>
            </table>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>

  );
};

export default SettingsCourse;
